import { Skeleton, Typography, styled } from '@mui/material';
import EDInput from '../EDInput/EDInput';
import EDAutocomplete from './EDAutocomplete';

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: 1.58,
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(0.5)
}));

const EDAutocompleteWithLabel = ({
  label,
  showLabel,
  required,
  isLoading,
  helperText,
  placeholder,
  ...props
}) => {
  return (
    <div>
      {showLabel && (
        <Label>
          {label}
          {required && ` (*)`}
        </Label>
      )}
      {isLoading && <Skeleton height={53} />}
      {!isLoading && (
        <EDAutocomplete
          fullWidth
          renderInput={(params) => (
            <EDInput
              helperText={helperText}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: props.disableAutocomplete ? 'one-time-code' : 'on'
              }}
            />
          )}
          {...props}
        />
      )}
    </div>
  );
};

export default EDAutocompleteWithLabel;
