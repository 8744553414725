import { Translate } from '@mui/icons-material'
import {
  Badge,
  Box,
  ButtonGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../_practice-components'
import AlertDialogWithIcon from '../../../../atoms/AlertDialogWithIcon/AlertDialogWithIcon'
import EDButton from '../../../../atoms/EDButton/EDButton'
import EDCard from '../../../../atoms/EDCard/EDCard'
import EDCircularLoading from '../../../../atoms/EDCircularLoading/EDCircularLoading'
import Icon from '../../../../atoms/icon'
import BBJSONEditor from '../../../../components/JSONEditor/BBJSONEditor'
import i18nInstance, { LANGUAGES } from '../../../../i18n/i18n'
import { fetchWithToken } from '../../../../utils/helpers/fetcher'
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage'
import useUserSettings from '../../../../utils/hooks/useUserSettings'
import { localStorageKeys } from '../../../../utils/localStorageKeys'
import CommentSection from './CommentSection'
import FullScreenPreview from './FullScreenPreview'
import './SingleActivityJSON.scss'
import TranslateDialog from './TranslateDialog'

const SingleActivityJSON = ({
  isLoading,
  json,
  jsonApp,
  onSetActivityJSON,
  onSetActivityJSONApp,
  onGenerateMobileJSON,
  onHasChanges,
  hasChanges,
  onHasError,
  readOnly,
  guid,
  activityName
}) => {
  const { t } = useTranslation()
  const { getItem } = useLocalStorage()
  const [currentTabMode, setCurrentTabMode] = useState('desktop')

  const [previewDataJSON, setPreviewDataJSON] = useState(null)
  const [editorCodeString, setEditorCodeString] = useState(json || {})
  const [showFullScreen, setShowFullScreen] = useState(false)

  const [hasDesktopChanges, setHasDesktopChanges] = useState(false)
  const [hasAppChanges, setHasAppChanges] = useState(false)

  const [showChangeAlert, setShowChangeAlert] = useState(false)

  const { getPreviewLanguageSettings, setPreviewLanguageSettings } =
    useUserSettings()

  const [previewLanguage, setPreviewLanguage] = useState(null)
  const [uiActivitiesLanguage, setUiActivitiesLanguage] = useState('en')

  const [isTranslateDialogOpen, setIsTranslateDialogOpen] = useState(false)
  const [updateForTranslation, setUpdateForTranslation] = useState(false)

  const [sourceLanguageIndex, setSourceLanguageIndex] = useState(0)
  const handleChangeLanguage = (event) => {
    setSourceLanguageIndex(event.target.value)
  }

  const handlePreviewLanguageChange = (event, newLanguage) => {
    setPreviewLanguage(newLanguage)
    setPreviewLanguageSettings(newLanguage)
  }

  const handleShowFullScreen = () => {
    setShowFullScreen(!showFullScreen)
  }

  const handleSetDesktopMode = () => {
    if (!hasChanges) {
      setEditorCodeString(json || '{}')
      setCurrentTabMode('desktop')
    } else setShowChangeAlert(true)
  }

  const handleSetAppMode = () => {
    if (!hasChanges) {
      setEditorCodeString(jsonApp || '{}')
      setCurrentTabMode('mobile')
    } else setShowChangeAlert(true)
  }

  const handleChangeAlertConfirmation = () => {
    if (currentTabMode === 'desktop') {
      setEditorCodeString(jsonApp || '{}')
      setCurrentTabMode('mobile')
    } else {
      setEditorCodeString(json || '{}')
      setCurrentTabMode('desktop')
    }
    setShowChangeAlert(false)
    setHasAppChanges(false)
    setHasDesktopChanges(false)
    onHasChanges(false)
  }

  const handleChangeAlertCancel = () => {
    setShowChangeAlert(false)
  }

  const checkDesktopChanges = (code) => {
    if (
      (json === null && code === '{}') ||
      isEqual(json, JSON.parse(code || '{}'))
    ) {
      setHasDesktopChanges(false)
    } else {
      setHasDesktopChanges(true)
    }
  }

  const checkAppChanges = (code) => {
    if (
      (jsonApp === null && code === '{}') ||
      isEqual(jsonApp, JSON.parse(code || '{}'))
    )
      setHasAppChanges(false)
    else {
      setHasAppChanges(true)
    }
  }

  const onSetEditorCodeString = (code) => {
    setEditorCodeString(code || '{}')
    // to save it in the main component

    switch (currentTabMode) {
      case 'desktop':
        onSetActivityJSON(code || '{}')
        checkDesktopChanges(code)
        break
      case 'mobile':
        onSetActivityJSONApp(code || '{}')
        checkAppChanges(code)
        break
      default:
        break
    }

    if (hasDesktopChanges || hasAppChanges) onHasChanges(true)
    else onHasChanges(false)
  }

  const onUpdatePreview = async () => {
    setPreviewDataJSON(null)

    const _activityJson =
      typeof editorCodeString === 'string'
        ? editorCodeString
        : JSON.stringify(editorCodeString)

    if (_activityJson !== '{}' && previewLanguage) {
      let previewResponse = await fetchWithToken({
        path: `/seeds/preview-json-lemonade`,
        method: 'POST',
        data: {
          data: _activityJson,
          lang: previewLanguage,
          device: currentTabMode
        }
      })
      if (previewResponse.status === 'success') {
        // currentTabMode
        setPreviewDataJSON(previewResponse.data)
      }
    }
  }

  const getMultilanguageKeys = () => {
    const seedCode =
      typeof editorCodeString === 'string'
        ? JSON.parse(editorCodeString)
        : editorCodeString
    if (seedCode.locales) {
      return Object.keys(seedCode.locales)
    }
    return false
  }

  useEffect(() => {
    if (hasDesktopChanges || hasAppChanges) onHasChanges(true)
    else onHasChanges(false)
  }, [currentTabMode, hasDesktopChanges, hasAppChanges])

  useEffect(() => {
    if (previewLanguage) {
      onUpdatePreview()
    }
  }, [currentTabMode, previewLanguage])

  useEffect(() => {
    if (isLoading) return
    onUpdatePreview()
  }, [isLoading])

  useEffect(() => {
    setPreviewLanguage(getPreviewLanguageSettings())

    setUiActivitiesLanguage(
      getItem(localStorageKeys.language) || i18nInstance.language || 'en'
    )
  }, [])

  useEffect(() => {
    if (updateForTranslation) {
      setUpdateForTranslation(false)
      onUpdatePreview()
    }
  }, [updateForTranslation])

  const handleTranslation = () => {
    setIsTranslateDialogOpen(true)
  }

  const onApplyTranslation = (translatedJson) => {
    setIsTranslateDialogOpen(false)
    onSetEditorCodeString(translatedJson)
    setUpdateForTranslation(true)
  }

  if (showFullScreen) {
    return (
      <Grid item xs={12}>
        <FullScreenPreview
          isLoading={isLoading}
          onClose={handleShowFullScreen}
          previewDataJSON={previewDataJSON}
          editorCodeString={editorCodeString}
          onSetEditorCodeString={onSetEditorCodeString}
          onHasError={onHasError}
          onUpdatePreview={onUpdatePreview}
          deviceMode={currentTabMode}
          onGetMultilanguageKeys={getMultilanguageKeys}
          onHandlePreviewLanguageChange={handlePreviewLanguageChange}
          previewLanguage={previewLanguage}
          readOnly={readOnly}
        />
      </Grid>
    )
  } else {
    return (
      <>
        <Grid item xs={12}>
          <AlertDialogWithIcon
            isOpen={showChangeAlert}
            title={t('back_without_saving_title')}
            description={t('back_without_saving_description')}
            onConfirm={handleChangeAlertConfirmation}
            onCancel={handleChangeAlertCancel}
            cancelText={t('cancel')}
            confirmText={t('back_without_saving_exit')}
          />
          <Grid container spacing={3}>
            <Grid item md={5}>
              <EDCard elevation={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid
                        item
                        container
                        className='single-activity-json__editor-actions'
                      >
                        <Grid
                          item
                          container
                          className='single-activity-json__actions-left'
                        >
                          <div className='single-activity-json__tabs'>
                            <ButtonGroup className='single-activity-json__tabs-group'>
                              <EDButton
                                disableElevation
                                variant={
                                  currentTabMode === 'desktop'
                                    ? 'contained'
                                    : 'outlined'
                                }
                                onClick={handleSetDesktopMode}
                                className='single-activity-json__tabs_item'
                              >
                                {t('editor_desktop_version')}
                              </EDButton>
                              <Badge
                                badgeContent={'+'}
                                color='primary'
                                // variant='dot'
                                invisible={
                                  jsonApp === null ||
                                  Object.keys(jsonApp).length === 0
                                    ? false
                                    : true
                                }
                                className='single-activity-json__tabs_badge'
                              >
                                <EDButton
                                  disableElevation
                                  variant={
                                    currentTabMode === 'mobile'
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  onClick={handleSetAppMode}
                                  className='single-activity-json__tabs_item'
                                >
                                  {t('editor_mobile_version')}
                                </EDButton>
                              </Badge>
                            </ButtonGroup>
                          </div>
                        </Grid>

                        <div className='single-activity-json_source-language-container'>
                          <EDButton
                            startIcon={<Translate />}
                            variant='contained'
                            size='large'
                            onClick={handleTranslation}
                            className='single-activity-json_source-language-translate'
                          >
                            {t('translate_button')}
                          </EDButton>
                          <div className='single-activity-json_source-language-source-selection'>
                            <span className='single-activity-json_source-language-source-text'>
                              {t('source_language')}
                            </span>
                            <Select
                              className='single-activity-json_source-language-source-select'
                              value={sourceLanguageIndex}
                              onChange={handleChangeLanguage}
                              variant='outlined'
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'right'
                                },
                                getContentAnchorEl: null,
                                sx: { marginTop: '12px' }
                              }}
                            >
                              {LANGUAGES.map((language, index) => (
                                <MenuItem
                                  key={index}
                                  value={index}
                                  className='single-activity-json_select-language-item'
                                >
                                  {language.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </Grid>

                      <Grid item>
                        <Typography variant='h2'>
                          {t('editor_subtitle')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Stack direction='row' spacing={2}>
                          {currentTabMode === 'mobile' && (
                            <EDButton
                              variant='outlined'
                              size='large'
                              onClick={() => {
                                setEditorCodeString(
                                  JSON.stringify(onGenerateMobileJSON())
                                )
                                onSetActivityJSONApp(
                                  JSON.stringify(onGenerateMobileJSON())
                                )
                                setTimeout(() => {
                                  onUpdatePreview()
                                }, 1000)
                              }}
                            >
                              Generate Mobile JSON
                            </EDButton>
                          )}

                          <EDButton
                            variant='outlined'
                            size='large'
                            onClick={onUpdatePreview}
                          >
                            {t('update_preview_button')}
                          </EDButton>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        {/* Keep different components so it renders the right code */}
                        {currentTabMode === 'desktop' && (
                          <BBJSONEditor
                            defaultJSON={editorCodeString}
                            onSetEditorCodeString={onSetEditorCodeString}
                            onHasError={onHasError}
                            source={'tab'}
                            readOnly={readOnly}
                            originalJSON={json}
                            json={editorCodeString}
                          />
                        )}
                        {currentTabMode === 'mobile' && (
                          <BBJSONEditor
                            defaultJSON={editorCodeString}
                            onSetEditorCodeString={onSetEditorCodeString}
                            onHasError={onHasError}
                            source={'tab'}
                            json={editorCodeString}
                            // json={mobileGeneratedJSON}
                            readOnly={readOnly}
                            originalJSON={jsonApp}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </EDCard>
            </Grid>
            <Grid item md={7} className='single-activity-json__right'>
              <EDCard elevation={0}>
                <Grid container spacing={3}>
                  {editorCodeString && editorCodeString !== '{}' && (
                    <Grid item xs={12}>
                      {!previewDataJSON && <EDCircularLoading fullScreen />}
                      {getMultilanguageKeys() && (
                        <div className='single-activity-json__right__languages'>
                          <ToggleButtonGroup
                            color='primary'
                            value={previewLanguage}
                            exclusive
                            onChange={handlePreviewLanguageChange}
                            aria-label='Language'
                            size='small'
                          >
                            {getMultilanguageKeys().map((langItem, index) => (
                              <ToggleButton value={langItem} key={index}>
                                {langItem.toUpperCase()}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </div>
                      )}
                      <div
                        className='single-activity-json__fullscreen-button'
                        onClick={handleShowFullScreen}
                      >
                        <Icon type='arrows-expand' format='outline' />
                      </div>
                      <div
                        className={`single-activity-preview-container single-activity-preview-container--${currentTabMode}`}
                      >
                        <div className='app-practice-page'>
                          {previewDataJSON && (
                            <ActivityBlueberryComponent
                              activityType={ACTIVITY_TYPES.PREVIEW}
                              activity={previewDataJSON}
                              uiLanguage={uiActivitiesLanguage}
                              mathRenderEngine='katex'
                              exerciseLanguage={previewLanguage}
                              pathAssetsSchool={`/assets/graphics/gamification`}
                            />
                          )}
                        </div>
                      </div>
                    </Grid>
                  )}
                  {(!editorCodeString || editorCodeString === '{}') && (
                    <Grid item xs={12}>
                      <Box sx={{ py: 5 }}>
                        <Typography variant='h2' align='center'>
                          {t('activity_no_data_to_preview')}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </EDCard>
            </Grid>
            <CommentSection seedId={guid}></CommentSection>
          </Grid>
        </Grid>

        {/* Force reload of states */}
        {isTranslateDialogOpen && (
          <TranslateDialog
            onClose={() => setIsTranslateDialogOpen(false)}
            onApply={onApplyTranslation}
            activityName={activityName}
            sourceJSON={editorCodeString}
            sourceLanguage={LANGUAGES[sourceLanguageIndex].value}
            isOpen={isTranslateDialogOpen}
          />
        )}
      </>
    )
  }
}

export default SingleActivityJSON
