import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { flexRender } from '@tanstack/react-table'
import PropTypes from 'prop-types'
import { useState } from 'react'

function TableBody({ rows = [], onRowClick, customHighlightRow, options }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [rowDataForOptions, setRowDataForOptions] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event, data) => {
    setRowDataForOptions(data)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <tbody className='table-body'>
      {rows.map((row) => {
        return (
          <tr
            key={row.id}
            className={`${onRowClick ? 'is-clickable' : ''} ${
              customHighlightRow?.key &&
              customHighlightRow?.value ===
                row.original[customHighlightRow?.key]
                ? 'is-highlighted'
                : ''
            }`}
            onClick={() => onRowClick && onRowClick(row.original)}
          >
            {(row?.getVisibleCells() || []).map((cell) => (
              <td
                key={cell.id}
                className={`${
                  cell.getContext().column.id === 'isSelected'
                    ? 'is-selectable'
                    : ''
                }`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
            {options && (
              <td className='table-body__options'>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleClick(e, row.original)
                  }}
                >
                  <MoreVert />
                </IconButton>
              </td>
            )}
          </tr>
        )
      })}

      {options && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className='table__menu'
        >
          {options.map((option) => {
            if (option.hideOption === undefined) {
              return (
                <MenuItem
                  key={option.label}
                  onClick={() => {
                    option.onClick(rowDataForOptions)
                    handleClose()
                  }}
                >
                  {option.icon}
                  {option.label}
                </MenuItem>
              )
            } else {
              return (
                !option.hideOption(rowDataForOptions) && (
                  <MenuItem
                    key={option.label}
                    onClick={() => {
                      option.onClick(rowDataForOptions)
                      handleClose()
                    }}
                  >
                    {option.icon}
                    {option.label}
                  </MenuItem>
                )
              )
            }
          })}
        </Menu>
      )}
    </tbody>
  )
}

TableBody.propTypes = {
  rows: PropTypes.array,
  onRowClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  options: PropTypes.array,
  customHighlightRow: PropTypes.object
}

export default TableBody
