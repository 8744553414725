import PropTypes from 'prop-types';
import { useIconImport } from './useIconImport';

function Icon({ type = 'search', format = 'outline' }) {
  const { loading, SvgIcon } = useIconImport({ type, format });

  return (
    <>
      {loading && (
        <div className='rounded-full bg-slate-400 animate-pulse h-8 w-8'></div>
      )}

      {SvgIcon && <SvgIcon />}
    </>
  );
}

Icon.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'action',
    'disabled',
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning'
  ]),
  fontSize: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
  format: PropTypes.oneOf(['outline', 'solid']),
  htmlColor: PropTypes.string,
  type: PropTypes.string
};

export default Icon;
