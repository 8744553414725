import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createNewUser, redeemLicense } from '../../../../utils/crud/user';
import { fetchWithToken, getAPIURL } from '../../../../utils/helpers/fetcher';
import { getToken } from '../../../../utils/helpers/token';
import useAuth from '../../../../utils/hooks/useAuth';

const useEditSchool = () => {
  const { user, hydrateUser, onLogout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = useParams();

  const [schoolGuid, setSchoolGuid] = useState('');
  const [isSchoolAdmin, setIsSchoolAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' });

  const [school, setSchool] = useState(null);
  const [countries, setCountries] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [educationYears, setEducationYears] = useState([]);
  const [schoolContracts, setSchoolContracts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [currentTab, setCurrentTab] = useState('details');
  const [dataToUpdate, setDataToUpdate] = useState({});

  const [totalUsersInSchool, setTotalUsersInSchool] = useState(0);
  const [totalGroupsInSchool, seTotalGroupsInSchool] = useState(0);
  const [totalClassesInSchool, setTotalClassesInSchool] = useState(0);
  const [totalSchoolContracts, setTotalSchoolContracts] = useState(0);

  const [totalClassesInSchoolV2, setTotalClassesInSchoolV2] = useState(0);
  const [classesV2, setClassesV2] = useState([]);

  const getEducationLevels = async () => {
    let educationLevelsResponse = await fetchWithToken({
      path: '/education-levels'
    });

    setEducationLevels(educationLevelsResponse.data.educationLevels);
  };

  const getEducationYears = async () => {
    let educationYearsResponse = await fetchWithToken({
      path: '/education-years'
    });

    setEducationYears(educationYearsResponse.data);
  };

  const getSchoolContracts = async () => {
    let schoolContractsResponse = await fetchWithToken({
      path: `/schools/${schoolGuid}/contracts`
    });

    setTotalSchoolContracts(schoolContractsResponse.data.total);
    setSchoolContracts(schoolContractsResponse.data.contracts);
  };

  const getSchoolYears = async () => {
    let schoolYearsResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/school-years`
    });

    setSchoolYears(schoolYearsResponse.data);
  };

  const getCountries = async () => {
    let countriesResponse = await fetchWithToken({
      path: '/locations'
    });

    setCountries(
      countriesResponse.data.map((country) => ({
        label: country.country,
        value: country.guid
      }))
    );
  };

  const getSchool = async () => {
    let response = await fetchWithToken({
      path: `/schools/${schoolGuid}`
    });

    setSchool({
      ...response.data,
      name: response.data.name,
      country: {
        label: response.data.country_name,
        value: response.data.country_guid
      }
    });
  };

  const getSchoolGroups = async () => {
    let groupsResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups?offset0&pageSize=100`
    });

    seTotalGroupsInSchool(groupsResponse.data.length);
    setGroups(groupsResponse.data.schoolGroups);
  };

  const getUsersInSchool = async () => {
    let usersResponse = await fetchWithToken({
      path: `/schools/${schoolGuid}/users?offset0&pageSize=100`
    });

    setTotalUsersInSchool(usersResponse.data.length);
    setUsers(
      usersResponse.data.users.map((user) => ({
        ...user,
        email: user.email || user.username
      }))
    );
  };

  const getSchoolClasses = async () => {
    let classesResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/courses?offset0&pageSize=100`
    });

    setTotalClassesInSchool(classesResponse.data.total);
    setClasses(classesResponse.data.courses);
  };

  const getSchoolClassesV2 = async () => {
    let classesResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/classes?offset0&pageSize=100`
    });

    setTotalClassesInSchoolV2(classesResponse.data.total);
    setClassesV2(classesResponse.data);
  };

  const getPrograms = async () => {
    let programsResponse = await fetchWithToken({
      path: 'lms/courses?isEditorial=1&hasBlueBerry=1' // &lang[]=en|es|pt&educationLevel[]=&educationYear[]&discipline[]
    });

    setPrograms(programsResponse.data.courses);
  };

  const getData = async () => {
    await getEducationLevels();
    await getEducationYears();
    await getSchoolContracts();
    await getCountries();
    await getSchoolYears();

    await getSchool();

    await getSchoolGroups();
    await getUsersInSchool();
    await getSchoolClassesV2();
    await getSchoolClasses();
    await getPrograms();

    setIsLoading(false);
  };

  const onChangeTab = (tab) => {
    if (!schoolGuid) return;
    setCurrentTab(tab.key);
  };

  const onCreateNewContract = async (contractData) => {
    contractData.school_year = contractData.school_year.toString();

    const newContractResponse = await fetchWithToken({
      path: `/licenses/schools`,
      method: 'POST',
      data: { ...contractData, school_guid: schoolGuid }
    });

    const hasError = newContractResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError
        ? t('school_year_create_success')
        : t('school_year_create_error')
    });

    setShowToast(true);

    if (hasError) return;

    getSchoolContracts();
    getSchoolYears();
  };

  const onCreateNewGroup = async (groupData) => {
    const newGroupResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups`,
      method: 'POST',
      data: { ...groupData, school_guid: schoolGuid, is_active: 1 }
    });

    const hasError = newGroupResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('group_create_success') : t('group_create_error')
    });

    setShowToast(true);

    if (!hasError) getSchoolGroups();
  };

  const onCreateNewUser = async (userData) => {
    // console.log('onCreateNewUser userData', userData);
    setIsLoading(true);

    // let hasError = false;
    let hasErrorAtCreate = false;

    try {
      // Crear usuario
      const newUserResponse = await createNewUser(userData);

      // console.log('newUserResponse', newUserResponse);

      if (newUserResponse.errorData) {
        throw newUserResponse;
      }

      const newUserGuid = newUserResponse.data.guid;

      // redeem license if user is role teacher
      if (userData.role === 'R02') {
        await redeemLicense({
          userGuid: newUserGuid,
          licenseCode: userData.license
        });
      }

      // Asignar colegio
      const setSchoolResponse = await fetchWithToken({
        path: `/users/${newUserGuid}/schools`,
        method: 'POST',
        data: {
          guid: [schoolGuid]
        }
      });

      if (setSchoolResponse.status !== 'success') {
        throw { type: 1, errorData: setSchoolResponse };
      }

      // assign to group if selected
      const addToGroupResponse = await fetchWithToken({
        path: `/front/school-admin/${schoolGuid}/groups/${userData.class.value}/users`,
        method: 'POST',
        data: {
          guid: [newUserGuid]
        }
      });

      if (addToGroupResponse.status !== 'success') {
        throw { type: 1, errorData: addToGroupResponse };
      }
    } catch (createError) {
      console.log('createError', createError);

      // hasError = true;
      hasErrorAtCreate = createError.type === 0;

      const errorMessage =
        createError?.errorData?.data?.error?.code === 409
          ? t('users_error_username_exists')
          : t('users_error_create');

      setToastMessage({
        type: 'error',
        text: errorMessage
      });

      setShowToast(true);
    }

    setIsLoading(false);

    if (!hasErrorAtCreate) {
      setToastMessage({
        type: 'success',
        text: t('users_success_create')
      });

      setShowToast(true);

      getUsersInSchool();
      getSchoolGroups();
    }

    return !hasErrorAtCreate;
  };

  const loginAsTeacher = async (groupGuid) => {
    // get the teacher of the group
    const groupUsersResponse = await fetchWithToken({
      path: `front/school-admin/${schoolGuid}/groups/${groupGuid}/users`
    });

    if (groupUsersResponse.status !== 'success') {
      throw { type: 'fetchGroupUsers', error: groupUsersResponse };
    }

    const teacher = groupUsersResponse.data.users.find(
      (item) => item.role_guid === 'R02'
    );

    // Soft login as teacher
    //  - Parece que solo va con fetch por algún motivo...
    const softLoginResponse = await fetch(
      `${getAPIURL()}users/${teacher.guid}/login`,
      {
        method: 'POST',
        headers: {
          Authorization: getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => response.json());

    if (softLoginResponse.status !== 'success') {
      throw { type: 'teacherSoftLogin', error: softLoginResponse };
    }

    return softLoginResponse.data.token;
  };

  const onCreateNewClass = async (classData) => {
    let hasError = false;

    try {
      const teacherToken = await loginAsTeacher(classData.school_group_guid);

      // Crear clase con token de profesor
      const createClassResponse = await fetch(`${getAPIURL()}front/courses`, {
        method: 'POST',
        headers: {
          Authorization: teacherToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(classData)
      }).then((response) => response.json());

      // console.log('craeteClassResponse', createClassResponse);

      if (createClassResponse.status !== 'success') {
        throw { type: 'createClass', error: createClassResponse };
      }
    } catch (errorData) {
      console.log('Create class error', errorData);

      hasError = true;

      let errorMessage = '';
      switch (errorData?.type) {
        case 'fetchGroupUsers':
          errorMessage = t('class_create_error_fetch_group_users');
          break;
        case 'teacherSoftLogin':
          errorMessage = t('class_create_error_teacher_soft_login');
          break;
        case 'createClass':
        default:
          errorMessage = t('class_create_error');
          break;
      }

      setToastMessage({
        type: 'error',
        text: errorMessage
      });

      setShowToast(true);
    }

    if (!hasError) {
      setToastMessage({
        type: 'success',
        text: t('class_create_success')
      });

      setShowToast(true);

      getSchoolClasses();
    }
  };

  const onEditClass = async ({ courseGuid, classData }) => {
    let hasError = false;

    try {
      const teacherToken = await loginAsTeacher(classData.school_group_guid);

      // edit class
      const editClassResponse = await fetch(
        `${getAPIURL()}front/courses/${courseGuid}`,
        {
          method: 'PUT',
          headers: {
            Authorization: teacherToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(classData)
        }
      ).then((response) => response.json());

      if (editClassResponse.status !== 'success') {
        throw { type: 'editClass', error: editClassResponse };
      }
    } catch (errorData) {
      console.log('Edit class error', errorData);

      hasError = true;

      let errorMessage = '';
      switch (errorData?.type) {
        case 'fetchGroupUsers':
          errorMessage = t('class_edit_error_fetch_group_users');
          break;
        case 'teacherSoftLogin':
          errorMessage = t('class_edit_error_teacher_soft_login');
          break;
        case 'editClass':
        default:
          errorMessage = t('class_edit_error');
          break;
      }

      setToastMessage({
        type: 'error',
        text: errorMessage
      });

      setShowToast(true);
    }

    if (!hasError) {
      setToastMessage({
        type: 'success',
        text: t('class_edit_success')
      });

      setShowToast(true);

      getSchoolClasses();
    }
  };

  const onDeleteClass = async (schoolGroupGuid, classToDelete) => {
    try {
      const teacherToken = await loginAsTeacher(schoolGroupGuid);

      const deleteClassResponse = await fetch(`${getAPIURL()}front/courses/`, {
        method: 'DELETE',
        headers: {
          Authorization: teacherToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          guid: [classToDelete.guid]
        })
      }).then((response) => response.json());

      if (deleteClassResponse.status !== 'success') {
        throw { type: 'deleteClass', error: deleteClassResponse };
      }

      await getSchoolClasses();

      setToastMessage({
        type: 'success',
        text: t('class_delete_success')
      });

      setShowToast(true);
    } catch (errorData) {
      console.log('Delete class error', errorData);

      let errorMessage = '';
      switch (errorData?.type) {
        case 'fetchGroupUsers':
          errorMessage = t('class_edit_error_fetch_group_users');
          break;
        case 'teacherSoftLogin':
          errorMessage = t('class_edit_error_teacher_soft_login');
          break;
        case 'deleteClass':
        default:
          errorMessage = t('class_delete_error');
          break;
      }

      setToastMessage({
        type: 'error',
        text: errorMessage
      });

      setShowToast(true);
    }
  };

  const onClickOnUser = (e) => {
    navigate(`/users/${e.guid}`);
  };

  const onDeleteUser = async (user) => {
    const deleteUserResponse = await fetchWithToken({
      path: `/schools/${schoolGuid}/users`,
      method: 'DELETE',
      data: {
        guid: [user.guid]
      }
    });

    const hasError = deleteUserResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('user_delete_success') : t('user_delete_error')
    });

    setShowToast(true);

    if (!hasError) getUsersInSchool();
  };

  // legacy to be removed on cleanup - replaces by onDeleteUserFromClass
  const onDeleteUserFromGroup = async (user) => {
    const deleteUserFromGroupResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups/${user.group_guid}/users`,
      method: 'DELETE',
      data: {
        guid: [user.guid]
      }
    });

    const hasError = deleteUserFromGroupResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError
        ? t('user_delete_from_group_success')
        : t('user_delete_from_group_error')
    });

    setShowToast(true);

    if (!hasError) {
      getUsersInSchool();
      getSchoolGroups();
    }
  };

  const onDeleteUserFromClass = async (user) => {
    const deleteUserFromGroupResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups/${user.group_guid}/users`,
      method: 'DELETE',
      data: {
        guid: [user.guid]
      }
    });

    const hasError = deleteUserFromGroupResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError
        ? 'The user has been deleted from the class.'
        : 'An error occurred while deleting the user from the class.'
    });

    setShowToast(true);

    if (!hasError) {
      getUsersInSchool();
      getSchoolClassesV2();
    }
  };

  const onUpdateSchool = async (updatedData) => {
    setSchool({ ...school, ...updatedData });
    setDataToUpdate({ ...dataToUpdate, ...updatedData });
  };

  const onSaveSchool = async () => {
    const updateSchoolResponse = await fetchWithToken({
      path: `/schools/${schoolGuid}`,
      method: 'PUT',
      data: dataToUpdate
    });

    const hasError = updateSchoolResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('school_update_success') : t('school_update_error')
    });

    setShowToast(true);
  };

  const onUpdateGroup = async (group) => {
    setGroups(
      groups.map((item) => {
        if (item.guid === group.guid) {
          return {
            ...item,
            ...group
          };
        }

        return item;
      })
    );

    const updateGroupResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups`,
      method: 'PUT',
      data: {
        guid: [group.guid],
        data: group
      }
    });

    const hasError = updateGroupResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError
        ? t('group_update_success')
        : updateGroupResponse.data.error.message
    });

    setShowToast(true);
    getSchoolGroups();
  };

  const getSchoolGuid = () => {
    if (params.schoolGuid || user.role_guid === 'R04') {
      return params.schoolGuid;
    }

    if (user.role_guid === 'R03') {
      if (user.schools[0]?.guid) {
        return user.schools[0]?.guid;
      } else {
        setCurrentTab('');
        setIsLoading(false);
      }
    }
  };

  const onDeleteClassV2 = async (classGuid) => {
    const deleteUserResponse = await fetchWithToken({
      method: 'DELETE',
      path: `front/school-admin/${schoolGuid}/classes/${classGuid}`
    });

    const hasError = deleteUserResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('class_delete_success') : t('class_delete_error')
    });

    setShowToast(true);

    if (!hasError) getSchoolClassesV2();
  };

  const onCreateNewClassV2 = async (newClassDetails) => {
    const createClassResponse = await fetchWithToken({
      method: 'POST',
      path: `front/school-admin/${schoolGuid}/classes`,
      data: {
        name: newClassDetails.name,
        school_year_guid: newClassDetails.schoolYear.value,
        education_discipline_guid: 'ed2',
        education_year_guid: newClassDetails.educationYear.value,
        program_guid: newClassDetails.program.value,
        time_zone: getTimeZone()
      }
    });

    const hasError = createClassResponse.status !== 'success';

    setToastMessage({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('class_create_success') : t('class_create_error')
    });

    setShowToast(true);

    if (!hasError) getSchoolClassesV2();
  };

  useEffect(() => {
    if (!user) return;

    setIsSchoolAdmin(user.role_guid === 'R03');
    setIsSuperAdmin(user.role_guid === 'R04');
    setSchoolGuid(getSchoolGuid());
  }, [user]);

  useEffect(() => {
    if (!schoolGuid) return;

    getData();
  }, [schoolGuid]);

  useEffect(() => {
    setIsLoading(true);
    hydrateUser();
  }, []);

  return {
    users,
    school,
    groups,
    classes,
    programs,
    onLogout,
    countries,
    isLoading,
    showToast,
    classesV2,
    currentTab,
    schoolGuid,
    schoolYears,
    toastMessage,
    isSuperAdmin,
    isSchoolAdmin,
    educationYears,
    educationLevels,
    schoolContracts,
    totalUsersInSchool,
    totalGroupsInSchool,
    totalSchoolContracts,
    totalClassesInSchool,
    totalClassesInSchoolV2,
    onEditClass,
    onChangeTab,
    onSaveSchool,
    setShowToast,
    onDeleteUser,
    onDeleteClass,
    onClickOnUser,
    onUpdateGroup,
    onUpdateSchool,
    getSchoolGroups,
    onCreateNewUser,
    getUsersInSchool,
    onCreateNewGroup,
    onCreateNewClass,
    onCreateNewContract,
    onDeleteUserFromGroup,
    // onDeleteClassV2,
    // onCreateNewClassV2,
    onDeleteUserFromClass,
    getSchoolClassesV2
  };
};

export default useEditSchool;
