import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import CellTag from '../../../../../components/table/cell/cellTypes/CellTag';

const useSchoolUsers = ({
  users,
  onCreateNewUser,
  getUsersInSchool,
  getSchoolGroups,
  setImportUsersDialogOpen,
  setNewUserSavedState,
  setNewUserDialogOpen
}) => {
  const [userToDelete, setUserToDelete] = useState(null);
  const [confirmDeleteUserDialogOpen, setConfirmDeleteUserDialogOpen] =
    useState(false);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchText, setSearchText] = useState('');

  const [isAddUsersToGroupDialogOpen, setIsAddUsersDialogOpen] =
    useState(false);

  const [isAddUsersToClassDialogOpen, setIsAddUsersToClassDialogOpen] =
    useState(false);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('lastname', {
      id: 'lastname',
      header: 'Lastname'
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: 'Email'
    }),
    columnHelper.accessor('role_name', {
      id: 'role_name',
      header: 'Role'
    }),
    columnHelper.accessor('group', {
      id: 'class',
      header: 'Class',
      cell: (info) => {
        const auxGroups = info.getValue();
        return (
          <CellTag
            tags={auxGroups.map((item) => ({
              label: item.name,
              description: `${item.education_year_name} ${item.education_level_name}`
            }))}
          />
        );
      }
    })
  ];

  const roles = [
    { guid: 'R01', role: 'Student' },
    { guid: 'R02', role: 'Teacher' },
    { guid: 'R03', role: 'School Admin' },
    { guid: 'R04', role: 'Editorial Admin' },
    { guid: 'R05', role: 'Help desk' }
  ];

  useEffect(() => {
    setFilteredUsers(users);

    if (searchText) {
      onSearch(searchText);
    }
  }, [users]);

  const onCloseNewUserDialog = () => {
    setNewUserDialogOpen(false);
  };

  const onCloseImportDialog = async () => {
    await getUsersInSchool();
    await getSchoolGroups();
    setImportUsersDialogOpen(false);
  };

  const onSubmitCreateNewUser = async (params) => {
    const isCreatedOk = await onCreateNewUser(params);

    if (isCreatedOk) {
      setNewUserSavedState(null);
    }
  };

  const onCloseAddUsersToGroupDialog = () => {
    setIsAddUsersDialogOpen(false);
  };

  const onCloseAddUsersToClassDialog = () => {
    setIsAddUsersToClassDialogOpen(false);
  };

  const onRemoveUserFromGroup = (user) => {
    setConfirmDeleteUserDialogOpen(true);
    setUserToDelete(user);
  };

  const handleCloseConfirmDeleteUserDialog = () => {
    setConfirmDeleteUserDialogOpen(false);
    setUserToDelete(null);
  };

  const onSearch = (searchText = '') => {
    // search by email, name, lastname, group, role
    let searchTextLowerCase = searchText.toLowerCase();
    setSearchText(searchTextLowerCase);

    const tempFilteredUsers = users.filter(
      (user) =>
        user?.email?.toLowerCase().includes(searchTextLowerCase) ||
        user?.name?.toLowerCase().includes(searchTextLowerCase) ||
        user?.lastname?.toLowerCase().includes(searchTextLowerCase) ||
        user?.role_name?.toLowerCase().includes(searchTextLowerCase) ||
        user?.group?.some((group) =>
          group?.name?.toLowerCase().includes(searchTextLowerCase)
        )
    );

    setFilteredUsers(tempFilteredUsers);
  };

  return {
    roles,
    columns,
    filteredUsers,
    userToDelete,
    confirmDeleteUserDialogOpen,
    isAddUsersToGroupDialogOpen,
    isAddUsersToClassDialogOpen,
    onSearch,
    onCloseImportDialog,
    onCloseNewUserDialog,
    onRemoveUserFromGroup,
    onSubmitCreateNewUser,
    setIsAddUsersDialogOpen,
    setIsAddUsersToClassDialogOpen,
    onCloseAddUsersToGroupDialog,
    onCloseAddUsersToClassDialog,
    handleCloseConfirmDeleteUserDialog
  };
};

export default useSchoolUsers;
