import { ThemeProvider, createTheme } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import DashboardWithSidebarMenu from '../layouts/dashboardWithSidebarMenu/DashboardWithSidebarMenu';
import BatchLicenses from '../pages/tenantDashboard/batchLicenses/List/BatchLicenses';
import Courses from '../pages/tenantDashboard/courses/ListCourses/Courses';
import Groups from '../pages/tenantDashboard/groups/List/Groups';
import Licenses from '../pages/tenantDashboard/licenses/List/Licenses';
import Programs from '../pages/tenantDashboard/programs/Programs';
import EditSchool from '../pages/tenantDashboard/schools/Edit/EditSchool';
import Schools from '../pages/tenantDashboard/schools/List/Schools';
import EditUser from '../pages/tenantDashboard/users/Edit/EditUser';
import Users from '../pages/tenantDashboard/users/List/Users';

const upperNavigation = [
  {
    name: 'Contents',
    href: '/courses',
    icon: 'document',
    key: ['courses', 'programs']
  },
  {
    name: 'Users',
    href: '/users',
    icon: 'users',
    key: ['users', 'schools', 'groups']
  },
  {
    name: 'Licenses',
    href: '/licenses',
    icon: 'bar-menu-license',
    key: ['licenses', 'licenses-batch']
  }
];

const theme = createTheme({
  palette: {
    common: {
      black: '#303949'
    },
    primary: {
      main: '#5134e1',
      light: '#acacf7',
      dark: '#3703a4'
    },
    success: {
      main: '#00e19a',
      light: '#B7F1E2',
      dark: '#13B481'
    },
    error: {
      main: '#ff3d30',
      light: '#FFE5E2',
      dark: '#DE2A1E'
    },
    warning: {
      main: '#ffae56',
      light: '#FFF0DE',
      dark: '#E8963D'
    },
    info: {
      main: '#45dbf4',
      light: '#DDF9FF',
      dark: '#1FB9D2'
    },
    text: {
      primary: '#303949'
    },
    grey: {
      900: '#303949',
      800: '#4d586c',
      700: '#647082',
      600: '#7a8598',
      500: '#939db0',
      400: '#afb7c6',
      300: '#c8cfda',
      200: '#dfe5ec',
      100: '#f0f4f8',
      50: '#fbfcfd'
    },
    action: {
      disabledBackground: '#f0f3f8',
      disabled: '#dfe5ec'
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontSize: '64px',
      fontWeight: 600,
      lineHeight: 1.75
    },
    h2: {
      fontSize: '48px',
      fontWeight: 600,
      lineHeight: 1.5
    },
    h3: {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: 1.25
    },
    h4: {
      fontSize: '20px',
      lineHeight: 1.4286
    },
    h5: {
      fontSize: '16px',
      lineHeight: 1.4286
    }
  },
  shape: {
    borderRadius: 4
  }
});

export const tenantDashboardRoutes = [
  {
    element: (
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    ),
    children: [
      {
        element: <DashboardWithSidebarMenu upperNavigation={upperNavigation} />,
        children: [
          {
            path: '/',
            exact: true,
            element: <Navigate to='/courses' />
          },
          {
            path: '/schools',
            element: <Schools />
          },
          {
            path: '/users',
            element: <Users />
          },
          {
            path: '/programs',
            element: <Programs />
          },
          {
            path: '/courses',
            element: <Courses />
          },
          {
            path: '/groups',
            element: <Groups />
          },
          {
            path: '/licenses',
            element: <Licenses />
          },
          {
            path: '/licenses-batch',
            element: <BatchLicenses />
          }
        ]
      },
      {
        path: '/schools/:schoolGuid',
        element: <EditSchool />
      },
      {
        path: '/users/:userGuid',
        element: <EditUser />
      }
      // {
      //   path: '/activities/:activityId',
      //   element: <SingleActivity />
      // }
    ]
  }
];
