import CallMade from '@mui/icons-material/CallMade';
import { Container, Grid, Typography } from '@mui/material';
import BackButton from '../../../atoms/BackButton/BackButton';
import EDButton from '../../../atoms/EDButton/EDButton';
import Toast from '../../../atoms/Toast/Toast';
import EDTabs from '../../../components/EDTabs/EDTabs';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import './editProgram.scss';
import ProgramActivities from './tabs/ProgramActivities';
import ProgramContent from './tabs/ProgramContent/ProgramContent';
import ProgramDetails from './tabs/ProgramDetails';
import ProgramLoading from './tabs/ProgramLoading';
import LanguageSelector from './tabs/components/LanguageSelector/LanguageSelector';
import ProgramStatusTag from './tabs/components/ProgramStatusTag';
import SavingTag from './tabs/components/SavingTag';
import useEditProgram from './useEditProgram';

const EditProgram = () => {
  const {
    program,
    tenants,
    phasers,
    showToast,
    isLoading,
    showSaving,
    currentTab,
    programGuid,
    toastMessage,
    programErrors,
    educationYears,
    lastUpdateString,
    isUserProgramEditor,
    programPreviewLanguage,
    t,
    onEditUnit,
    onChangeTab,
    onCreateUnit,
    setShowToast,
    onEditLesson,
    onDeleteUnit,
    onReorderUnit,
    setShowSaving,
    onSaveProgram,
    onDeleteLesson,
    canEditProgram,
    onCreateLesson,
    onUpdateProgram,
    onReorderLesson,
    onPublishProgram,
    onAddLOsToLesson,
    onRemoveLOsFromLesson,
    onProgramPreviewLanguageChange
  } = useEditProgram();

  return (
    <FullPageLayout
      customClass='edit-program-layout'
      left={
        <>
          <BackButton />
          {!isLoading && currentTab === 'content' && (
            <LanguageSelector
              languageValue={programPreviewLanguage}
              handleChange={onProgramPreviewLanguageChange}
            />
          )}
        </>
      }
      title={<Typography variant='h2'>{program?.name}</Typography>}
      subtitle={lastUpdateString}
      right={
        <>
          {!isLoading && (
            <ProgramStatusTag
              isPublished={program?.synchronizedAt}
              isPublishing={program?.status === 'PUBLISHING-IN-PROGRESS'}
            />
          )}
          {!isLoading && currentTab === 'content' && showSaving && (
            <SavingTag handleShowSavingTag={setShowSaving} />
          )}
        </>
      }
      footerRight={
        !isLoading &&
        currentTab === 'metadata' &&
        canEditProgram() && (
          <EDButton
            onClick={onSaveProgram}
            variant='contained'
            disabled={false}
            disableElevation
          >
            {t('program_content_save')}
          </EDButton>
        )
      }
      footerLeft={
        !isLoading &&
        currentTab === 'content' &&
        program?.status !== 'DRAFT' &&
        !isUserProgramEditor && (
          <EDButton
            onClick={onPublishProgram}
            variant='outlined'
            startIcon={<CallMade />}
            disabled={false}
            disableElevation
          >
            {t('program_content_publish')}
          </EDButton>
        )
      }
    >
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EDTabs
              tabs={
                canEditProgram()
                  ? [
                      { name: t('program_content_tab'), key: 'content' },
                      { name: t('program_metadata_tab'), key: 'metadata' },
                      { name: t('program_activities_tab'), key: 'activities' }
                    ]
                  : [
                      { name: t('program_content_tab'), key: 'content' },
                      { name: t('program_activities_tab'), key: 'activities' }
                    ]
              }
              currentTab={currentTab}
              onClick={onChangeTab}
            />
          </Grid>
          {isLoading && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <ProgramLoading />
            </div>
          )}
          {!isLoading && currentTab === 'metadata' && (
            <ProgramDetails
              program={program}
              onUpdateProgram={onUpdateProgram}
              educationYears={educationYears}
              tenants={tenants}
              phasers={phasers}
            />
          )}
          {!isLoading && currentTab === 'content' && (
            <ProgramContent
              program={program}
              onCreateUnit={onCreateUnit}
              onCreateLesson={onCreateLesson}
              educationYears={educationYears}
              onEditUnit={onEditUnit}
              onEditLesson={onEditLesson}
              onReorderUnit={onReorderUnit}
              onReorderLesson={onReorderLesson}
              onDeleteUnit={onDeleteUnit}
              onDeleteLesson={onDeleteLesson}
              onAddLOsToLesson={onAddLOsToLesson}
              onRemoveLOsFromLesson={onRemoveLOsFromLesson}
              programErrors={programErrors}
              canEditProgram={canEditProgram()}
              programPreviewLanguage={programPreviewLanguage}
            />
          )}
          {!isLoading && currentTab === 'activities' && (
            <ProgramActivities program={program} />
          )}
        </Grid>
        {showToast && (
          <Toast
            handleShowToast={setShowToast}
            type={toastMessage.type}
            text={toastMessage.message}
          />
        )}
      </Container>
    </FullPageLayout>
  );
};

export default EditProgram;
