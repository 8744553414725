import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AlertDialogWithIcon from '../../../atoms/AlertDialogWithIcon/AlertDialogWithIcon';
import BackButton from '../../../atoms/BackButton/BackButton';
import EDButton from '../../../atoms/EDButton/EDButton';
import Toast from '../../../atoms/Toast/Toast';
import EDSeedStatusSelect from '../../../components/EDSeedStatusSelect/EDSeedStatusSelect';
import EDTabs from '../../../components/EDTabs/EDTabs';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import SingleActivityJSON from './components/SingleActivityJSON';
import SingleActivityMetadata from './components/SingleActivityMetadata';
import SingleActivityPrograms from './components/SingleActivityPrograms';
import useSingleActivity from './useSingleActivity';

const SingleActivity = () => {
  const { t } = useTranslation();
  const {
    isUserReviewer,
    isUserProgramEditor,
    isLoading,
    activity,
    tabs,
    currentTab,
    onSaveButtonClick,
    hasError,
    hasChanges,
    showToast,
    toastMessage,
    showAlert,
    onBackWithConfirmation,
    onChangeTab,
    setHasChanges,
    setHasError,
    setShowToast,
    setToastMessage,
    setActivityCode,
    setActivityCodeApp,
    setActivityMetadata,
    onAlertConfirmation,
    onAlertCancel,
    programsTableColumns,
    activityPrograms,
    onGenerateMobileJSON,
    setShowAlert
  } = useSingleActivity();

  return (
    <FullPageLayout
      left={<BackButton onClick={onBackWithConfirmation} />}
      title={
        <Typography>{`${t('edit_activity_title')} "${
          activity?.name
        }" `}</Typography>
      }
      right={
        !isUserReviewer &&
        !isUserProgramEditor && (
          <EDSeedStatusSelect
            activity={activity}
            setShowToast={setShowToast}
            setToastMessage={setToastMessage}
          />
        )
      }
      {...(currentTab !== 'programs'
        ? {
            footerRight: !isUserReviewer && !isUserProgramEditor && (
              <EDButton
                disableElevation
                color='primary'
                variant='contained'
                onClick={onSaveButtonClick[currentTab]}
                sx={{
                  padding: '0.75rem 1.5rem',
                  textTransform: 'none'
                }}
                disabled={hasError || !hasChanges}
              >
                {t('Save')}
              </EDButton>
            )
          }
        : {})}
      fullWidth
    >
      <Container maxWidth='xl'>
        <AlertDialogWithIcon
          isOpen={showAlert}
          title={t('back_without_saving_title')}
          description={t('back_without_saving_description')}
          onConfirm={onAlertConfirmation}
          onCancel={onAlertCancel}
          cancelText={t('cancel')}
          confirmText={t('back_without_saving_exit')}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EDTabs tabs={tabs} currentTab={currentTab} onClick={onChangeTab} />
          </Grid>
          {currentTab === 'metadata' && (
            <SingleActivityMetadata
              isLoading={isLoading}
              activity={activity}
              onGetActivityMetadata={(metadata) =>
                setActivityMetadata(metadata)
              }
              onHasChanges={setHasChanges}
              onHasError={setHasError}
            />
          )}
          {currentTab === 'json' && activity && (
            <SingleActivityJSON
              json={JSON.parse(activity.data)}
              isCompatibleDesktop={activity.isCompatibleDesktop}
              jsonApp={JSON.parse(activity.dataApp)}
              isCompatibleApp={activity?.isCompatibleApp}
              isLoading={isLoading}
              guid={activity?.guid}
              onSetActivityJSON={(code) => setActivityCode(code)}
              onSetActivityJSONApp={(code) => setActivityCodeApp(code)}
              onHasChanges={setHasChanges}
              hasChanges={hasChanges}
              onHasError={setHasError}
              onGenerateMobileJSON={onGenerateMobileJSON}
              readOnly={isUserReviewer || isUserProgramEditor}
              onSetShowAlert={setShowAlert}
              activityName={activity?.name}
            />
          )}
          {currentTab === 'programs' && (
            <SingleActivityPrograms
              columns={programsTableColumns}
              data={activityPrograms}
              isLoading={isLoading}
            />
          )}
        </Grid>
        {showToast && (
          <Toast
            handleShowToast={setShowToast}
            type={toastMessage.type}
            text={toastMessage.text}
          />
        )}
      </Container>
    </FullPageLayout>
  );
};

export default SingleActivity;
