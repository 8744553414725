import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../../../atoms/EDInput/EDInputWithLabel';

const CreateEditClassDialogV2 = ({
  isOpen,
  onClose,
  programs,
  isLoading,
  isEditMode,
  schoolYears,
  educationLevels,
  classDetails,
  selectedClassToEdit,
  onSubmit
}) => {
  const { t } = useTranslation();
  const baseClassDetails = {
    name: '',
    program: null,
    schoolYear: null,
    educationLevel: null,
    educationYear: null
  };

  const [newClassDetails, setNewClassDetails] = useState(baseClassDetails);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programsOptions, setProgramsOptions] = useState([]);
  const [schoolYearsOptions, setSchoolYearsOptions] = useState([]);
  const [educationLevelsOptions, setEducationLevelsOptions] = useState([]);
  const [educationYearsOptions, setEducationYearsOptions] = useState([]);
  const [canProceed, setCanProceed] = useState(false);
  const [buttonText, setButtonText] = useState('');

  const onCancelDialog = () => {
    setNewClassDetails(baseClassDetails);
    setSelectedProgram(null);
    onClose();
  };

  useEffect(() => {
    setSchoolYearsOptions(
      schoolYears.map((item) => ({
        label: item.name,
        value: item.guid
      }))
    );
  }, [schoolYears]);

  useEffect(() => {
    setEducationLevelsOptions(
      educationLevels.map((item) => ({
        label: item.level,
        value: item.guid
      }))
    );
  }, [educationLevels]);

  useEffect(() => {
    console.log('newClassDetails', newClassDetails);
    setCanProceed(
      newClassDetails.name &&
        newClassDetails.schoolYear?.value &&
        newClassDetails.educationLevel?.value &&
        newClassDetails.educationYear?.value &&
        newClassDetails.program?.value
    );
  }, [newClassDetails, selectedProgram]);

  useEffect(() => {
    let filteredProgramsByState = getFilteredPrograms();
    filteredProgramsByState = filteredProgramsByState.map((item) => ({
      label: [item.education_year_name, item.education_level_name, item.name]
        .filter(Boolean) // Remove any falsy values (null, undefined, '', etc.)
        .join(' '),
      value: item.guid
    }));

    setProgramsOptions(filteredProgramsByState);
  }, [isEditMode, classDetails]);

  useEffect(() => {
    if (isLoading) {
      if (isEditMode) {
        setButtonText('Saving...');
      } else {
        setButtonText('Creating ...');
      }
    } else {
      if (isEditMode) {
        setButtonText('Save');
      } else {
        setButtonText('Create');
      }
    }
  }, [isLoading, isEditMode]);

  const getFilteredPrograms = () => {
    return programs.filter((item) => item.is_accessible && item.is_active);
  };

  useEffect(() => {
    if (!isLoading) {
      fillClassDetails();
    }
  }, [selectedClassToEdit]);

  const fillClassDetails = () => {
    const baseClassDetails = {
      name: '',
      program: null,
      schoolYear: null,
      educationLevel: null,
      educationYear: null
    };
    const details = {
      guid: '0a7e4c40-a5a9-11ef-93b9-610d763d0c0d',
      name: 'test 3 group',
      code: 'PxF2AJ',
      numberOfTeachers: 6,
      numberOfStudents: 2,
      isLessonLocked: true,
      isUppercase: false,
      programName: 'Magic Demo - multilanguage',
      schoolYearName: '2024',
      educationLevelName: 'Educación Primaria',
      educationYearName: '5.°',
      status: true,
      ranking: false
    };

    if (selectedClassToEdit) {
      const tempEducationYearOptions = educationLevels
        .find((item) => item.level === selectedClassToEdit.educationLevelName)
        .educationYears.map((item) => ({
          label: item.year,
          value: item.guid
        }));

      setEducationYearsOptions(tempEducationYearOptions);

      setNewClassDetails({
        guid: selectedClassToEdit.guid,
        name: selectedClassToEdit.name,
        schoolYear: schoolYearsOptions?.find(
          (item) => item.label === selectedClassToEdit?.schoolYearName
        ),
        educationLevel: educationLevelsOptions?.find(
          (item) => item.label === selectedClassToEdit?.educationLevelName
        ),
        educationYear: tempEducationYearOptions?.find(
          (item) => item.label === selectedClassToEdit?.educationYearName
        ),
        program: programsOptions?.find(
          (item) => item.value === selectedClassToEdit?.programGuid
        ),
        isLessonLocked: selectedClassToEdit.isLessonLocked,
        isUppercase: selectedClassToEdit.isUppercase,
        status: selectedClassToEdit.status,
        ranking: selectedClassToEdit.ranking
      });
    }
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onCancelDialog}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h4' fontWeight={600}>
                {isEditMode ? t('edit_class') : t('create_class')}
              </Typography>
            </Stack>
          </Grid>

          {isEditMode && (
            <Grid item xs={12}>
              <Alert severity='warning' variant='standard'>
                {t('edit_traces_loss_warning')}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <EDInputWithLabel
              label='Class name'
              value={newClassDetails.name}
              onChange={({ target: { value } }) => {
                setNewClassDetails({ ...newClassDetails, name: value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EDAutocompleteWithLabel
              showLabel
              label='School year'
              disableClearable
              disableAutocomplete
              options={schoolYearsOptions}
              value={newClassDetails.schoolYear}
              onChange={(e, selectedSchoolYear) => {
                setNewClassDetails({
                  ...newClassDetails,
                  schoolYear: selectedSchoolYear
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EDAutocompleteWithLabel
              showLabel
              label='Level'
              disableClearable
              disableAutocomplete
              options={educationLevelsOptions}
              value={newClassDetails.educationLevel}
              onChange={(e, selectedEducationLevel) => {
                setNewClassDetails({
                  ...newClassDetails,
                  educationLevel: selectedEducationLevel,
                  educationYear: null
                });

                setEducationYearsOptions(
                  educationLevels
                    .find((item) => item.guid === selectedEducationLevel.value)
                    .educationYears.map((item) => ({
                      label: item.year,
                      value: item.guid
                    }))
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EDAutocompleteWithLabel
              showLabel
              label='Year'
              disableClearable
              disableAutocomplete
              options={educationYearsOptions}
              value={newClassDetails.educationYear}
              onChange={(e, selectedEducationYear) => {
                setNewClassDetails({
                  ...newClassDetails,
                  educationYear: selectedEducationYear
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <EDAutocompleteWithLabel
              showLabel
              label='Program'
              disableClearable
              options={programsOptions}
              value={newClassDetails.program}
              onChange={(e, selectedProgram) => {
                setNewClassDetails({
                  ...newClassDetails,
                  program: selectedProgram
                });
              }}
              // disable dropdown from overflowing outside the screen
              ListboxProps={{ style: { maxHeight: 220 } }}
              disableAutocomplete
              // disable dropdown from opening upwards and covering the input on top
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: []
                      }
                    }
                  ]
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onCancelDialog}>
          {t('cancel')}
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canProceed || isLoading}
          onClick={() =>
            onSubmit({
              ...(isEditMode && {
                guid: selectedClassToEdit.guid,
                isLessonLocked: selectedClassToEdit.isLessonLocked,
                isUppercase: selectedClassToEdit.isUppercase,
                status: selectedClassToEdit.status,
                ranking: selectedClassToEdit.ranking
              }),
              name: newClassDetails.name,
              programGuid: newClassDetails.program?.value,
              schoolYearGuid: newClassDetails.schoolYear?.value,
              educationLevel: newClassDetails.educationLevel?.value,
              educationYearGuid: newClassDetails.educationYear?.value
            })
          }
        >
          {buttonText}
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditClassDialogV2;
