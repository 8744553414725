import { Alert, AlertTitle, TextField } from '@mui/material';
import { useState } from 'react';
import Button from '../../atoms/button/Button';
import Typography from '../../atoms/typography/Typography';

import { useTranslation } from 'react-i18next';
import './LoginForm.scss';

const LoginForm = ({
  error,
  setError,
  isLoading,
  onLoginWithGoogle,
  onLoginWithEmailAndPassword
}) => {
  const { t } = useTranslation();

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });

  return (
    <div className='login-form__container'>
      <div className='login-form__header'>
        <Typography variant='h2'>{t('login_to_dashboard')}</Typography>
      </div>
      <div className='login-form__body'>
        {error && (
          <div className='login-form__input'>
            <Alert severity='error'>
              <AlertTitle>{t(error)}</AlertTitle>
            </Alert>
          </div>
        )}
        <div className='login-form__input'>
          <TextField
            fullWidth
            disabled={isLoading}
            type='email'
            placeholder={t('email')}
            value={credentials.email}
            onChange={({ target: { value } }) => {
              setCredentials({
                ...credentials,
                email: value
              });

              setError('');
            }}
          />
        </div>
        <div className='login-form__input'>
          <TextField
            fullWidth
            disabled={isLoading}
            type='password'
            placeholder={t('password')}
            value={credentials.password}
            onChange={({ target: { value } }) => {
              setCredentials({
                ...credentials,
                password: value
              });

              setError('');
            }}
          />
        </div>
        <div className='login-form__submit'>
          <Button
            color='primary'
            variant='contained'
            size='lg'
            fullWidth
            onClick={() => onLoginWithEmailAndPassword(credentials)}
            loading={isLoading}
            text={isLoading ? t('logging in') : t('login')}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
