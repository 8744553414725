import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Check from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ChecklistIcon from '@mui/icons-material/Rule';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Download } from '@mui/icons-material';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import FileReader from './components/FileReader';
import './ImportUsersDialog.scss';
import useImportUsers from './useImportUsers';

const ImportUsersDialog = ({ isOpen, onClose, schoolGuid }) => {
  const { t } = useTranslation();

  const {
    handleUploadData,
    onCheckBatchUsersData,
    onCreateBatchUsers,
    fileRawData,
    checksData,
    usernameErrorsCount,
    groupErrorsCount,
    groupUnassignedCount,
    isCreatingUsers,
    isCheckingUsers,
    hasReadFile,
    creationStatus,
    missingLicenseErrorsCount,
    invalidLicenseErrorsCount
  } = useImportUsers({ schoolGuid });

  const [currentStep, setCurrentStep] = useState(0);

  const gotoUpload = () => {
    setCurrentStep(0);
  };

  const gotoCheckUsers = () => {
    setCurrentStep(1);
    onCheckBatchUsersData();
  };

  const gotoConfirmation = () => {
    setCurrentStep(2);
    onCreateBatchUsers();
  };

  return (
    <div className='import-users-dialog'>
      <Dialog fullWidth maxWidth='lg' open={isOpen} onClose={onClose}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>
                Import users
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stepper activeStep={currentStep}>
                <Step>
                  <StepLabel variant='h3'>Upload file</StepLabel>
                </Step>
                <Step>
                  <StepLabel variant='h3'>Check users</StepLabel>
                </Step>
                <Step>
                  <StepLabel variant='h3'>Confirmation</StepLabel>
                </Step>
              </Stepper>
            </Grid>

            {/* Upload step */}
            {currentStep === 0 && (
              <Grid item md={12}>
                <Grid container spacing={3}>
                  <Grid item md={9}>
                    <FileReader onUploadAccepted={handleUploadData} />
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      fullWidth
                      variant='outlined'
                      startIcon={<Download />}
                      sx={{ height: '80% ' }}
                      onClick={() => {
                        // generate, populate and download csv file
                        const csv =
                          'Name,Last Name,Email / username,Role (Student | Teacher),Password,Class Code,Is Demo (yes | no),devmode (yes | no),License (ES|EN|PT), Case sensitive';
                        const blob = new Blob([csv], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = 'template.csv';
                        document.body.appendChild(a);
                        a.click();
                      }}
                    >
                      Download template
                    </Button>
                  </Grid>
                </Grid>

                {fileRawData ? (
                  <div className='import-users-dialog__data'>
                    <div className='import-users-dialog__data-result'>
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert variant='filled' severity='info'>
                          CSV Total users:{' '}
                          {fileRawData?.length && fileRawData?.length > 1
                            ? fileRawData?.length - 1
                            : '-'}
                        </Alert>
                      </Stack>
                    </div>

                    <div className='import-users-dialog__data-table'>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table stickyHeader size='small'>
                            <TableHead>
                              <TableRow>
                                {fileRawData[0].map((header, index) => (
                                  <TableCell key={index}>{header}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fileRawData.slice(1).map((row, index) => (
                                <TableRow key={index}>
                                  {row.map((cell, index) => (
                                    <TableCell key={index}>{cell}</TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </div>
                  </div>
                ) : (
                  hasReadFile && (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )
                )}
              </Grid>
            )}

            {/* Check step */}
            {currentStep === 1 && (
              <Grid item md={12}>
                {checksData ? (
                  <div className='import-users-dialog__check'>
                    <div className='import-users-dialog__check-result'>
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert
                          variant='filled'
                          severity={
                            usernameErrorsCount === 0 && groupErrorsCount === 0
                              ? 'success'
                              : 'info'
                          }
                        >
                          {`Total users: ${checksData?.length || '-'}`}
                          {groupUnassignedCount > 0 &&
                            ` - 🙄 Users unassigned: ${groupUnassignedCount}`}
                        </Alert>
                        {usernameErrorsCount > 0 && (
                          <Alert variant='filled' severity='error'>
                            Username errors: {usernameErrorsCount} - Class
                            errors: {groupErrorsCount}
                          </Alert>
                        )}
                        {missingLicenseErrorsCount > 0 && (
                          <Alert variant='filled' severity='error'>
                            Missing license errors: {missingLicenseErrorsCount}
                          </Alert>
                        )}
                        {invalidLicenseErrorsCount > 0 && (
                          <Alert variant='filled' severity='error'>
                            Invalid license errors: {invalidLicenseErrorsCount}
                          </Alert>
                        )}
                      </Stack>
                    </div>
                    <div className='import-users-dialog__check-table'>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table stickyHeader size='small'>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Username check</TableCell>
                                <TableCell>Class check</TableCell>
                                <TableCell>License check</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {checksData.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    {row.username_check_has_error ||
                                    row.group_check_has_error ? (
                                      <WarningIcon color='error' />
                                    ) : (
                                      <CheckCircleIcon color='success' />
                                    )}
                                  </TableCell>
                                  <TableCell>{row.username}</TableCell>
                                  <TableCell>
                                    {row.username_check_has_error ? (
                                      <div className='import-users-dialog__table-error'>
                                        <WarningAmberIcon color='error' />
                                        {row.username_check_message}
                                      </div>
                                    ) : (
                                      <Check color='success' />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {row.group_check_has_error ? (
                                      <div className='import-users-dialog__table-error'>
                                        <WarningAmberIcon color='error' />
                                        {row.group_check_message}
                                      </div>
                                    ) : row.group === '' ? (
                                      <div className='import-users-dialog__table-error'>
                                        <WarningAmberIcon color='warning' />{' '}
                                        {row.group_check_message}
                                      </div>
                                    ) : (
                                      <Check color='success' />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {row.missing_license_check_has_error ||
                                    row.invalid_license_check_has_error ? (
                                      <div className='import-users-dialog__table-error'>
                                        <WarningAmberIcon color='error' />{' '}
                                        {row.missing_license_check_message ||
                                          row.invalid_license_check_message}
                                      </div>
                                    ) : (
                                      <Check color='success' />
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </div>
                  </div>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            )}

            {/* Confirmation step */}
            {currentStep === 2 && (
              <Grid item md={12}>
                {!isCreatingUsers ? (
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    {creationStatus === 'success' && (
                      <Alert variant='filled' severity='success'>
                        <AlertTitle>😎 Success!</AlertTitle>
                        {checksData?.length} users have been created from the
                        CSV file.
                      </Alert>
                    )}
                    {creationStatus === 'error' && (
                      <Alert variant='filled' severity='error'>
                        <AlertTitle>😢 Error</AlertTitle>
                        An error ocurred while importing the users. Please
                        review your data.
                      </Alert>
                    )}
                  </Stack>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} justifyContent='space-between'>
              <div>
                {currentStep === 1 && (
                  <EDButton
                    variant='outlined'
                    onClick={gotoUpload}
                    startIcon={<ArrowBackIcon />}
                  >
                    Go back
                  </EDButton>
                )}
              </div>
              <div>
                {currentStep !== 2 && (
                  <EDButton variant='text' onClick={onClose}>
                    {t('Cancel')}
                  </EDButton>
                )}
                {currentStep === 0 && (
                  <EDButton
                    disabled={!hasReadFile}
                    variant='contained'
                    disableElevation
                    onClick={gotoCheckUsers}
                    startIcon={<ChecklistIcon />}
                  >
                    Next to check
                  </EDButton>
                )}
                {currentStep === 1 && (
                  <EDButton
                    disabled={
                      isCheckingUsers ||
                      groupErrorsCount > 0 ||
                      usernameErrorsCount > 0 ||
                      missingLicenseErrorsCount > 0 ||
                      invalidLicenseErrorsCount > 0
                    }
                    variant='contained'
                    disableElevation
                    onClick={gotoConfirmation}
                    startIcon={<CloudUploadIcon />}
                  >
                    Create users
                  </EDButton>
                )}
                {currentStep === 2 && (
                  <EDButton
                    disabled={isCreatingUsers}
                    variant='contained'
                    disableElevation
                    onClick={onClose}
                  >
                    Done
                  </EDButton>
                )}
              </div>
            </Stack>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImportUsersDialog;
