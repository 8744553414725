import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const LicenseFilter = ({ defaultValue, onChange, ...props }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLicenses = async () => {
    const licenses = await fetchWithToken({
      method: 'GET',
      path: 'licenses/personals'
    });

    setOptions(
      licenses?.data?.personalLicenses.map((license) => {
        return {
          label: license.code,
          value: license.code
        };
      })
    );

    setIsLoading(false);
  };

  useEffect(() => {
    getLicenses();
  }, []);

  return (
    <EDAutocompleteWithLabel
      {...props}
      isLoading={isLoading}
      label={t('license')}
      inputProps={{ autoComplete: 'new-password' }}
      options={options}
      defaultValue={defaultValue}
      onChange={(e, value) => {
        onChange(value);
      }}
      showLabel
      disableClearable
      disableAutocomplete
    />
  );
};

export default LicenseFilter;
