import { useTranslation } from 'react-i18next';

const TableResultsText = ({ isLoading, sectionNameText, total, classname }) => {
  const { t } = useTranslation();
  const withoutResultsText = t('without_results_text');
  const loadingText = t('loading_text');
  if (isLoading) return <span>{loadingText}</span>;

  if (total > 0)
    return (
      <>
        <span
          style={{
            color: '#414b5c'
          }}
        >
          {Intl.NumberFormat().format(total)}
        </span>{' '}
        {sectionNameText}
      </>
    );

  return <span>{withoutResultsText}</span>;
};

export default TableResultsText;
