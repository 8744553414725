import {
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDCard from '../../../../atoms/EDCard/EDCard';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDEducationLevelFilter from '../../../../components/filters/EDEducationLevelFilter';
import EDKnowledgeComponentFilter from '../../../../components/filters/EDKnowledgeComponentFilter';
import EDStandardsFilter from '../../../../components/filters/EDStandardFilter';
import PillTabs from '../../../../components/pillTabs/PillTabs';
import { generateEducationYear } from '../../../../utils/helpers/generateEducationYear';

const LearningOutcomeMetadata = ({ learningOutcome, onChange }) => {
  const { t } = useTranslation();
  const [BNCC, setBNCC] = useState();
  const [CC, setCC] = useState();

  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    if (metadata.name || !learningOutcome.name) return;

    const filteredBNCC = learningOutcome?.standards
      ?.filter((item) => item.name.startsWith('EF'))
      .map((obj) => obj.guid);

    const filteredCC = learningOutcome?.standards
      ?.filter((item) => item.name.startsWith('CC'))
      .map((obj) => obj.guid);

    const _metadata = {
      name: learningOutcome?.name,
      shortName: learningOutcome?.name,
      description: learningOutcome?.description, // legacy description
      knowledgeComponent: learningOutcome?.linkFrom?.map((item) => ({
        ...item,
        label: item.name,
        name: item.name,
        id: item.guid,
        value: item.guid
      }))[0],
      id: learningOutcome?.guid,
      descriptionLanguages: learningOutcome?.descriptionLanguages,
      standards: learningOutcome?.standards.map((item) => item.guid),
      BNCC: filteredBNCC,
      CC: filteredCC,
      internalEducationYear: learningOutcome?.internalEducationYear
        ? {
            id: learningOutcome?.internalEducationYear?.guid,
            year: learningOutcome?.internalEducationYear?.year,
            label: generateEducationYear(
              learningOutcome?.internalEducationYear,
              t
            )
          }
        : null,
      observation: learningOutcome?.observation
    };

    setMetadata(_metadata);
    // console.log('LEARNING OUTCOME API', learningOutcome);
  }, [learningOutcome]);

  useEffect(() => {
    if (!metadata.name || !metadata.standards) return;

    const filterStandards = (_standards, prefix) =>
      _standards.filter((item) => item.startsWith(prefix));

    const EFStandard = filterStandards(BNCC || [], 'EF');
    const CCStandard = filterStandards(CC || [], 'CC');
    const tempStandards = [...EFStandard, ...CCStandard];

    setMetadata({ ...metadata, standards: tempStandards });
  }, [CC, BNCC]);

  useEffect(() => {
    onChange({
      name: metadata?.name,
      description: metadata?.description,
      observation: metadata?.observation,
      internalEducationYearGuid:
        metadata?.internalEducationYear?.id || metadata?.internalEducationYear,
      linkFromGuid: metadata?.linkFromGuid,
      standards: (metadata?.standards || []).map(
        (item) => item.guid || item.id || item
      ),
      linkFrom: metadata?.knowledgeComponent,
      shortName: metadata?.name,
      descriptionLanguages: metadata?.descriptionLanguages
    });
  }, [metadata]);

  const [currentLanguageToDisplay, setCurrentLanguageToDisplay] =
    useState('EN');

  const sortLanguages = () => {
    const order = { es: 1, en: 2, pt: 3 };
    const _tabsLanguages = metadata?.descriptionLanguages?.map((item) => ({
      name: item.lang,
      key: item.lang
    }));

    return _tabsLanguages.sort((a, b) => {
      return order[a.key.toLowerCase()] - order[b.key.toLowerCase()];
    });
  };

  if (!metadata.name) return <CircularProgress />;

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h3'>{t('metadata_tab_title')}</Typography>
            </Grid>
            <Grid item md={4}>
              <EDInputWithLabel
                required
                label={t('name')}
                placeholder={t('name')}
                value={metadata?.name || ''}
                onChange={(e) =>
                  setMetadata({ ...metadata, name: e.target.value })
                }
              />
            </Grid>
            <Grid item md={4}>
              <EDKnowledgeComponentFilter
                required
                showLabel
                disableClearable
                defaultValue={metadata?.knowledgeComponent}
                onChange={(kc, allData) => {
                  setMetadata({
                    ...metadata,
                    linkFromGuid: [kc]
                  });
                }}
              />
            </Grid>
            <Grid item md={4}>
              <EDInputWithLabel
                label='ID'
                placeholder='ID'
                value={metadata?.id || ''}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction='column' spacing={3}>
                <div>
                  <PillTabs
                    tabs={sortLanguages()}
                    currentTab={currentLanguageToDisplay}
                    onChangeTab={(value) => {
                      setCurrentLanguageToDisplay(value);
                    }}
                  />
                </div>
                <EDInputWithLabel
                  required
                  label='Description'
                  multiline
                  rows={3}
                  placeholder='Write'
                  value={
                    metadata?.descriptionLanguages?.find(
                      (item) => item.lang === currentLanguageToDisplay
                    )?.description || ''
                  }
                  onChange={(e) =>
                    setMetadata({
                      ...metadata,
                      descriptionLanguages: metadata?.descriptionLanguages?.map(
                        (item) => {
                          if (item.lang === currentLanguageToDisplay) {
                            return {
                              ...item,
                              description: e.target.value
                            };
                          }
                          return item;
                        }
                      )
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item md={4}>
              {metadata.name && (
                <EDStandardsFilter
                  showLabel
                  type='BNCC'
                  multiple={true}
                  defaultValue={
                    Array.isArray(metadata?.BNCC)
                      ? metadata?.BNCC
                      : [metadata?.BNCC]
                  }
                  onChange={(value) => {
                    if (value) {
                      const parsedValues = value.map((item) => {
                        if (typeof item === 'object') return item.id;
                        return item;
                      });
                      setBNCC(parsedValues);
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item md={4}>
              {metadata.name && (
                <EDStandardsFilter
                  showLabel
                  type='CC'
                  multiple={true}
                  defaultValue={
                    Array.isArray(metadata?.CC) ? metadata?.CC : [metadata?.CC]
                  }
                  onChange={(value) => {
                    if (value) {
                      const parsedValues = value.map((item) => {
                        if (typeof item === 'object') return item.id;
                        return item;
                      });
                      setCC(parsedValues);
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item md={4}>
              {metadata.name && (
                <EDEducationLevelFilter
                  showLabel
                  label={t('learning_outcome_column_internal_education_year')}
                  defaultValue={metadata?.internalEducationYear}
                  onChange={(value) => {
                    setMetadata({
                      ...metadata,
                      internalEducationYear: value
                    });
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <EDInputWithLabel
                label={t('lo_observations_label')}
                rows={3}
                placeholder={t('lo_observations_placeholder')}
                multiline
                value={metadata?.observation || ''}
                onChange={({ target: { value } }) => {
                  setMetadata({
                    ...metadata,
                    observation: value
                  });
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </EDCard>
    </Grid>
  );
};

export default LearningOutcomeMetadata;
