import { Button, Container, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import BackButton from '../../../../atoms/BackButton/BackButton'
import EDButton from '../../../../atoms/EDButton/EDButton'
import Toast from '../../../../atoms/Toast/Toast'
import TDTabs from '../../../../components/TDTabs/TDTabs'
import FullPageLayout from '../../../../layouts/dashboardFullPage/FullPageLayout'
import NoSchoolFound from './NoSchoolFound'
import SchoolClassesV2 from './tabs/SchoolClassesV2'
import SchoolDetails from './tabs/SchoolDetails'
import SchoolLoading from './tabs/SchoolLoading'
import SchoolUsers from './tabs/SchoolUsers'
import SchoolYear from './tabs/SchoolYear'
import useEditSchool from './useEditSchool'

const EditSchool = () => {
  const {
    users,
    school,
    groups,
    classes,
    programs,
    countries,
    isLoading,
    showToast,
    classesV2,
    currentTab,
    schoolGuid,
    schoolYears,
    schoolContracts,
    toastMessage,
    isSuperAdmin,
    isSchoolAdmin,
    educationYears,
    educationLevels,
    totalUsersInSchool,
    totalGroupsInSchool,
    totalClassesInSchool,
    totalClassesInSchoolV2,
    totalSchoolContracts,
    onLogout,
    onEditClass,
    onChangeTab,
    onSaveSchool,
    setShowToast,
    onDeleteUser,
    onDeleteClass,
    onClickOnUser,
    onUpdateGroup,
    onUpdateSchool,
    onCreateNewUser,
    getSchoolGroups,
    getUsersInSchool,
    onCreateNewGroup,
    onCreateNewClass,
    onCreateNewContract,
    onDeleteUserFromGroup,
    // onDeleteClassV2,
    // onCreateNewClassV2,
    getSchoolClassesV2,
    onDeleteUserFromClass
  } = useEditSchool()

  const [isCreateNewUserOpen, setIsCreateNewUserOpen] = useState(false)
  const [newUserSavedState, setNewUserSavedState] = useState(null)
  const [isImportUsersOpen, setIsImportUsersOpen] = useState(false)

  return (
    <FullPageLayout
      fullWidth={currentTab === 'classesv2'}
      {...(isSuperAdmin
        ? {
            left: <BackButton />,
            title: (
              <Typography variant='h4' fontWeight={600}>
                Edit School
              </Typography>
            )
          }
        : {})}
      {...(isSchoolAdmin
        ? {
            left: <Typography variant='h2'>Manage your school</Typography>,
            right: <EDButton onClick={onLogout}>Logout</EDButton>
          }
        : {})}
      footerRight={
        !isLoading &&
        currentTab === 'details' && (
          <Button
            onClick={onSaveSchool}
            variant='contained'
            disabled={false}
            disableElevation
          >
            Save
          </Button>
        )
      }
    >
      <Container maxWidth={currentTab === 'classesv2' ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TDTabs
              tabs={[
                { name: 'Details', key: 'details' },
                { name: 'School Year', key: 'schoolYear' },
                { name: 'Classes', key: 'classesv2' },
                { name: 'Users', key: 'users' }
              ]}
              currentTab={currentTab}
              onClick={onChangeTab}
            />
          </Grid>
          {isLoading && <SchoolLoading />}
          {!isLoading && !currentTab && <NoSchoolFound />}
          {!isLoading && currentTab === 'details' && (
            <SchoolDetails
              {...school}
              countries={countries}
              onUpdateSchool={onUpdateSchool}
            />
          )}
          {!isLoading && currentTab === 'schoolYear' && (
            <SchoolYear
              schoolContracts={schoolContracts}
              onCreateNewContract={onCreateNewContract}
              totalSchoolContracts={totalSchoolContracts}
            />
          )}
          {/* {!isLoading && currentTab === 'groups' && (
            <SchoolGroups
              users={users}
              groups={groups}
              schoolGuid={schoolGuid}
              schoolYears={schoolYears}
              onUpdateGroup={onUpdateGroup}
              educationYears={educationYears}
              educationLevels={educationLevels}
              getSchoolGroups={getSchoolGroups}
              onCreateNewGroup={onCreateNewGroup}
              totalGroupsInSchool={totalGroupsInSchool}
              onDeleteUserFromGroup={onDeleteUserFromGroup}
            />
          )} */}
          {/* {!isLoading && currentTab === 'classes' && (
            <SchoolClasses
              groups={groups}
              classes={classes}
              programs={programs}
              onEditClass={onEditClass}
              onDeleteClass={onDeleteClass}
              onCreateNewClass={onCreateNewClass}
              totalClassesInSchool={totalClassesInSchool}
            />
          )} */}
          {!isLoading && currentTab === 'classesv2' && (
            <SchoolClassesV2
              classes={classesV2}
              programs={programs}
              schoolYears={schoolYears}
              educationLevels={educationLevels}
              getSchoolClassesV2={getSchoolClassesV2}
              onDeleteUserFromClass={onDeleteUserFromClass}
            />
          )}
          {!isLoading && currentTab === 'users' && (
            <SchoolUsers
              users={users}
              groups={groups}
              classes={classesV2}
              schoolGuid={schoolGuid}
              onDeleteUser={onDeleteUser}
              onClickOnUser={onClickOnUser}
              getSchoolGroups={getSchoolGroups}
              getSchoolClasses={getSchoolClassesV2}
              onCreateNewUser={onCreateNewUser}
              getUsersInSchool={getUsersInSchool}
              newUserSavedState={newUserSavedState}
              totalUsersInSchool={totalUsersInSchool}
              newUserDialogOpen={isCreateNewUserOpen}
              setNewUserSavedState={setNewUserSavedState}
              setNewUserDialogOpen={setIsCreateNewUserOpen}
              newImportUsersDialogOpen={isImportUsersOpen}
              setImportUsersDialogOpen={setIsImportUsersOpen}
            />
          )}
        </Grid>
      </Container>

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={toastMessage.type}
          text={toastMessage.text}
        />
      )}
    </FullPageLayout>
  )
}

export default EditSchool
