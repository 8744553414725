import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDDepartmentMainFilter = ({
  defaultValue,
  showLabel = false,
  multiple,
  required = false,
  disabled = false,
  language = 'ES',
  onChange,
  isLoading: isInitialLoad,
  isFilter = false
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const translateDepartmentName = (department) => {
    switch (department) {
      case 'Geometry':
        return t('demo_geometry');
      case 'Magnitudes and measure':
        return t('demo_magnitudes');
      case 'Numbers and operations':
        return t('demo_numbers');
      case 'Statistic and probability':
        return t('demo_statistics');
      default:
        return department;
    }
  };

  const getData = async (searchValue) => {
    setIsLoading(true);

    let departmentsResponse = await fetchWithToken({
      method: 'GET',
      path: `/departments?pageSize=25&offset=0${
        language ? `&langCode[]=${language.toUpperCase()}` : ''
      }${searchValue ? `&name=${searchValue}` : ''}`
    });

    setDepartments(
      departmentsResponse.data.departments?.map((department) => ({
        value: department.guid,
        id: department.guid,
        label:
          department.departmentLanguages.find(
            (departmentLanguage) =>
              departmentLanguage.lang.code === language.toUpperCase()
          )?.name || translateDepartmentName(department.name)
      }))
    );

    setTotal(departmentsResponse.data.count);
    setOffset(departmentsResponse.data.offset);
    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce((value) => {
      getData(value);
    }, 500),
    []
  );

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  useEffect(() => {
    setDepartments([]);
    setCurrentPage(0);
    debouncedFetchOptions(inputValue);
  }, [inputValue]);

  useEffect(() => {
    getData();
  }, [language, currentPage]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          limitTags={2}
          label={t('demo_departments')}
          showLabel={showLabel}
          multiple={multiple}
          required={required}
          loading={isLoading}
          disabled={disabled}
          loadingText={t('loading_text')}
          noOptionsText={t('no_options_text')}
          value={
            isFilter
              ? defaultValue
              : defaultValue?.length
              ? defaultValue[0]
              : []
          }
          options={departments}
          ListboxProps={{
            onScroll: (event) => {
              if (
                event.target.scrollTop + event.target.clientHeight ===
                event.target.scrollHeight
              ) {
                if (currentPage * pageSize <= total) {
                  setCurrentPage(currentPage + 1);
                }
              }
            }
          }}
          getOptionLabel={(option) => option.label || ''}
          onInputChange={handleInputChange}
          onChange={(event, value) => {
            if (multiple) {
              onChange(value);
            } else {
              onChange(value ? [value] : []);
            }
          }}
          renderInput={(params) => (
            <EDInput {...params} placeholder={t('select_placeholder')} />
          )}
        />
      )}
    </>
  );
};

export default EDDepartmentMainFilter;
