import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDDialog from '../../../../atoms/EDDialog/EDDialog';
import BBJSONEditor from '../../../../components/JSONEditor/BBJSONEditor';
import { LANGUAGES } from '../../../../i18n/i18n';
import './TranslateDialog.scss';

const TranslateDialog = ({
  sourceJSON,
  sourceLanguage,
  activityName,
  isOpen,
  onClose,
  onApply
}) => {
  const { t } = useTranslation();

  const TRANSLATION_API_KEY =
    '8f3a9c2e7b6d1f5a4e0d8c7b2a6f9e3d5b1c4a8e7f2d0b9c6a3f5d8e1b4a7c2';
  const TRANSLATION_BASE_URL = 'https://cherimoya.oneclicklabs.es/translation?';

  const [finalTranslatedJson, setFinalTranslatedJson] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    translateJson();
  }, []);

  const translateJson = async () => {
    const targetLanguages = LANGUAGES.filter(
      (language) => language.value !== sourceLanguage
    ).map((language) => language.value);

    const translatedJsons = [];
    const translationPromises = targetLanguages.map(async (language) => {
      const translatedJson = await getTranslationTo(language);
      if (translatedJson?.translatedContent) {
        translatedJsons.push({
          language: language,
          translation: translatedJson.translatedContent
        });
      }
    });

    try {
      await Promise.all(translationPromises);

      // Unir las traducciones en un sólo JSON con sourceJSON como base
      const mergedJson = { ...JSON.parse(sourceJSON) };
      mergedJson.locales = mergedJson.locales || {};
      translatedJsons.forEach((translatedJson) => {
        mergedJson.locales[translatedJson.language] =
          translatedJson.translation.locales[translatedJson.language];
      });

      setFinalTranslatedJson(mergedJson);
    } catch (error) {
      setError(true);
    }
  };

  const getTranslationTo = async (targetLanguage) => {
    return await fetch(
      `${TRANSLATION_BASE_URL}sourceLanguage=${sourceLanguage}&targetLanguage=${targetLanguage}`,
      {
        method: 'POST',
        headers: {
          'x-api-key': TRANSLATION_API_KEY,
          'Content-Type': 'application/json'
        },
        body: sourceJSON
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data);
        if (!data?.error) {
          return data;
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error translating JSON:', error);
        setError(true);
        return null;
      });
  };

  const applyTranslatedJson = () => {
    onApply(JSON.stringify(finalTranslatedJson));
  };

  return (
    <EDDialog open={isOpen} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogContent className='translate-dialog_content'>
        {activityName && (
          <Stack direction='row' spacing={2} justifyContent='center'>
            <Typography
              variant='h3'
              gutterBottom
              className='translate-dialog_title'
            >
              {t('translate_dialog_title', { activityName: activityName })}
            </Typography>
          </Stack>
        )}

        {finalTranslatedJson && !error && (
          <div className='translate-dialog_json'>
            <BBJSONEditor
              defaultJSON={finalTranslatedJson}
              onSetEditorCodeString={() => {}}
              onHasError={() => {}}
              readOnly={true}
            />
          </div>
        )}
        {!finalTranslatedJson && !error && (
          <div className='translate-dialog_loading'>
            <CircularProgress />
          </div>
        )}
        {error && (
          <div className='translate-dialog_error'>{t('translation_error')}</div>
        )}
      </DialogContent>
      <DialogActions>
        <EDButton onClick={onClose} color='primary' variant='outlined'>
          {t('cancel')}
        </EDButton>
        <EDButton
          onClick={applyTranslatedJson}
          color='primary'
          variant='contained'
          disabled={!finalTranslatedJson || error}
        >
          {t('Apply')}
        </EDButton>
      </DialogActions>
    </EDDialog>
  );
};

export default TranslateDialog;
