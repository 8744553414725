import { Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import TableDashboard from '../../../../../../view/tableDashboard/TableDashboard';

const ListUsersInClassDialog = ({
  usersInClass,
  isOpen,
  onClose,
  selectedClass,
  isLoading,
  onDeleteUserFromClass
}) => {
  const columnHelper = createColumnHelper();
  const [deletedUsers, setDeletedUsers] = React.useState([]);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('lastname', {
      header: 'Last Name',
      id: 'lastname',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      id: 'email',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('role_name', {
      header: 'Role',
      id: 'role_name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  return (
    <Dialog fullWidth maxWidth='lg' open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h3'>
                Users in class {selectedClass.name}
              </Typography>
              {/* Add close icon */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TableDashboard
              disableFiltersAndSearch={true}
              tableData={usersInClass.filter(
                (user) => !deletedUsers.includes(user.guid)
              )}
              tableColumns={columns}
              totalEntries={
                usersInClass.filter((user) => !deletedUsers.includes(user.guid))
                  .length
              }
              singleEntryLabel={'user'}
              pluralEntryLabel={'users'}
              showPagination={false}
              showTopPagination={false}
              isTableDataLoading={isLoading}
              searchInputPlaceholder={'Search users'}
              options={[
                {
                  label: 'Remove User',
                  onClick: async (user) => {
                    await onDeleteUserFromClass(user);
                    setDeletedUsers([...deletedUsers, user.guid]);
                  }
                }
              ]}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ListUsersInClassDialog;
