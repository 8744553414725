import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDButton from '../../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';
import LicenseFilter from '../../../../../components/tenantFilters/LicenseFilter';
import { checkIfValidEmail } from '../../../../../utils/helpers/checkIfValidEmail';
import { isLayersTenant } from '../../../../../utils/helpers/whichTenant';

const CreateNewUserDialog = ({
  isOpen,
  onClose,
  onSubmit,
  roles,
  newUserSavedState,
  setNewUserSavedState,
  classes
}) => {
  const { t } = useTranslation();

  const [newUser, setNewUser] = useState(
    newUserSavedState
      ? newUserSavedState.newUser
      : {
          name: '',
          lastname: '',
          email: '',
          role: {
            label: '',
            value: ''
          },
          password: '',
          is_demo: 0,
          json_settings: {
            blueberry: {
              devmode: 0
            }
          }
        }
  );

  const LAYERS_DEFAULT_LICENSE = {
    label: 'PT',
    value: 'PT'
  };

  const [canCreate, setCanCreate] = useState(
    newUserSavedState ? newUserSavedState.canCreate : false
  );

  const [confirmPassword, setConfirmPassword] = useState(
    newUserSavedState ? newUserSavedState.confirmPassword : ''
  );

  const [isTeacher, setIsTeacher] = useState(newUser.role.value === 'R02');

  useEffect(() => {
    setNewUserSavedState &&
      setNewUserSavedState({ newUser, confirmPassword, canCreate });
    setCanCreate(isUserMandatoryDataFilledCorrectly(getParsedUserData()));
  }, [newUser, confirmPassword, isTeacher]);

  useEffect(() => {
    setIsTeacher(newUser.role.value === 'R02');

    if (newUser.role.value !== 'R02') {
      let tempUser = { ...newUser };
      delete tempUser.license;
      setNewUser(tempUser);
    }
  }, [newUser.role]);

  const getParsedUserData = () => {
    let parsedUserData = {
      ...newUser,
      role: newUser.role.value,
      json_settings: JSON.stringify(newUser.json_settings)
    };

    if (isTeacher) {
      parsedUserData.license = isLayersTenant()
        ? LAYERS_DEFAULT_LICENSE.value
        : newUser.license;
    } else {
      delete parsedUserData.license;
    }

    return parsedUserData;
  };

  const isUserMandatoryDataFilledCorrectly = (userData) => {
    return (
      userData.name &&
      userData.lastname &&
      userData.email &&
      userData.role &&
      userData.password &&
      userData.json_settings &&
      userData.password === confirmPassword &&
      (isTeacher ? userData.license : true) &&
      (isTeacher ? checkIfValidEmail(userData.email) : true)
    );
  };

  useEffect(() => {
    setIsTeacher(newUser.role.value === 'R02');
  }, [newUser]);

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={600}>
              {t('create_user')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label={t('name')}
              value={newUser.name}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, name: value })
              }
              disableAutocomplete
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label={t('lastname')}
              value={newUser.lastname}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, lastname: value })
              }
              disableAutocomplete
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              label={t('role')}
              value={newUser.role}
              inputProps={{ autoComplete: 'new-password' }}
              options={roles.map((role) => {
                return {
                  label: role.role,
                  value: role.guid
                };
              })}
              onChange={(e, value) => {
                setNewUser({ ...newUser, role: value });
              }}
              showLabel
              disableClearable
              disableAutocomplete
            />
          </Grid>
          {/* the license option is only available for teacher */}
          {isTeacher && (
            <Grid item xs={12} md={6}>
              <LicenseFilter
                disabled={isLayersTenant()}
                {...(isLayersTenant() ? { value: LAYERS_DEFAULT_LICENSE } : {})}
                onChange={({ label }) => {
                  setNewUser({ ...newUser, license: label });
                }}
                defaultValue={
                  isLayersTenant() ? LAYERS_DEFAULT_LICENSE : newUser.license
                }
              />
            </Grid>
          )}
          <Grid item xs={12} md={isTeacher ? 12 : 6}>
            <EDInputWithLabel
              label={isTeacher ? t('email') : t('username_or_email')}
              value={newUser.email}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, email: value })
              }
              disableAutocomplete
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              type='password'
              label={t('password')}
              inputProps={{ autoComplete: 'new-password' }}
              disableAutocomplete
              value={newUser.password}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, password: value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              type='password'
              label={t('confirm_password')}
              inputProps={{ autoComplete: 'new-password' }}
              disableAutocomplete
              value={confirmPassword}
              onChange={({ target: { value } }) => setConfirmPassword(value)}
            />
          </Grid>
          {classes && classes.length > 0 && (
            <Grid item xs={12}>
              <EDAutocompleteWithLabel
                showLabel
                label='Class'
                disableClearable
                options={classes.map((activeClass) => {
                  return {
                    label: activeClass.name,
                    value: activeClass.guid
                  };
                })}
                onChange={(e, value) => {
                  setNewUser({ ...newUser, class: value });
                }}
                disableAutocomplete
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormGroup aria-label='position' row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        newUser.json_settings.blueberry.letter_case ===
                        'upperCase'
                      }
                      onChange={(e, value) => {
                        setNewUser({
                          ...newUser,
                          json_settings: {
                            blueberry: {
                              ...newUser.json_settings.blueberry,
                              letter_case: value ? 'upperCase' : 'regularCase'
                            }
                          }
                        });
                      }}
                    />
                  }
                  label='Uppercase Required (Texts in uppercase for this user)'
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset'>
              <FormGroup aria-label='position' row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newUser.json_settings.blueberry.devmode === 1}
                      onChange={(e, value) => {
                        setNewUser({
                          ...newUser,
                          json_settings: {
                            blueberry: {
                              ...newUser.json_settings.blueberry,
                              devmode: value ? 1 : 0
                            }
                          }
                        });
                      }}
                    />
                  }
                  label={t('users_dev_mode_label')}
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset'>
              <FormGroup aria-label='position' column>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newUser.is_demo}
                      onChange={(e, value) => {
                        setNewUser({
                          ...newUser,
                          is_demo: e.target.checked ? 1 : 0
                        });
                      }}
                    />
                  }
                  label={t('users_demo_user_label')}
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onClose}>
          {t('cancel')}
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canCreate}
          onClick={() => onSubmit(getParsedUserData())}
        >
          {t('Create')}
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewUserDialog;
