import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../_practice-components';
import usePreviewActivity from './usePreviewActivity';

const PreviewActivity = () => {
  const { t } = useTranslation();
  const { data } = usePreviewActivity();

  return (
    data?.json && (
      <ActivityBlueberryComponent
        activityType={ACTIVITY_TYPES.PREVIEW}
        activity={data.json}
        uiLanguage={data.previewLanguage}
        mathRenderEngine='katex'
        exerciseLanguage={data.previewLanguage}
        pathAssetsSchool={`/assets/graphics/gamification`}
      />
    )
  );
};

export default PreviewActivity;
