import { Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import './TDDashboardTabs.scss';

const TDDashboardTabs = ({ tabs, currentTab, onClick }) => {
  const {
    palette: {
      primary: { main: primaryColor },
      grey: { 500: greyColor }
    }
  } = useTheme();

  return (
    <div className='td-dashboard-tabs__container'>
      {tabs.map((tab) => (
        <div
          key={tab.key}
          className={`td-dashboard-tabs__link ${
            currentTab === tab.key ? 'td-dashboard-tabs__link--active' : ''
          }`}
          {...(onClick && { onClick: () => onClick(tab.key) })}
        >
          <Typography
            {...(tab.to && { component: Link, to: tab.to })}
            className='td-dashboard-tabs__link__text'
            color={currentTab === tab.key ? 'primary' : greyColor}
            fontWeight={500}
            sx={{
              '&:hover': {
                color: primaryColor
              }
            }}
          >
            {tab.name}
          </Typography>
          <div
            className='td-dashboard-tabs__link__underline'
            style={{
              backgroundColor:
                currentTab === tab.key ? primaryColor : 'transparent'
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default TDDashboardTabs;
