import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useEffect, useState } from 'react'
import { ReactComponent as CompletedIcon } from '../../assets/icons/status_completed.svg'
import { ReactComponent as JsonNeedsCorrectionIcon } from '../../assets/icons/status_json_needs_correction.svg'
import { ReactComponent as TranslationIcon } from '../../assets/icons/status_json_pending.svg'
import { ReactComponent as FixTranslation } from '../../assets/icons/status_needs_correction.svg'
import { ReactComponent as JsonRevisionIcon } from '../../assets/icons/status_needs_revision.svg'
import { ReactComponent as PendingTranslationOnlyAppIcon } from '../../assets/icons/status_pending_translation_only_app.svg'
import { ReactComponent as ToDoIcon } from '../../assets/icons/status_todo.svg'
import './EDSeedStatusSelect.scss'

import { useTranslation } from 'react-i18next'
import { CustomArrow } from '../../atoms/CustomArrow/CustomArrow'
import { fetchWithToken } from '../../utils/helpers/fetcher'

const EDSeedStatusSelect = ({ activity, setShowToast, setToastMessage }) => {
  const { t } = useTranslation()

  const statusInfo = [
    { key: 'to_do', icon: <ToDoIcon /> },
    { key: 'check_JSON', icon: <JsonRevisionIcon /> },
    { key: 'correct_JSON', icon: <JsonNeedsCorrectionIcon /> },
    { key: 'pending_auto_translation', icon: <TranslationIcon /> },
    { key: 'check_translation', icon: <JsonRevisionIcon /> },
    { key: 'fix_translation', icon: <FixTranslation /> },
    {
      key: 'pending_translation_only_app',
      icon: <PendingTranslationOnlyAppIcon />
    },
    { key: 'done', icon: <CompletedIcon /> }
  ]

  const [status, setStatus] = useState(activity?.status || '')

  const handleChange = (event) => {
    setStatus(event.target.value)
    onSaveStatus(event.target.value)
  }

  const onSaveStatus = async (newStatus) => {
    const questionSeedGuid = activity.guid
    const response = await fetchWithToken({
      path: `/seeds/${questionSeedGuid}`,
      method: 'PATCH',
      data: { status: newStatus }
    })
    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_activity_saved_success')
      })
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_saved_error')
      })
    }
    setShowToast(true)
  }

  useEffect(() => {
    setStatus(activity?.status || '')
  }, [activity])

  return (
    <div className='seed-status-select'>
      <Select
        id='seed-status-select__select'
        className='seed-status-select__select'
        // defaultValue={activity?.status}
        value={status}
        onChange={handleChange}
        variant='outlined'
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          getContentAnchorEl: null,
          sx: { marginTop: '12px' }
        }}
        IconComponent={CustomArrow}
      >
        {statusInfo.map((statusItem) => (
          <MenuItem
            key={statusItem.key}
            value={statusItem.key}
            className='seed-status-select__item'
          >
            <span className='seed-status-select__item-icon'>
              {statusItem.icon}
            </span>
            <span className='seed-status-select__item-name'>
              {t(statusItem.key)}
            </span>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default EDSeedStatusSelect
