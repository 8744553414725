import { CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import Toast from '../../../../../atoms/Toast/Toast';
import TableDashboard from '../../../../../view/tableDashboard/TableDashboard';
import ConfirmDeleteDialogV2 from './dialogs/ConfirmDeleteDialogV2';
import CreateEditClassDialogV2 from './dialogs/CreateEditClassDialogV2';
import ListUsersInClassDialog from './dialogs/ListUsersInClassDialog';
import useSchoolClassesV2 from './useSchoolClassesV2';

const SchoolClassesV2 = ({
  classes,
  programs,
  schoolYears,
  educationLevels,
  getSchoolClassesV2,
  onDeleteUserFromClass
}) => {
  const {
    columns,
    showToast,
    messageToast,
    parsedClasses,
    filteredClasses,
    tableRowOptions,
    isEditClassOpen,
    selectedClassToEdit,
    isCreateNewClassOpen,
    selectedClassToDelete,
    isCreatingOrEditingClass,
    usersInClass,
    isListOfUsersInClassOpen,
    isLoadingUsersInClass,
    selectedClassToViewUsers,
    setShowToast,
    onSearch,
    setIsCreateNewClassOpen,
    onCloseCreateEditClassDialog,
    onCloseDeleteClassDialog,
    isConfirmDeleteClassOpen,
    setIsConfirmDeleteClassOpen,
    onDeleteClassV2,
    onCreateNewClassV2,
    onEditClassV2,
    getUsersInClassV2,
    onCloseListOfUsersInClass
  } = useSchoolClassesV2({
    classes,
    getSchoolClassesV2
  });

  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h4' fontWeight={600}>
                Classes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableDashboard
                filters={[]}
                onSearch={onSearch}
                onFilter={() => {}}
                onClickOnCreate={() => {
                  setIsCreateNewClassOpen(true);
                }}
                tableData={parsedClasses}
                onRowClick={getUsersInClassV2}
                tableColumns={columns}
                totalEntries={filteredClasses.length}
                singleEntryLabel={'class'}
                pluralEntryLabel={'classes'}
                isTableDataLoading={false}
                searchInputPlaceholder={'Search class'}
                options={tableRowOptions}
                showPagination={false}
                showTopPagination={false}
                disableFilters
                createButtonLabel='Create class'
              />
            </Grid>
          </Grid>
        </CardContent>
      </EDCard>

      <CreateEditClassDialogV2
        isOpen={selectedClassToEdit ? isEditClassOpen : isCreateNewClassOpen}
        isEditMode={!!selectedClassToEdit}
        classes={classes}
        programs={programs}
        schoolYears={schoolYears}
        educationLevels={educationLevels}
        isLoading={isCreatingOrEditingClass}
        onSubmit={selectedClassToEdit ? onEditClassV2 : onCreateNewClassV2}
        onClose={onCloseCreateEditClassDialog}
        selectedClassToEdit={selectedClassToEdit}
      />

      <ConfirmDeleteDialogV2
        isOpen={isConfirmDeleteClassOpen}
        onClose={onCloseDeleteClassDialog}
        onConfirm={() => {
          onDeleteClassV2(selectedClassToDelete?.guid);
          onCloseDeleteClassDialog();
        }}
      />

      {isListOfUsersInClassOpen && (
        <ListUsersInClassDialog
          selectedClass={selectedClassToViewUsers}
          onClose={onCloseListOfUsersInClass}
          usersInClass={usersInClass}
          isOpen={isListOfUsersInClassOpen}
          isLoading={isLoadingUsersInClass}
          onDeleteUserFromClass={onDeleteUserFromClass}
        />
      )}

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={messageToast.type}
          text={messageToast.text}
        />
      )}
    </Grid>
  );
};

export default SchoolClassesV2;
