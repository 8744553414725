import { CardContent, Grid, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDCard from '../../../../atoms/EDCard/EDCard';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import SwitchWithLabel from '../../../../atoms/SwitchWithLabel/SwitchWithLabel';
import EDEducationLevelFilter from '../../../../components/filters/EDEducationLevelFilter';
import EDLanguageFilter from '../../../../components/filters/EDLanguageFilter';
import EDLearningObjectiveFilter from '../../../../components/filters/EDLearningObjectiveFilter';
import EDPhaseFilter from '../../../../components/filters/EDPhaseFilter';
import EDResponsibleFilter from '../../../../components/filters/EDResponsibleFilter';
import { generateEducationYear } from '../../../../utils/helpers/generateEducationYear';

const SingleActivityMetadata = ({
  isLoading,
  activity,
  onGetActivityMetadata,
  onHasChanges,
  onHasError
}) => {
  const { t } = useTranslation();

  const responsibleDefaultValue = {
    label: activity?.responsible?.name + ' ' + activity?.responsible?.lastname,
    id: activity?.responsible?.guid
  };

  const getActivityLearningOutcomes = () => {
    /*
    // Para multiple
    return activity?.loNodeSeeds.map((loNodeSeed) => ({
      label: loNodeSeed?.node?.name,
      id: loNodeSeed?.node?.guid
    }));
    */
    // Para single
    return activity?.loNodeSeeds?.length
      ? [
          {
            label: activity?.loNodeSeeds[0]?.node?.name,
            id: activity?.loNodeSeeds[0]?.node?.guid
          }
        ]
      : [];
  };

  const savedMetadata = {
    name: activity?.name,
    responsible: activity?.responsible?.guid,
    educationYearGuid: activity?.educationYear?.guid,
    langCode: activity?.lang?.code,
    learningOutcomes: getActivityLearningOutcomes(),
    phase: activity?.loNodeSeeds[0]?.phase,
    extraEffort: activity?.extraEffort,
    penAndPaperNeeded: activity?.penAndPaperNeeded
  };

  const [metadata, setMetadata] = useState({
    name: activity?.name,
    responsible: activity?.responsible?.guid,
    educationYearGuid: activity?.educationYear?.guid,
    langCode: activity?.lang?.code,
    learningOutcomes: getActivityLearningOutcomes(),
    phase: activity?.loNodeSeeds[0]?.phase,
    extraEffort: activity?.extraEffort,
    penAndPaperNeeded: activity?.penAndPaperNeeded
  });

  useEffect(() => {
    setMetadata({
      name: activity?.name,
      responsible: activity?.responsible?.guid,
      educationYearGuid: activity?.educationYear?.guid,
      langCode: activity?.lang?.code,
      learningOutcomes: getActivityLearningOutcomes(),
      phase: activity?.loNodeSeeds[0]?.phase,
      extraEffort: activity?.extraEffort,
      penAndPaperNeeded: activity?.penAndPaperNeeded
    });
  }, [activity]);

  useEffect(() => {
    onGetActivityMetadata(metadata);

    // CHECK FOR CHANGES
    if (isEqual(metadata, savedMetadata)) onHasChanges(false);
    else onHasChanges(true);

    if (
      metadata.name === '' ||
      !hasMandatoryLearningOutcome() ||
      !hasMandatoryPhase()
    )
      onHasError(true);
    else onHasError(false);
  }, [metadata]);

  const hasMandatoryPhase = () => {
    return !!metadata?.phase;
  };

  const hasMandatoryLearningOutcome = () => {
    const learningOutcomesAvailables = metadata.learningOutcomes.filter(
      (lo) => lo.action !== 'delete'
    );

    return learningOutcomesAvailables?.length ? true : false;
  };

  const updateLearningOutcomeMetadata = (userInputLOs) => {
    // Marcar como borrado los que dejan de estar y dejar tal cual los que estaban
    const learningOutcomes = savedMetadata.learningOutcomes.map((metaLO) => {
      if (!userInputLOs.some((userLO) => userLO.id === metaLO.id)) {
        return { ...metaLO, action: 'delete' }; // Si el lo deja de estar se marca como 'deleted'
      } else {
        userInputLOs = userInputLOs.filter((userLO) => userLO.id !== metaLO.id);
        return metaLO;
      }
    });

    // Marcar como añadido los nuevos
    for (let index in userInputLOs) {
      learningOutcomes.push({ ...userInputLOs[index], action: 'add' });
    }

    setMetadata({
      ...metadata,
      learningOutcomes: learningOutcomes
    });
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <EDCard elevation={0}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant='h2'>{t('general_subtitle')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <EDInputWithLabel
                    isLoading={isLoading}
                    value={metadata?.name}
                    label={t('name_label')}
                    placeholder={t('name_placeholder')}
                    fullWidth
                    required
                    onChange={({ target: { value } }) => {
                      setMetadata({
                        ...metadata,
                        name: value
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <EDResponsibleFilter
                    showLabel={true}
                    isLoading={isLoading}
                    defaultValue={
                      activity?.responsible?.name
                        ? responsibleDefaultValue
                        : null
                    }
                    onChange={(inputValue) => {
                      setMetadata({
                        ...metadata,
                        responsible: inputValue
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <EDPhaseFilter
                    required
                    showLabel={true}
                    defaultValue={activity?.loNodeSeeds[0]?.phase}
                    isLoading={isLoading}
                    onChange={(inputValue) => {
                      setMetadata({
                        ...metadata,
                        phase: inputValue
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <EDEducationLevelFilter
                    isLoading={isLoading}
                    showLabel={true}
                    defaultValue={{
                      id: activity?.educationYear?.guid,
                      year: activity?.educationYear?.year,
                      label: activity?.educationYear
                        ? generateEducationYear(activity?.educationYear, t)
                        : t('select_placeholder')
                    }}
                    onChange={(inputValue) => {
                      setMetadata({
                        ...metadata,
                        educationYearGuid: inputValue
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <EDLanguageFilter
                    showLabel={true}
                    isLoading={isLoading}
                    defaultValue={activity?.lang?.code}
                    onChange={(inputValue) => {
                      setMetadata({
                        ...metadata,
                        langCode: inputValue
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <EDLearningObjectiveFilter
                    required
                    isLoading={isLoading}
                    showLabel={true}
                    // multiple={true}
                    multiple={false}
                    defaultValue={getActivityLearningOutcomes()}
                    onChange={updateLearningOutcomeMetadata}
                  />
                </Grid>

                <Grid item xs={12}>
                  <EDInputWithLabel
                    showLabel={true}
                    label={t('id_label')}
                    disabled
                    placeholder='id_placeholder'
                    value={activity?.guid}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SwitchWithLabel
                    checked={metadata?.penAndPaperNeeded}
                    onChange={({ target: { checked } }) => {
                      setMetadata({
                        ...metadata,
                        penAndPaperNeeded: checked ? 1 : 0
                      });
                    }}
                    label={t('pen_paper')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SwitchWithLabel
                    checked={metadata?.extraEffort}
                    onChange={({ target: { checked } }) => {
                      setMetadata({
                        ...metadata,
                        extraEffort: checked ? 1 : 0
                      });
                    }}
                    label={t('extra_reward')}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </EDCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleActivityMetadata;
