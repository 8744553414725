import { useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AuthLayout from '../layouts/auth/AuthLayout';
import GeneralError from '../pages/general-error/GeneralError';
import LoginPage from '../pages/login/LoginPage';
import { getPlatform } from '../utils/helpers/platform';
import useAuth from '../utils/hooks/useAuth';
import { editorDashboardRoutes } from './EditorDashboardRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Root from './Root';
import { schoolAdminDashboardRoutes } from './SchoolAdminDashboardRoutes';
import { tenantDashboardRoutes } from './TenantDashboardRoutes';

const Router = () => {
  const { user } = useAuth();

  const [router, setRouter] = useState(null); // nuevo estado para el router

  useEffect(() => {
    let routesCurrent = [
      {
        path: '/',
        element: <LoginPage />
      }
    ];
    if (getPlatform() === 'editor') {
      routesCurrent = editorDashboardRoutes;
      document.title = 'Editor Backoffice';
    } else if (getPlatform() === 'tenant') {
      // if (!user) return;

      if (user?.role_guid === 'R03') {
        routesCurrent = schoolAdminDashboardRoutes;
        document.title = 'School Admin Backoffice';
      } else {
        routesCurrent = tenantDashboardRoutes;
        document.title = 'Tenant Backoffice';
      }
    }

    // Nota: asegúrate que 'routes' está cargada en este punto
    const newRouter = createBrowserRouter([
      {
        path: '/',
        element: <Root />,
        errorElement: <GeneralError code={500} />,
        children: [
          {
            element: <PublicRoute />,
            children: [
              {
                element: <AuthLayout />,
                children: [
                  {
                    path: '/login',
                    element: <LoginPage />
                  },
                  {
                    path: '/login-qa',
                    element: <LoginPage forceDisableAnalytics={true} />
                  }
                ]
              }
            ]
          },
          {
            element: <PrivateRoute />,
            children: routesCurrent
          }
        ]
      }
    ]);

    setRouter(newRouter); // actualizas el estado del router
  }, [user?.guid]);

  if (!router) return null; // si el router no está listo, no renderiza nada

  return <RouterProvider router={router} />;
};

export default Router;
