import { HelpOutline } from '@mui/icons-material';
import { Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDButton from '../../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';
import BBTooltip from '../../../../../atoms/tooltip/BBTooltip';
import EDStandardsFilter from '../../../../../components/filters/EDStandardFilter';
import PillTabs from '../../../../../components/pillTabs/PillTabs';
import Table from '../../../../../components/table/Table';
import CellTag from '../../../../../components/table/cell/cellTypes/CellTag';
import CellWithCheckBox from '../../../../../components/table/cell/cellTypes/CellWithCheckbox';
import CellWithOneLineOfTextLarge from '../../../../../components/table/cell/cellTypes/CellWithOneLineOfTextLarge';
import { fetchWithToken } from '../../../../../utils/helpers/fetcher';
import './AddLOsDialog.scss';

const PAGE_SIZE = 8;

const AddLOsDialog = ({
  isOpen,
  onClose,
  existingLOs,
  educationYears,
  onAddLOs,
  onRemoveLOs,
  programLOsInUseGuids,
  programStandard,
  programPreviewLanguage
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('search');
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageOfSelectedTab, setCurrentPageOfSelectedTab] = useState(1);
  const [offsetOfSelectedTab, setOffsetOfSelectedTab] = useState(0);
  const [filters, setFilters] = useState({
    educationYear: null,
    standardGuid: null
  });
  const [learningObjectives, setLearningObjectives] = useState([]);

  const [selectedLearningObjectives, setSelectedLearningObjectives] =
    useState(existingLOs);
  const [
    fixedCopyOfSelectedLearningObjectives,
    setFixedCopyOfSelectedLearningObjectives
  ] = useState(existingLOs);

  // Nuevo estado para controlar el checkbox del Encabezado
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);

  // Función para manejar el cambio de state del checkbox del encabezado
  const handleHeaderCheckboxChange = () => {
    setIsHeaderChecked(!isHeaderChecked);

    // No se selecciona un LO si está en uso en otra lección
    const newLOs = learningObjectives.map((lo) => ({
      ...lo,
      isSelected: !programLOsInUseGuids.includes(lo.guid)
        ? !isHeaderChecked
        : lo.isSelected
    }));

    setLearningObjectives(newLOs);

    if (isHeaderChecked) {
      // si el encabezado estaba seleccionado, limpiar todos los seleccionados en la página actual
      setSelectedLearningObjectives(
        selectedLearningObjectives.filter((val) => {
          // Solo conservar aquellos que no estén en la página actual
          return (
            !newLOs.find((item) => item.guid === val.guid) && val.isSelected
          );
        })
      );
    } else {
      // si el encabezado no estaba marcado, agregar todos de la página actual a SelectedLearningObjectives
      const newObjLOs = newLOs.filter((val) => {
        // Solo conservar aquellos que no estén en la página actual
        return (
          !selectedLearningObjectives.find((item) => item.guid === val.guid) &&
          val.isSelected
        );
      });
      setSelectedLearningObjectives([
        ...selectedLearningObjectives,
        ...newObjLOs
      ]);
    }
  };

  // Nueva función para marcar el checkbox del encabezado si todos los items están seleccionados
  const checkIfAllSelected = (data) => {
    if (Array.isArray(data) && data.length === 0) {
      return;
    }
    const allSelected = data.every((item) => item.isSelected);
    setIsHeaderChecked(allSelected);
  };

  // Llamar a esta función cada vez que los datos cambien o se cambie de página
  useEffect(() => {
    if (selectedTab === 'search') {
      checkIfAllSelected(
        learningObjectives.filter(
          (lo) => !programLOsInUseGuids.includes(lo.guid)
        )
      );
    } else {
      checkIfAllSelected(selectedLearningObjectives);
    }
  }, [
    learningObjectives,
    selectedTab,
    currentPage,
    selectedLearningObjectives
  ]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('isSelected', {
      header: () =>
        selectedTab === 'search' ? (
          <CellWithCheckBox
            checked={isHeaderChecked}
            onChange={handleHeaderCheckboxChange}
          />
        ) : (
          ''
        ),
      id: 'isSelected',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithCheckBox
          checked={info.row.original.isSelected}
          disabled={programLOsInUseGuids.includes(info.row.original.guid)}
        />
      )
    }),
    columnHelper.accessor('guid', {
      header: t('name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => {
        const seedsNumber = info.row.original?.loNodeSeeds?.length || 0;
        const hasSeeds = seedsNumber > 0;

        return (
          <div className={'lo-name-cell' + (hasSeeds ? ' lo-has-seeds' : '')}>
            {!hasSeeds && (
              <BBTooltip
                text={t('program_editor_program_errors_seeds_tag')}
                key={info.row.original.name}
              >
                <HelpOutline className='lo-has-no-seeds-icon' />
              </BBTooltip>
            )}
            <CellTag
              tags={[
                {
                  label: info.row.original.name,
                  description: seedsNumber + ' seeds'
                }
              ]}
              customClass={hasSeeds ? '' : 'lo-has-no-seeds'}
            />
          </div>
        );
      }
    }),
    columnHelper.accessor('name', {
      header: t('program_manage_LO_description'),
      id: 'description',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithOneLineOfTextLarge text={info.row.original.descriptionLang} />
      )
    }),
    columnHelper.accessor('standard', {
      header: t('standard'),
      id: 'standard',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellTag
          tags={info.row.original.standards.map((standard) => ({
            label: standard.name
          }))}
        />
      )
    })
  ];

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);
  };

  const onChangePageOfSelectedTab = (e, page) => {
    setCurrentPageOfSelectedTab(page);
    setOffsetOfSelectedTab((page - 1) * PAGE_SIZE);
  };

  let searchDebounceTimer = null;

  const onSearch = (value) => {
    clearTimeout(searchDebounceTimer);
    if (selectedTab === 'search') {
      searchDebounceTimer = setTimeout(() => {
        setSearchText(value);
      }, 300);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const queryParameters = [
      `offset=${offset}`,
      `pageSize=${PAGE_SIZE}`,
      filters.educationYear && `educationYearGuid[]=${filters.educationYear}`,
      filters.standardGuid && `standardGuid[]=${filters.standardGuid}`,
      searchText && `search=${searchText}`,
      'orderBy=createdAt',
      'orderType=DESC',
      'type[]=outcome',
      `includeLinkTo=0`,
      `includeDepartments=0`
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/nodes?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });
    setLearningObjectives(
      response.data.nodes.map((node) => {
        const descriptionLangObj = node.descriptionLanguages?.find(
          (desc) =>
            desc.lang.toUpperCase() === programPreviewLanguage.toUpperCase()
        ) || { description: node.description };

        return {
          ...node,
          isSelected: selectedLearningObjectives.some(
            (lo) => lo.guid === node.guid
          ),
          descriptionLang: descriptionLangObj
            ? descriptionLangObj.description
            : ''
        };
      })
    );
    setOffset(response.data.offset);
    setTotal(Math.ceil(response.data.count / PAGE_SIZE));
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [offset, filters, searchText]);

  useEffect(() => {
    setFixedCopyOfSelectedLearningObjectives(
      selectedLearningObjectives.map((item) => ({ ...item, isSelected: true }))
    );
  }, [selectedTab]);

  const onClickAddLO = () => {
    const LOsToDelete = existingLOs.filter(
      (item) => !selectedLearningObjectives.some((lo) => lo.guid === item.guid)
    );
    const LOsToAdd = selectedLearningObjectives.filter(
      (item) => !existingLOs.some((lo) => lo.guid === item.guid)
    );

    if (LOsToAdd.length > 0) onAddLOs(LOsToAdd.map((item) => item.guid));
    if (LOsToDelete.length > 0)
      onRemoveLOs(LOsToDelete.map((item) => item.guid));
    onClose();
  };

  const onSelectLO = (currentRow) => {
    // disable selection if the LO is already in use in other lesson
    if (programLOsInUseGuids.includes(currentRow.guid)) return;

    if (currentRow.isSelected) {
      setSelectedLearningObjectives((oldArray) =>
        oldArray.filter((item) => item.guid !== currentRow.guid)
      );
    } else {
      setSelectedLearningObjectives((oldArray) => [...oldArray, currentRow]);
    }
    // set learningObjectives as selected or not
    setLearningObjectives(
      learningObjectives.map((item) => {
        if (item.guid === currentRow.guid) {
          return {
            ...item,
            isSelected: !item.isSelected
          };
        }
        return item;
      })
    );

    setFixedCopyOfSelectedLearningObjectives(
      fixedCopyOfSelectedLearningObjectives.map((item) => {
        if (item.guid === currentRow.guid) {
          return {
            ...item,
            isSelected: !item.isSelected
          };
        }
        return item;
      })
    );
  };

  return (
    <Dialog
      maxWidth='lg'
      fullWidth
      open={isOpen}
      onClose={onClose}
      className='dialog-programs-lo'
    >
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction='row'
              spacing={2}
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='h6'>
                {t('program_manage_LO_title')}
              </Typography>
              <PillTabs
                tabs={[
                  { name: t('program_manage_LO_search'), key: 'search' },
                  {
                    name: `${t('program_manage_LO_selected')} (${
                      selectedLearningObjectives.length
                    })`,
                    key: 'selectedLOs'
                  }
                ]}
                currentTab={selectedTab}
                onChangeTab={(tab) => {
                  setSelectedTab(tab);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label={t('program_manage_LO_search')}
              fullWidth
              placeholder={t('program_manage_LO_placeholder')}
              onChange={(e) => onSearch(e.target.value)}
              disabled={selectedTab !== 'search'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EDStandardsFilter
              showLabel
              label={t('program_manage_LO_standard')}
              type={programStandard}
              onChange={(value) => {
                setFilters({
                  ...filters,
                  standardGuid: value
                });
              }}
              disabled={selectedTab !== 'search'}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <EDAutocompleteWithLabel
              showLabel={true}
              label={t('program_manage_LO_education_level')}
              options={educationYears.map((item) => ({
                label: item.name,
                value: item.guid
              }))}
              onChange={(e, selectedItem) => {
                setFilters({
                  ...filters,
                  educationYear: selectedItem?.value
                });
              }}
              disabled={selectedTab !== 'search'}
            />
          </Grid>
          <Grid item xs={12} className={'dialog-programs-lo-grid-content'}>
            {selectedTab === 'search' && (
              <Table
                columns={columns}
                data={learningObjectives}
                isSelectable={true}
                showPagination={true}
                totalPages={total}
                currentPage={currentPage}
                onChangePage={onChangePage}
                isLoading={isLoading}
                className={'dialog-programs-lo-table-content'}
                onRowClick={onSelectLO}
              />
            )}

            {selectedTab === 'selectedLOs' && (
              <Table
                columns={columns}
                data={[...fixedCopyOfSelectedLearningObjectives].slice(
                  (currentPageOfSelectedTab - 1) * PAGE_SIZE,
                  currentPageOfSelectedTab * PAGE_SIZE + offsetOfSelectedTab
                )}
                isSelectable={true}
                showPagination={true}
                totalPages={Math.ceil(
                  fixedCopyOfSelectedLearningObjectives.length / PAGE_SIZE
                )}
                currentPage={currentPageOfSelectedTab}
                onChangePage={onChangePageOfSelectedTab}
                onRowClick={onSelectLO}
                className={'dialog-programs-lo-table-content'}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' spacing={3} justifyContent='flex-end'>
              <EDButton variant='text' onClick={onClose}>
                {t('cancel')}
              </EDButton>
              <EDButton
                variant='contained'
                disableElevation
                onClick={onClickAddLO}
              >
                {t('program_manage_LO_update')}
              </EDButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddLOsDialog;
