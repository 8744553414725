import { Avatar, Typography } from '@mui/material';
import './TDTabs.scss';

const TDTabs = ({ tabs, currentTab, onClick }) => {
  return (
    <div className='td-link-tabs__container'>
      {tabs.map((tab, index) => (
        <div
          key={tab.key}
          className={`td-link-tabs__link ${
            currentTab === tab.key ? 'td-link-tabs__link--active' : ''
          }`}
          onClick={() => {
            onClick(tab);
          }}
        >
          <Avatar
            sx={{ width: 20, height: 20, fontSize: 14 }}
            className='td-link-tabs__link__avatar'
          >
            {index + 1}
          </Avatar>
          <Typography variant='h5' fontWeight={600}>
            {tab.name}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default TDTabs;
