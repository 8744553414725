import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';

const useSchools = () => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const pageSize = 10;

  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [schools, setSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateNewSchoolOpen, setIsCreateNewSchoolOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / pageSize);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('code', {
      header: 'Code',
      id: 'code',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('amount_groups', {
      header: 'Groups',
      id: 'amount_groups',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('amount_users', {
      header: 'Users',
      id: 'amount_users',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('createdAtDateString', {
      header: 'Created At',
      id: 'createdAtDateString',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  const onClickOnSchool = (e) => {
    navigate(`/schools/${e.guid}`);
  };

  const onCreateNewSchool = async (newSchoolDetails) => {
    const data = {
      name: newSchoolDetails.name,
      address: newSchoolDetails.address,
      school_path: newSchoolDetails.school_path
    };

    const createNewSchoolResponse = await fetchWithToken({
      path: `/schools`,
      method: 'POST',
      data
    });
    navigate(`/schools/${createNewSchoolResponse.data.guid}`);
  };

  const onOpenCreateSchoolDialog = () => {
    setIsCreateNewSchoolOpen(true);
  };

  const onCloseCreateSchoolDialog = () => {
    setIsCreateNewSchoolOpen(false);
  };

  const getData = async () => {
    setIsLoading(true);
    // add search to query params

    let response = await fetchWithToken({
      path: `/schools?pageSize=${pageSize}&offset=${offset}&orderBy=created_at+Desc${
        search ? `&name=${search}` : ''
      }`
    });

    setSchools(
      response.data.schools.map((school) => ({
        ...school,
        createdAtDateString: Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric'
        }).format(new Date(school?.created_at))
      }))
    );
    setTotal(response.data.length);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * pageSize);
  };

  let searchDebounceTimer = null;
  const onSearch = (value) => {
    clearTimeout(searchDebounceTimer);
    searchDebounceTimer = setTimeout(() => {
      setSearch(value);
    }, 300);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [search, currentPage]);

  return {
    total,
    offset,
    columns,
    schools,
    pageSize,
    isLoading,
    currentPage,
    totalPages,
    isCreateNewSchoolOpen,
    onSearch,
    onChangePage,
    onClickOnSchool,
    onCreateNewSchool,
    onOpenCreateSchoolDialog,
    onCloseCreateSchoolDialog
  };
};
export default useSchools;
