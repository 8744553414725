import { Skeleton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CellWithProgressBar.scss';

const CellWithProgressBar = ({ data }) => {
  const { t } = useTranslation();

  const getColorByPercentage = () => {
    const percentage = getPercentageData();
    if (percentage <= 20) {
      return 'red';
    } else if (percentage >= 21 && percentage <= 50) {
      return 'orange';
    } else {
      return 'green';
    }
  };

  const getPercentageData = () => {
    if (data.last_updated) {
      return data.last_updated.percentage_mastery;
    } else {
      return data.days.percentage_mastery;
    }
  };

  return (
    <>
      {data && (data.days || data.last_updated) ? (
        <Tooltip
          title={
            <div className='tooltip-container'>
              {data && data.last_updated && (
                <>
                  <div className='tooltip_title'>
                    {t('data_analysis_last_updated', {
                      last_updated: data.last_updated,
                      percentage_mastery_last_updated:
                        data.last_updated.percentage_mastery,
                      total_attempts_last_updated:
                        data.last_updated.total_attempts,
                      total_users_last_updated: data.last_updated.total_users
                    })}
                  </div>
                  <div className='tooltip_text'>
                    {t('analysis_last_update_text', {
                      number_days: data.last_updated.days
                    })}
                  </div>
                </>
              )}
              {data && data.days && (
                <>
                  <div className='tooltip_title'>
                    {t('data_analysis', {
                      percentage_mastery: data.days.percentage_mastery,
                      total_attempts: data.days.total_attempts,
                      total_users: data.days.total_users
                    })}
                  </div>
                  <div className='tooltip_text'>
                    {t('analysis_last_days_text')}
                  </div>
                </>
              )}
            </div>
          }
        >
          <div className='cell__container'>
            <div className='cell__progress-bar-container'>
              <div
                className={`cell__progress-bar-fill--${getColorByPercentage()}`}
                style={{ width: getPercentageData() + '%' }}
              />
            </div>
          </div>
        </Tooltip>
      ) : (
        <Skeleton variant='rounded' />
      )}
    </>
  );
};

export default CellWithProgressBar;
