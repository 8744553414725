import { fetchWithToken } from '../utils/helpers/fetcher';

export const patchUnit = async (unitGuid, payload) => {
  try {
    return await fetchWithToken({
      path: `/units/${unitGuid}`,
      method: 'PATCH',
      data: payload
    });
  } catch (error) {
    console.error('Error at unitService patchUnit', error);
    return { error: error };
  }
};
