import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';
import { generateEducationYear } from '../../utils/helpers/generateEducationYear';

const EDEducationLevelFilter = ({
  defaultValue,
  showLabel,
  label,
  required,
  disabled,
  isLoading: isInitialLoad,
  onChange
}) => {
  const { t } = useTranslation();

  // ONLY FOR PRIMARY LEVEL
  const EDUCATION_LEVEL = 'nivel-1';

  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);
  const [educationLevelData, setEducationLevelData] = useState([]);
  const [inputValue, setInputValue] = useState(defaultValue);

  const getData = async () => {
    setIsLoading(true);

    let responseYears = await fetchWithToken({
      method: 'GET',
      path: `/educations-years?pageSize=100&offset=${offset + 1}`
    });

    setEducationLevelData([
      ...educationLevelData,
      ...responseYears.data.educationYears
        .filter(
          (item) =>
            item?.educationLevel?.guid === 'nivel-2' ||
            (item?.educationLevel?.guid === 'nivel-1' && item?.year !== '7')
        )
        .map((educationYear) => ({
          label: generateEducationYear(educationYear, t),
          year: educationYear.year,
          id: educationYear.guid
        }))
    ]);

    setTotal(responseYears.data.count);
    setOffset(responseYears.data.offset);
    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce(() => {
      getData();
    }, 500),
    []
  );

  const handleInputChange = (event, value) => {
    // Creates an array with the selected ids
    setInputValue(value.id);
    debouncedFetchOptions(value);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          limitTags={2}
          showLabel={showLabel}
          required={required}
          label={label || t('education_level_label')}
          disableClearable
          loading={isLoading}
          disabled={disabled}
          loadingText={t('loading_text')}
          noOptionsText={t('no_options_text')}
          defaultValue={defaultValue}
          options={educationLevelData}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          getOptionLabel={(option) => option.label}
          onChange={handleInputChange}
          renderInput={(params) => (
            <EDInput
              {...params}
              placeholder={t('education_level_placeholder')}
            />
          )}
        />
      )}
    </>
  );
};

export default EDEducationLevelFilter;
