import { useEffect, useState } from 'react'

const usePreviewActivity = () => {
  const [data, setData] = useState(null)
  useEffect(() => {
    function handleReceiveMessage(event) {
      const data = event.data // Aquí obtienes los datos que se enviaron desde la ventana principal
      // Haz algo con los datos aquí...
      if (data.json) {
        setData(data)
      }
    }
    window.addEventListener('message', handleReceiveMessage)

    return () => {
      // Limpiar el oyente cuando se desmonte el componente
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [])

  return { data }
}

export default usePreviewActivity
