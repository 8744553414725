import { createColumnHelper } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CellProgramStatusChip from '../../../../components/table/cell/cellTypes/CellProgramStatusChip';
import CellTag from '../../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfText from '../../../../components/table/cell/cellTypes/CellWithOneLineOfText';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import { generateEducationYear } from '../../../../utils/helpers/generateEducationYear';
import { lastUpdate } from '../../../../utils/helpers/lastUpdate';
import useAuth from '../../../../utils/hooks/useAuth';
import useEditorDashboardPermissions from '../../../../utils/hooks/useEditorDashboardPermissions';

const useListPrograms = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isUserProgramEditor } = useEditorDashboardPermissions();
  const columnHelper = createColumnHelper();
  const pageSize = 10;

  const [educationYears, setEducationYears] = useState(null);

  const [tenants, setTenants] = useState(null);
  const [phasers, setPhasers] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [standards, setStandards] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [numberOfFilters, setNumberOfFilters] = useState(0);

  const [isCreateNewProgramDialogOpen, setIsCreateNewProgramDialogOpen] =
    useState(false);

  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    type: 'success',
    message: ''
  });

  const columns = [
    columnHelper.accessor('name', {
      header: t('programs_table_column_name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'cellWithOneLineOfText',
      cell: (props) => <CellWithOneLineOfText text={props.row.original.name} />
    }),
    columnHelper.accessor('status', {
      header: t('programs_table_column_status'),
      id: 'status',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellProgramStatusChip status={info.row.original.status} />
      )
    }),
    columnHelper.accessor('country', {
      header: t('programs_table_column_country'),
      id: 'country',
      enableSorting: false,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('educationYear', {
      header: t('programs_table_column_education_level'),
      id: 'educationYear',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (info) =>
        generateEducationYear(
          educationYears.filter(
            (educationYear) =>
              educationYear.guid === info.row.original.educationYear.guid
          )[0],
          t
        )
    }),
    columnHelper.accessor('tenants', {
      header: t('programs_table_column_tenant'),
      id: 'tenants',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellTag
          tags={info.row.original.tenants.map((tenant) => ({
            label: tenant.name
          }))}
        />
      )
    }),
    columnHelper.accessor('owner', {
      header: t('programs_table_column_owner'),
      id: 'owner',
      enableSorting: false,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('lastUpdated', {
      header: t('programs_table_column_last_updated'),
      id: 'updatedAt',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  const getEducationYears = async () => {
    let response = await fetchWithToken({
      path: '/educations-years?pageSize=100&offset=0'
    });
    // Show only Primaria
    const filteredEducationYears = response?.data?.educationYears?.filter(
      (educationYear) =>
        educationYear?.educationLevel?.guid === 'nivel-2' ||
        (educationYear?.educationLevel?.guid === 'nivel-1' &&
          educationYear?.year !== '7')
    );

    setEducationYears(
      filteredEducationYears.map((educationYear) => ({
        ...educationYear,
        name: generateEducationYear(educationYear, t)
      }))
    );
  };

  const getTenants = async () => {
    let response = await fetchWithToken({
      path: '/tenants?pageSize=100&offset=0'
    });

    setTenants(response.data.tenants);
  };

  const getPhasers = async () => {
    let response = await fetchWithToken({
      path: '/phasers/master-templates'
    });

    setPhasers(response.data);
  };

  const getStandards = async () => {
    let response = await fetchWithToken({
      path: '/standards?offset=0&pageSize=100'
    });
    setStandards(response.data);
  };

  const onChangeFilter = (newFilters) => {
    let tempFilters = { ...newFilters };
    // remove page from filters to avoid pagination issues when changing filters
    delete tempFilters.page;
    // filters are added if they do not exist and replaced if they do
    setFilters({ ...tempFilters, search: search });
    // save filters on url
    saveFiltersInURL({ ...tempFilters, search: search });
    // update number of filters
    Object.keys(
      Object.keys(tempFilters).filter(
        (item) => !['search', 'orderBy', 'orderType'].includes(item)
      )
    ).length - 1;

    getData();
  };

  const saveFiltersInURL = (newFilters) => {
    // check if all filters are empty
    const allFiltersEmpty = Object.values(newFilters).every(
      (filter) => filter === '' || filter === null || filter === undefined
    );
    // if all filters are empty, remove filters from url
    if (allFiltersEmpty) {
      const searchParams = new URLSearchParams(window.location.search);

      const URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // delete except search
        if (!['search', 'orderBy', 'orderType'].includes(key)) {
          searchParams.delete(key);
        }
      });

      const newUrl = `${window.location.pathname}${
        searchParams.toString() !== ''
          ? `?${searchParams.toString()}`
          : searchParams.toString()
      }`;

      history.pushState(null, '', newUrl);
      return;
    }

    const searchParams = new URLSearchParams();
    const tempFilters = { ...newFilters };
    delete tempFilters.offset;
    console.log('saveFiltersInURL tempFilters --- ', tempFilters);

    Object.keys(tempFilters).forEach((key) => {
      if (
        tempFilters[key] !== '' &&
        tempFilters[key] !== null &&
        tempFilters[key] !== undefined
      ) {
        searchParams.append(
          key,
          typeof tempFilters[key] === 'object'
            ? JSON.stringify(tempFilters[key])
            : tempFilters[key]
        );
      }
    });
    // searchParams.set('filters', JSON.stringify(newFilters));
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    history.pushState(null, '', newUrl);
  };

  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // get all search params
    let URLfilters = {};
    try {
      URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // check if value is a stringified object
        if (URLfilters[key].startsWith('{')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }

        // check if value is a stringified array
        if (URLfilters[key].startsWith('[')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }
      });
    } catch (e) {
      console.log('error parsing filters', e);
    }

    setFilters(URLfilters);
    setSearch(URLfilters.search);
    setOffset(((URLfilters.page || 1) - 1) * pageSize);
    setCurrentPage(URLfilters.page || 1);

    // update number of filters
    setNumberOfFilters(
      Object.keys(URLfilters).filter(
        (item) =>
          !['search', 'page', 'orderBy', 'orderType'].includes(item) &&
          URLfilters[item] !== '' &&
          URLfilters[item] !== null &&
          URLfilters[item] !== undefined
      ).length
    );

    return { ...URLfilters, offset: ((URLfilters.page || 1) - 1) * pageSize };
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      let filtersFromURL = getFiltersFromURL();
      delete filtersFromURL.page;
      saveFiltersInURL({ ...filtersFromURL, search: searchValue });
      getData();
      return;
    }, 500),
    []
  );

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * pageSize);

    saveFiltersInURL({ ...filters, page: page });
  };

  const getLastUpdate = (program) => {
    const updatedString = lastUpdate(
      program?.updatedAt,
      (program?.updatedBy || program?.createdBy)?.name || t('no_data'),
      t
    );

    const publishedString = program?.synchronizedAt
      ? lastUpdate(
          program?.synchronizedAt,
          program?.synchronizedBy?.name || t('no_data'),
          t
        )
      : null;

    const formattedUpdatedString =
      t('programs_table_updated') +
      ' ' +
      updatedString.charAt(0).toLowerCase() +
      updatedString.slice(1);

    const formattedPublishedString = publishedString
      ? t('programs_table_published') +
        ' ' +
        publishedString.charAt(0).toLowerCase() +
        publishedString.slice(1)
      : t('program_editor_not_published');

    if (program?.status === 'PUBLISHED') return formattedPublishedString;
    else return formattedUpdatedString;
  };

  const getData = async (isUpdate = false) => {
    if (!isUpdate) setIsLoading(true);

    const filtersFromURL = getFiltersFromURL();

    const queryParameters = [
      `offset=${
        filtersFromURL.offset !== undefined && filtersFromURL.offset !== null
          ? filtersFromURL.offset
          : offset
      }`,
      `pageSize=${pageSize}`,
      filtersFromURL.search && `name=${filtersFromURL.search}`,
      filtersFromURL.status &&
        filtersFromURL.status.map((s) => `status[]=${s}`).join('&'),
      filtersFromURL.responsible &&
        filtersFromURL.responsible.map((s) => `createdBy[]=${s.id}`).join('&'),
      filtersFromURL.countryCode &&
        filtersFromURL.countryCode.map((s) => `countryCode[]=${s}`).join('&'),
      filtersFromURL.educationYearGuid &&
        filtersFromURL.educationYearGuid
          .map((s) => `educationYearGuid[]=${s.id}`)
          .join('&'),
      filtersFromURL.updatedAt && `updatedAt=${filtersFromURL.updatedAt}`,
      `orderBy=${filtersFromURL.orderBy || 'updatedAt'}`,
      `orderType=${filtersFromURL.orderType || 'DESC'}`
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/programs?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });

    const tempPrograms = response.data.programs.map((program) => ({
      ...program,
      country: program.country?.name,
      language: program.lang?.name,
      lastUpdated: getLastUpdate(program),
      owner: program.createdBy
        ? `${program.createdBy?.name} ${program.createdBy?.lastname}`
        : '-'
    }));

    setPrograms(tempPrograms);
    setTotal(response.data.count);
    setTotalPages(Math.ceil(response.data.count / pageSize));
    setOffset(response.data.offset);
    if (!isUpdate) setIsLoading(false);
  };

  const onCreateNewProgram = async (newProgram) => {
    const response = await fetchWithToken({
      path: '/programs',
      method: 'POST',
      data: {
        ...newProgram,
        description: newProgram.name,
        code: newProgram.name
          .toLowerCase()
          .replace(/\s/g, '-')
          .substring(0, 29),
        educationDisciplineGuid: '09d34c20-71b9-475e-b42d-d677883700e9',
        learningObjectiveGuid: newProgram.learningObjectiveGuid,
        educationYearGuid: newProgram.educationYearGuid,
        phaserTemplateGuid: newProgram.phaserTemplateGuid,
        tenantsGuid: newProgram.tenantsGuid
      }
    });

    setIsCreateNewProgramDialogOpen(false);

    if (response.data.error) {
      setToastMessage({
        type: 'error',
        message: response.data?.message || t('programs_create_error')
      });
      setShowToast(true);

      return;
    } else {
      setToastMessage({
        type: 'success',
        message: t('programs_create_success')
      });
      setShowToast(true);
    }
    getData();
  };

  const onDeleteProgram = async (program) => {
    const response = await fetchWithToken({
      path: `/programs`,
      method: 'DELETE',
      data: { guids: [program.guid] }
    });

    if (response.data.status === 'error') {
      setToastMessage({
        type: 'error',
        message: response.data?.message || t('programs_delete_error')
      });

      setShowToast(true);
      return;
    } else {
      setToastMessage({
        type: 'success',
        message: t('programs_delete_success')
      });
      setShowToast(true);
    }
    getData();
  };

  const onDuplicateProgram = async (program) => {
    const response = await fetchWithToken({
      path: `/programs/${program.guid}/copy`,
      method: 'POST',
      data: { name: `${program.name} (copy)` }
    });

    if (response.data.status === 'error') {
      setToastMessage({
        type: 'error',
        message: response.data?.message || t('programs_copy_error')
      });

      setShowToast(true);
      return;
    } else {
      setToastMessage({
        type: 'success',
        message: t('programs_copy_success')
      });
      setShowToast(true);
    }
    getData();
  };

  const onClickOnProgram = (program) => {
    navigate(`/programs/${program.guid}`);
  };

  // Permissions management

  const isProgramOwner = (program) => {
    return program?.createdBy?.guid === user?.guid;
  };

  const canEditProgram = (program) => {
    return !isUserProgramEditor || isProgramOwner(program);
  };

  useEffect(() => {
    if (!educationYears || !tenants || !phasers) return;
    getData();
  }, [educationYears, tenants, phasers, currentPage]);

  useEffect(() => {
    getTenants();
    getPhasers();
    getStandards();
    getEducationYears();
  }, []);

  useEffect(() => {
    const isUpdate = true;
    const UPDATE_INTERVAL_MS = 20000; // 20 seconds
    const hasPublishingInProgress = programs?.some(
      (program) => program.status === 'PUBLISHING-IN-PROGRESS'
    );

    const intervalId = setInterval(() => {
      if (hasPublishingInProgress) {
        getData(isUpdate);
      } else {
        clearInterval(intervalId);
      }
    }, UPDATE_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [programs, getData]);

  return {
    canEditProgram,
    total,
    offset,
    phasers,
    standards,
    showToast,
    tenants,
    search,
    setShowToast,
    toastMessage,
    columns,
    programs,
    educationYears,
    onCreateNewProgram,
    pageSize,
    isLoading,
    onClickOnProgram,
    onChangePage,
    totalPages,
    currentPage,
    onDeleteProgram,
    isCreateNewProgramDialogOpen,
    setIsCreateNewProgramDialogOpen,
    onDuplicateProgram,
    onChangeFilter,
    onChangeSearch,
    filters,
    numberOfFilters
  };
};
export default useListPrograms;
