import { DeleteForever } from '@mui/icons-material'
import { CardContent, Grid, Typography } from '@mui/material'
import EDCard from '../../../../../atoms/EDCard/EDCard'
import TableDashboard from '../../../../../view/tableDashboard/TableDashboard'
import CreateNewUserDialog from '../../../users/List/Dialogs/CreateNewUserDialog'
import ImportUsersDialog from '../../../users/List/Dialogs/ImportUsersDialog/ImportUsersDialog'
import AddUsersToClassDialog from './dialogs/AddUsersToClassDialog'
import AddUsersToGroupDialog from './dialogs/AddUsersToGroupDialog'
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog'
import useSchoolUsers from './useSchoolUsers'

const SchoolUsers = ({
  users,
  onClickOnUser,
  onCreateNewUser,
  newUserDialogOpen,
  setNewUserDialogOpen,
  newUserSavedState,
  setNewUserSavedState,
  totalUsersInSchool,
  groups,
  classes,
  schoolGuid,
  onDeleteUser,
  getUsersInSchool,
  getSchoolGroups,
  getSchoolClasses,
  newImportUsersDialogOpen,
  setImportUsersDialogOpen
}) => {
  const {
    roles,
    columns,
    filteredUsers,
    userToDelete,
    confirmDeleteUserDialogOpen,
    isAddUsersToGroupDialogOpen,
    isAddUsersToClassDialogOpen,
    onSearch,
    onCloseImportDialog,
    onCloseNewUserDialog,
    onRemoveUserFromGroup,
    onSubmitCreateNewUser,
    setIsAddUsersDialogOpen,
    setIsAddUsersToClassDialogOpen,
    onCloseAddUsersToGroupDialog,
    onCloseAddUsersToClassDialog,
    handleCloseConfirmDeleteUserDialog
  } = useSchoolUsers({
    users,
    onCreateNewUser,
    getUsersInSchool,
    getSchoolGroups,
    getSchoolClasses,
    setImportUsersDialogOpen,
    setNewUserSavedState,
    setNewUserDialogOpen
  })

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h4' fontWeight={600}>
                Users
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TableDashboard
                filters={[]}
                onSearch={onSearch}
                onFilter={() => {}}
                tableData={filteredUsers}
                onRowClick={onClickOnUser}
                tableColumns={columns}
                totalEntries={totalUsersInSchool}
                onClickOnCreate={() => setNewUserDialogOpen(true)}
                extraActions={[
                  {
                    label: 'Add existing users to class',
                    onClick: () => setIsAddUsersToClassDialogOpen(true),
                    variant: 'outlined'
                  },
                  // {
                  //   label: 'Add existing users to group',
                  //   onClick: () => setIsAddUsersDialogOpen(true),
                  //   variant: 'outlined'
                  // },
                  {
                    label: 'Import',
                    onClick: () => setImportUsersDialogOpen(true)
                  }
                ]}
                singleEntryLabel={'user'}
                pluralEntryLabel={'users'}
                isTableDataLoading={false}
                searchInputPlaceholder={'Search users'}
                options={[
                  {
                    label: 'Remove user',
                    onClick: onRemoveUserFromGroup,
                    icon: <DeleteForever />
                  }
                ]}
                disableFilters={true}
                showPagination={false}
                showTopPagination={false}
              />
            </Grid>
          </Grid>
        </CardContent>
      </EDCard>

      {newImportUsersDialogOpen && (
        <ImportUsersDialog
          roles={roles}
          isOpen={newImportUsersDialogOpen}
          onClose={onCloseImportDialog}
          schoolGuid={schoolGuid}
        />
      )}
      <CreateNewUserDialog
        roles={roles}
        onSubmit={onSubmitCreateNewUser}
        isOpen={newUserDialogOpen}
        onClose={onCloseNewUserDialog}
        newUserSavedState={newUserSavedState}
        setNewUserSavedState={setNewUserSavedState}
        classes={classes}
      />
      {isAddUsersToGroupDialogOpen && (
        <AddUsersToGroupDialog
          isOpen={isAddUsersToGroupDialogOpen}
          onClose={onCloseAddUsersToGroupDialog}
          groups={groups}
          schoolGuid={schoolGuid}
          currentUsers={users}
          getUsersInSchool={getUsersInSchool}
          getSchoolGroups={getSchoolGroups}
        />
      )}
      {isAddUsersToClassDialogOpen && (
        <AddUsersToClassDialog
          isOpen={isAddUsersToClassDialogOpen}
          onClose={onCloseAddUsersToClassDialog}
          classes={classes}
          schoolGuid={schoolGuid}
          currentUsers={users}
          getUsersInSchool={getUsersInSchool}
          getSchoolClasses={getSchoolClasses}
        />
      )}
      {confirmDeleteUserDialogOpen && (
        <ConfirmDeleteDialog
          isOpen={confirmDeleteUserDialogOpen}
          onClose={handleCloseConfirmDeleteUserDialog}
          itemToBeDeletedName={'user'}
          itemToBeDeletedDescription={
            "By deleting this user, you are removing the user from all the classes inside this school, and removing the user's access to the school."
          }
          onConfirm={() => {
            onDeleteUser(userToDelete)
            handleCloseConfirmDeleteUserDialog()
          }}
        />
      )}
    </Grid>
  )
}

export default SchoolUsers
