import {
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDCard from '../../../../atoms/EDCard/EDCard';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDDepartmentFilter from '../../../../components/filters/EDDepartmentFilter';
import EDEducationLevelFilter from '../../../../components/filters/EDEducationLevelFilter';
import PillTabs from '../../../../components/pillTabs/PillTabs';
import { generateEducationYear } from '../../../../utils/helpers/generateEducationYear';

const KnowledgeComponentMetadata = ({ knowledgeComponent, onChange }) => {
  const { t } = useTranslation();
  const [BNCC, setBNCC] = useState();
  const [CC, setCC] = useState();

  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    console.log('LEARNING OUTCOME API', knowledgeComponent);
    if (metadata.name || !knowledgeComponent?.name) return;

    setMetadata({
      name: knowledgeComponent?.name,
      shortName: knowledgeComponent?.name,
      description: knowledgeComponent?.description, // legacy description
      knowledgeComponent: knowledgeComponent?.linkFrom?.map((item) => ({
        ...item,
        label: item.name,
        name: item.name,
        id: item.guid,
        value: item.guid
      }))[0],
      id: knowledgeComponent?.guid,
      descriptionLanguages: knowledgeComponent?.descriptionLanguages,
      internalEducationYear: knowledgeComponent?.internalEducationYear
        ? {
            id: knowledgeComponent?.internalEducationYear?.guid,
            year: knowledgeComponent?.internalEducationYear?.year,
            label: generateEducationYear(
              knowledgeComponent?.internalEducationYear,
              t
            )
          }
        : null,
      department: knowledgeComponent?.department
        ? {
            value: knowledgeComponent?.department?.guid,
            label: knowledgeComponent?.department?.name
          }
        : null,
      observation: knowledgeComponent?.observation
    });
    console.log('LEARNING OUTCOME API', knowledgeComponent);
    // console.log('LEARNING OUTCOME', metadata);
  }, [knowledgeComponent]);

  useEffect(() => {
    onChange({
      name: metadata?.name,
      description: metadata?.description,
      internalEducationYearGuid:
        metadata?.internalEducationYear?.id || metadata?.internalEducationYear,
      descriptionLanguages: metadata?.descriptionLanguages,
      departmentGuid: metadata?.department ? metadata?.department : null
    });
  }, [metadata]);

  const [currentLanguageToDisplay, setCurrentLanguageToDisplay] =
    useState('EN');

  const sortLanguages = () => {
    const order = { es: 1, en: 2, pt: 3 };
    const _tabsLanguages = metadata?.descriptionLanguages?.map((item) => ({
      name: item.lang,
      key: item.lang
    }));

    return _tabsLanguages.sort((a, b) => {
      return order[a.key.toLowerCase()] - order[b.key.toLowerCase()];
    });
  };

  if (!metadata.name) return <CircularProgress />;

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h3'>{t('metadata_tab_title')}</Typography>
            </Grid>
            <Grid item md={6}>
              <EDInputWithLabel
                required
                label={t('name')}
                placeholder={t('name')}
                value={metadata?.name}
                onChange={(e) =>
                  setMetadata({ ...metadata, name: e.target.value })
                }
              />
            </Grid>
            <Grid item md={6}>
              <EDInputWithLabel
                label='ID'
                placeholder='ID'
                value={metadata?.id}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction='column' spacing={3}>
                <div>
                  <PillTabs
                    tabs={sortLanguages()}
                    currentTab={currentLanguageToDisplay}
                    onChangeTab={(value) => {
                      setCurrentLanguageToDisplay(value);
                    }}
                  />
                </div>
                <EDInputWithLabel
                  required
                  label='Description'
                  multiline
                  rows={3}
                  placeholder='Write'
                  value={
                    metadata?.descriptionLanguages?.find(
                      (item) => item.lang === currentLanguageToDisplay
                    )?.description
                  }
                  onChange={(e) =>
                    setMetadata({
                      ...metadata,
                      descriptionLanguages: metadata?.descriptionLanguages?.map(
                        (item) => {
                          if (item.lang === currentLanguageToDisplay) {
                            return {
                              ...item,
                              description: e.target.value
                            };
                          }
                          return item;
                        }
                      )
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item md={6}>
              {metadata.department && (
                <EDDepartmentFilter
                  showLabel
                  required
                  multiple={false}
                  defaultValue={metadata?.department}
                  onChange={(selectedDepartment) => {
                    setMetadata({
                      ...metadata,
                      department: selectedDepartment.value
                    });
                  }}
                />
              )}
            </Grid>
            <Grid item md={6}>
              {metadata.name && (
                <EDEducationLevelFilter
                  showLabel
                  label={t('learning_outcome_column_internal_education_year')}
                  defaultValue={metadata?.internalEducationYear}
                  onChange={(value) => {
                    setMetadata({
                      ...metadata,
                      internalEducationYear: value
                    });
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </EDCard>
    </Grid>
  );
};

export default KnowledgeComponentMetadata;
