export const getPlatform = () => {
  const { origin } = window.location;

  const platforms = {
    3001: 'editor',
    3002: 'tenant'
  };

  const tenantBackofficeDomains = [
    'tenants-backoffice.tangerine-dev.oneclicklabs.es',
    'backoffice-blueberry.tangerine-dev1.oneclicklabs.es',
    'backoffice.school.blueberry-dev.oneclicklabs.es',
    'backoffice.school.blueberry-qa.oneclicklabs.es',
    'backoffice.school.blueberrymath.ai',
    'backoffice.escuela.blueberrymath.ai',
    'backoffice.escola.blueberrymath.ai',
    'backoffice.family.blueberrymath.ai',
    'backoffice.blueberry.oneclick.es',
    'backoffice.blueberry-sa.oneclicklabs.es',
    'tenants-backoffice.tangerine-qa.oneclicklabs.es',
    'tenants-backoffice.tangerine-demo.oneclickaws.tk',
    'tenant-backoffice.blueberry-family-qa.oneclicklabs.es',
    'backoffice.blueberry-family-dev.oneclicklabs.es',
    'backoffice.blueberry-school-dev.oneclicklabs.es',
    'backoffice.layers.blueberry.app.br',
    'backoffice.school.blueberrymath.com'
  ];

  // TODO: check if backoffice.blueberry-school-dev.oneclicklabs.es exists

  return origin.match(/http:\/\/localhost:/)
    ? platforms[window.location.port]
    : origin.includes('tenant') ||
      tenantBackofficeDomains.some((domain) => origin.includes(domain))
    ? 'tenant'
    : origin.includes('dashboard')
    ? 'teacher'
    : 'editor';
};
