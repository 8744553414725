import { CardContent, Grid, Typography } from '@mui/material';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';

const UserPassword = ({ setNewPassword, setNewConfirmPassword }) => {
  return (
    <Grid item xs={12}>
      <EDCard>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h4' fontWeight={600}>
                Manage Password
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <EDInputWithLabel
                type='password'
                autoComplete='off'
                label='New password'
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EDInputWithLabel
                type='password'
                autoComplete='off'
                label='Confirm password'
                onChange={(e) => setNewConfirmPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </EDCard>
    </Grid>
  );
};

export default UserPassword;
