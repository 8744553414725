import TDDashboardTabs from '../../../../components/TDDashboardTabs/TDDashboardTabs';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import useCourses from './useCourses';

const Courses = () => {
  const { total, offset, columns, courses, pageSize, isLoading } = useCourses();

  return (
    <PageWithHeader
      left={
        <TDDashboardTabs
          tabs={[
            { name: 'Courses', to: '/courses', key: 'courses' },
            { name: 'Programs', to: '/programs', key: 'programs' }
          ]}
          currentTab={'courses'}
        />
      }
    >
      <TableDashboard
        disableFiltersAndSearch
        filters={[
          {
            id: 'status',
            key: 'status',
            title: 'Status',
            type: 'radio',
            options: [
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' }
            ]
          }
        ]}
        onSearch={() => {}}
        onFilter={() => {}}
        tableData={courses}
        // onRowClick={(e) => {
        //   console.log(e);
        // }}
        tableColumns={columns}
        totalEntries={total}
        // onClickOnCreate={() => {}}
        singleEntryLabel='course'
        pluralEntryLabel='courses'
        searchInputPlaceholder='Search courses'
        numberOfFilters={0}
        isTableDataLoading={isLoading}
      />
    </PageWithHeader>
  );
};

export default Courses;
