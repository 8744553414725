import { Switch } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchWithToken } from '../../../../../utils/helpers/fetcher';
import CellClassWithTooltip from './cells/CellClassWithTooltip';
import CellWithToolTip from './cells/CellWithToolTip';

const useSchoolClassesV2 = ({ classes, getSchoolClassesV2 }) => {
  const { t } = useTranslation();
  const { schoolGuid } = useParams();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => {
        return (
          <CellClassWithTooltip
            code={row.original.code}
            name={row.original.name}
          />
        );
      }
    }),
    columnHelper.accessor('schoolYearName', {
      id: 'schoolYearName',
      header: 'School year'
    }),
    columnHelper.accessor('educationLevelName', {
      id: 'educationLevelName',
      header: 'Level',
      cell: ({ row }) => {
        return <CellWithToolTip text={row.original.educationLevelName} />;
      }
    }),
    columnHelper.accessor('educationYearName', {
      id: 'educationYearName',
      header: 'Year'
    }),
    columnHelper.accessor('programName', {
      id: 'programName',
      header: 'Program',
      cell: ({ row }) => {
        return <CellWithToolTip text={row.original.programName} />;
      }
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: ({ row }) => {
        return (
          <Switch
            checked={row.original.status}
            onChange={() => {
              onEditClassV2({
                ...row.original,
                status: !row.original.status
              });
            }}
          />
        );
      }
    }),
    columnHelper.accessor('ranking', {
      id: 'ranking',
      header: 'Ranking',
      cell: ({ row }) => {
        return (
          <Switch
            checked={row.original.ranking}
            onChange={() => {
              onEditClassV2({
                ...row.original,
                ranking: !row.original.ranking
              });
            }}
          />
        );
      }
    }),
    columnHelper.accessor('numberOfTeachers', {
      id: 'numberOfTeachers',
      header: 'Teachers'
    }),
    columnHelper.accessor('numberOfStudents', {
      id: 'numberOfStudents',
      header: 'Students'
    }),
    columnHelper.accessor('isLessonLocked', {
      id: 'isLessonLocked',
      header: 'Lesson locked',
      cell: ({ row }) => {
        return (
          <Switch
            checked={row.original.isLessonLocked}
            onChange={() => {
              onEditClassV2({
                ...row.original,
                isLessonLocked: !row.original.isLessonLocked
              });
            }}
          />
        );
      }
    }),
    columnHelper.accessor('isUppercase', {
      id: 'isUppercase',
      header: 'Uppercase',
      cell: ({ row }) => {
        return (
          <Switch
            checked={row.original.isUppercase}
            onChange={() => {
              onEditClassV2({
                ...row.original,
                isUppercase: !row.original.isUppercase
              });
            }}
          />
        );
      }
    })
  ];

  const [messageToast, setMessageToast] = useState({ type: '', text: '' });
  const [showToast, setShowToast] = useState(false);

  const [isCreateNewClassOpen, setIsCreateNewClassOpen] = useState(false);
  const [isCreatingOrEditingClass, setIsCreatingOrEditingClass] =
    useState(false);
  const [selectedClassToEdit, setSelectedClassToEdit] = useState(null);
  const [isEditClassOpen, setIsEditClassOpen] = useState(false);
  const [selectedClassToDelete, setSelectedClassToDelete] = useState(null);
  const [filteredClasses, setFilteredClasses] = useState(classes || []);
  const [searchText, setSearchText] = useState('');
  const [parsedClasses, setParsedClasses] = useState([]);
  const [isConfirmDeleteClassOpen, setIsConfirmDeleteClassOpen] =
    useState(false);

  const [usersInClass, setUsersInClass] = useState([]);
  const [isListOfUsersInClassOpen, setIsListOfUsersInClassOpen] =
    useState(false);
  const [isLoadingUsersInClass, setIsLoadingUsersInClass] = useState(false);
  const [selectedClassToViewUsers, setSelectedClassToViewUsers] =
    useState(null);

  const onCloseCreateEditClassDialog = () => {
    setIsCreateNewClassOpen(false);
    setIsEditClassOpen(false);
    setSelectedClassToEdit(null);
  };

  const onCloseDeleteClassDialog = () => {
    setIsConfirmDeleteClassOpen(false);
    setSelectedClassToDelete(null);
  };

  useEffect(() => {
    setParsedClasses(
      filteredClasses.map((item) => ({
        guid: item.guid,
        name: item.name,
        code: item.code,
        numberOfTeachers: item.amount_teachers,
        numberOfStudents: item.amount_students,
        isLessonLocked: Boolean(item.bb_units_locked),
        isUppercase: Boolean(item.letter_case !== 'regularCase'),
        programName: item.program.name,
        programGuid: item.program.guid,
        schoolYearName: item.schoolYear.name,
        schoolYearGuid: item.schoolYear.guid,
        educationLevelName: item.educationLevel.name,
        educationLevelGuid: item.educationLevel.guid,
        educationYearName: item.educationYear.name,
        educationYearGuid: item.educationYear.guid,
        status: Boolean(item.is_active),
        ranking: Boolean(item.blueberry_ranking)
      }))
    );
  }, [filteredClasses]);

  const tableRowOptions = [
    {
      label: t('edit'),
      onClick: (row) => {
        setSelectedClassToEdit(row);
        setIsEditClassOpen(true);
      }
    },
    {
      label: t('delete'),
      onClick: (row) => {
        setIsConfirmDeleteClassOpen(true);
        setSelectedClassToDelete(row);
      }
    }
  ];

  const onSearch = (search = '') => {
    const lowerCaseSearchText = search.toLowerCase();
    setSearchText(lowerCaseSearchText);

    const filteredClasses = classes.filter((item) => {
      const {
        name,
        code,
        school_group_year_name,
        school_group_name,
        programName
      } = item;
      return (
        name?.toLowerCase().includes(lowerCaseSearchText) ||
        code?.toLowerCase().includes(lowerCaseSearchText) ||
        school_group_year_name?.toLowerCase().includes(lowerCaseSearchText) ||
        school_group_name?.toLowerCase().includes(lowerCaseSearchText) ||
        programName?.toLowerCase().includes(lowerCaseSearchText)
      );
    });

    setFilteredClasses(filteredClasses);
  };

  const getTimeZone = () => {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const hours = Math.abs(Math.floor(timeZoneOffset / 60))
      .toString()
      .padStart(2, '0');
    const minutes = Math.abs(timeZoneOffset % 60)
      .toString()
      .padStart(2, '0');
    return `${timeZoneOffset < 0 ? '+' : '-'}${hours}:${minutes}`;
  };

  const onCreateNewClassV2 = async ({
    name,
    schoolYearGuid,
    educationYearGuid,
    programGuid
  }) => {
    setIsCreatingOrEditingClass(true);

    const createClassResponse = await fetchWithToken({
      method: 'POST',
      path: `front/school-admin/${schoolGuid}/classes`,
      data: {
        name: name,
        school_year_guid: schoolYearGuid,
        education_discipline_guid: 'ed2',
        education_year_guid: educationYearGuid,
        program_guid: programGuid,
        status: 1,
        blueberry_ranking: 1,
        bb_units_locked: 0,
        letter_case: 'regularCase',
        time_zone: getTimeZone()
      }
    });

    const hasError = createClassResponse.status !== 'success';

    setMessageToast({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('class_create_success') : t('class_create_error')
    });

    setShowToast(true);
    setIsCreatingOrEditingClass(false);
    onCloseCreateEditClassDialog();

    if (!hasError) getSchoolClassesV2();
  };

  const onDeleteClassV2 = async (classGuid) => {
    const deleteClassResponse = await fetchWithToken({
      method: 'DELETE',
      path: `front/school-admin/${schoolGuid}/classes/${classGuid}`
    });

    const hasError = deleteClassResponse.status !== 'success';

    setMessageToast({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('class_delete_success') : t('class_delete_error')
    });

    setShowToast(true);

    if (!hasError) getSchoolClassesV2();
  };

  const onEditClassV2 = async ({
    guid,
    name,
    schoolYearGuid,
    educationYearGuid,
    programGuid,
    isUppercase,
    isLessonLocked,
    status,
    ranking
  }) => {
    setIsCreatingOrEditingClass(true);

    const editClassResponse = await fetchWithToken({
      method: 'PUT',
      path: `front/school-admin/${schoolGuid}/classes/${guid}`,
      data: {
        name: name,
        school_year_guid: schoolYearGuid,
        education_year_guid: educationYearGuid,
        program_guid: programGuid,
        time_zone: getTimeZone(),
        letter_case: isUppercase ? 'upperCase' : 'regularCase',
        bb_units_locked: Number(isLessonLocked),
        is_active: Number(status),
        blueberry_ranking: Number(ranking)
      }
    });

    const hasError = editClassResponse.status !== 'success';

    setMessageToast({
      type: !hasError ? 'success' : 'error',
      text: !hasError ? t('class_edit_success') : t('class_edit_error')
    });

    if (!hasError) getSchoolClassesV2();
    setShowToast(true);
    setIsCreatingOrEditingClass(false);
    onCloseCreateEditClassDialog();
  };

  const getUsersInClassV2 = async (clickedClass) => {
    setSelectedClassToViewUsers(clickedClass);
    setIsListOfUsersInClassOpen(true);
    setIsLoadingUsersInClass(true);
    const classUsersResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups/${clickedClass.guid}/users`
    });

    if (classUsersResponse.status !== 'success') {
      return [];
    }

    setUsersInClass(classUsersResponse?.data?.users);
    setIsLoadingUsersInClass(false);
  };

  const onCloseListOfUsersInClass = () => {
    setIsListOfUsersInClassOpen(false);
    setSelectedClassToViewUsers(null);
  };

  const onDeleteUserFromClass = async (userGuid) => {
    const deleteUserFromClassResponse = await fetchWithToken({
      method: 'DELETE',
      path: `/front/school-admin/${schoolGuid}/groups/${selectedClassToViewUsers.guid}/users/${userGuid}`
    });

    if (deleteUserFromClassResponse.status !== 'success') {
      return [];
    }

    getUsersInClassV2(selectedClassToViewUsers);
  };

  useEffect(() => {
    setFilteredClasses(classes || []);
  }, [classes]);

  return {
    columns,
    showToast,
    messageToast,
    parsedClasses,
    filteredClasses,
    tableRowOptions,
    isEditClassOpen,
    selectedClassToEdit,
    isCreateNewClassOpen,
    selectedClassToDelete,
    isCreatingOrEditingClass,
    usersInClass,
    isListOfUsersInClassOpen,
    isLoadingUsersInClass,
    selectedClassToViewUsers,
    setShowToast,
    onSearch,
    setIsCreateNewClassOpen,
    onCloseCreateEditClassDialog,
    onCloseDeleteClassDialog,
    isConfirmDeleteClassOpen,
    setIsConfirmDeleteClassOpen,
    onDeleteClassV2,
    onCreateNewClassV2,
    onEditClassV2,
    getUsersInClassV2,
    onCloseListOfUsersInClass,
    onDeleteUserFromClass
  };
};

export default useSchoolClassesV2;
