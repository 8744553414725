import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './FullpageLayout.scss';

const FullPageLayout = ({
  left,
  title,
  subtitle,
  right,
  children,
  footerLeft,
  footerCenter,
  footerRight,
  fullWidth,
  containerSize,
  fullScreenModal,
  type,
  customClass
}) => {
  const { t } = useTranslation();
  const {
    palette: {
      primary: { main: primaryColor }
    }
  } = useTheme();
  const hasFooter = footerLeft || footerCenter || footerRight;
  const fullPageLayoutClasses =
    'full-page-layout__container' +
    (' full-page-layout--' + (type ? 'teacher' : 'default')) +
    (hasFooter ? ' full-page-layout--with-footer' : '') +
    (fullScreenModal ? ' full-page-layout--fullscreen-modal' : '') +
    (customClass ? ' ' + customClass : '');

  return (
    <div className={fullPageLayoutClasses}>
      <div
        className={`header__container`}
        style={{ backgroundColor: primaryColor }}
      >
        <div
          className={`header__content ${
            fullWidth ? 'header__content--full-width' : ''
          }`}
        >
          <div className='header__left'>{left}</div>
          <div className='header__center'>
            <div className='header__title'>{title}</div>
            <div className='header__subtitle'>{subtitle}</div>
          </div>
          <div className='header__right'>{right}</div>
        </div>
      </div>
      <div
        className={`content__container ${
          fullWidth ? 'content__container--full-width' : ''
        } ${containerSize ? 'content__container--' + containerSize : ''}`}
      >
        <div className='content'>{children}</div>
      </div>

      {(footerLeft || footerCenter || footerRight) && (
        <div className='content__footer'>
          <div className='content__footer--left'>{footerLeft}</div>
          <div className='content__footer--center'>{footerCenter}</div>
          <div className='content__footer--right'>{footerRight}</div>
        </div>
      )}
    </div>
  );
};

export default FullPageLayout;
