import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { ReactComponent as CompletedIcon } from '../../assets/icons/status_completed.svg';
import { ReactComponent as DraftIcon } from '../../assets/icons/status_translations.svg';
import { PROGRAM_UNIT_STATUS } from '../../utils/constants/programUnitStatus';
import './EDProgramStatusSelect.scss';

import { useTranslation } from 'react-i18next';
import { CustomArrow } from '../../atoms/CustomArrow/CustomArrow';
import { patchUnit } from '../../services/unitService';

const EDProgramStatusSelect = ({ unit, setShowUnitAlert }) => {
  const { t } = useTranslation();

  const statusInfo = [
    {
      key: PROGRAM_UNIT_STATUS.DRAFT,
      icon: <DraftIcon />,
      name: t('programs_table_unit_status_draft')
    },
    {
      key: PROGRAM_UNIT_STATUS.DONE,
      icon: <CompletedIcon />,
      name: t('programs_table_unit_status_done')
    }
  ];

  const [status, setStatus] = useState(unit.status);

  useEffect(() => {
    setStatus(unit.status);
  }, [unit]);

  const handleChange = (event) => {
    onSaveStatus(status, event.target.value);
    setStatus(event.target.value);
  };

  const onSaveStatus = async (previousStatus, newStatus) => {
    const response = await patchUnit(unit.guid, { status: newStatus });

    if (response.status === 'success') {
      setShowUnitAlert('success');
    } else {
      setStatus(previousStatus);
      setShowUnitAlert('error');
    }

    setShowToast(true);
  };

  return (
    <div className='program-unit-status-select'>
      <Select
        id='program-unit-status-select__select'
        className='program-unit-status-select__select'
        value={status}
        onChange={handleChange}
        variant='outlined'
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          getContentAnchorEl: null,
          sx: { marginTop: '12px' }
        }}
        IconComponent={CustomArrow}
      >
        {statusInfo.map((statusItem) => (
          <MenuItem
            key={statusItem.key}
            value={statusItem.key}
            className='program-unit-status-select__item'
          >
            <span className='program-unit-status-select__item-icon'>
              {statusItem.icon}
            </span>
            <span className='program-unit-status-select__item-name'>
              {statusItem.name}
            </span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default EDProgramStatusSelect;
