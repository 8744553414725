import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../../atoms/AlertDialog/AlertDialog';
import ProfileMenuItem from '../../components/profileMenuItem/ProfileMenuItem';
import { goToGame } from '../../utils/helpers/goToGame';
import { getPlatform } from '../../utils/helpers/platform';
import useAuth from '../../utils/hooks/useAuth';
import DashboardMenuItem from './DashboardMenuItem';
import './DashboardWithSidebarMenu.scss';

const DashboardWithSidebarMenu = ({ upperNavigation, lowerItems }) => {
  const { user } = useAuth();
  const {
    palette: {
      primary: { main: primaryColor }
    }
  } = useTheme();

  const [isLoginAsStudentOpen, setIsLoginAsStudentOpen] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();

  const loginAsStudent = () => {
    setIsLoginAsStudentOpen(true);
  };

  const onClickForAction = (action) => {
    if (action === 'loginAsStudent') {
      loginAsStudent();
      return;
    }
  };

  function onConfirmDialog() {
    handleCloseDialog();
    goToGame();
  }

  function handleCloseDialog() {
    setIsLoginAsStudentOpen(false);
  }

  // console.log('upperNavigation', upperNavigation);

  const checkPermissions = (item) => {
    // TODO: CHANGE TO PERMISSIONS ARRAY IN UPPER NAVIGATION
    // TODO: Check other backoffices
    const platform = getPlatform();

    if (platform !== 'editor') {
      return true;
    }

    switch (user?.rolePerson?.guid) {
      // REVIEWER
      case 'R02':
        return item.isReviewer;

      // PROGRAM EDITOR
      case 'R05':
        return item.isProgramEditor;

      // GUEST
      case 'R04':
        return item.isDemo;

      default:
        return true;
    }
  };

  return (
    <>
      <div
        className='sidebar'
        style={{
          backgroundColor: primaryColor
        }}
      >
        <div className='sidebar__container'>
          <div className='sidebar__navigation'>
            {/* <div className="sidebar__navigation-logo">
								<Logo />
							</div> */}
            <nav className='sidebar__navigation-list'>
              {upperNavigation
                ?.filter((item) => checkPermissions(item))
                .map((item) => (
                  <div
                    key={item.key[0]}
                    className='sidebar__navigation-list-item-container'
                  >
                    {item.type === 'separator' ? (
                      <div className='sidebar__navigation-list-separator' />
                    ) : (
                      <DashboardMenuItem
                        name={item?.name}
                        action={item?.action}
                        href={item?.href}
                        key={item.key}
                        pageKey={item.key}
                        icon={item?.icon}
                        flag={item?.flag}
                      />
                    )}
                  </div>
                ))}
            </nav>
          </div>
          <div className='sidebar__navigation'>
            <nav className='sidebar__navigation-list'>
              <div className='sidebar__navigation-list-item'>{null}</div>
              <div className='sidebar__navigation-list-item sidebar__navigation-list-item--avatar'>
                <div className='sidebar__navigation-list-item-container'>
                  <ProfileMenuItem />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className='main-content'>
        <main className='main-content__container'>
          <Outlet />
        </main>

        <AlertDialog
          isOpen={isLoginAsStudentOpen}
          title={t('Are you sure you want to enter student view?')}
          description={t('A new tab will open in the browser')}
          onConfirm={onConfirmDialog}
          onCancel={handleCloseDialog}
          confirmText={t('Yes')}
          cancelText={t('No')}
        />
      </div>
    </>
  );
};

export default DashboardWithSidebarMenu;
