import { ThemeProvider, createTheme } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import DashboardWithSidebarMenu from '../layouts/dashboardWithSidebarMenu/DashboardWithSidebarMenu';
import Demo from '../pages/demo/Demo';
import DemoUsers from '../pages/demoUsers/DemoUsers';
import LearningOutcomesManager from '../pages/editorDashboard/LearningOutcomesManager/LearningOutcomesManager';
import SingleLearningOutcome from '../pages/editorDashboard/SingleLearningOutcome/SingleLearningOutcome';
import ActivitiesManager from '../pages/editorDashboard/activitiesManager/ActivitiesManager';
import Departments from '../pages/editorDashboard/departments/Departments';
import KnowledgeComponentsManager from '../pages/editorDashboard/knowledgeComponentManager/KnowledgeComponentsManager';
import LemonadeAllQuestions from '../pages/editorDashboard/lemonadeAllQuestions/LemonadeAllQuestions';
import NodesVisualizer from '../pages/editorDashboard/nodesVisualizer/NodesVisualizer';
import PreviewActivity from '../pages/editorDashboard/previewActivity/PreviewActivity';
import EditProgram from '../pages/editorDashboard/programDetails/EditProgram';
import Programs from '../pages/editorDashboard/programs/list/ListPrograms';
import SingleActivity from '../pages/editorDashboard/singleActivity/SingleActivity';
import SingleKnowledgeComponent from '../pages/editorDashboard/singleKnowledgeComponentManager/SingleKnowledgeComponent';
import StandardsManager from '../pages/editorDashboard/standardsManager/StandardsManager';
import UpdateActivity from '../pages/editorDashboard/updateActivity/UpdateActivity';

const upperNavigation = [
  {
    name: 'home',
    href: '/',
    icon: 'home',
    key: ['home'],
    isDemo: true,
    isReviewer: true,
    isProgramEditor: true
  },
  {
    name: 'Content',
    href: '/activities',
    icon: 'document',
    key: ['activities', 'programs'],
    isDemo: false,
    isReviewer: true,
    isProgramEditor: true
  },
  {
    name: 'Learning Outcomes',
    href: '/learning-outcomes',
    icon: 'briefcase',
    key: ['learning-outcomes', 'knowledge-components', 'learning-objectives'],
    isDemo: false,
    isReviewer: false,
    isProgramEditor: true
  },
  {
    name: 'Standards',
    href: '/standards',
    icon: 'archive',
    key: ['standards'],
    isDemo: false,
    isReviewer: false,
    isProgramEditor: true
  },
  {
    name: 'Users',
    href: '/users',
    icon: 'users',
    key: ['users'],
    isDemo: false,
    isReviewer: false,
    isProgramEditor: false
  },
  {
    name: 'knowledge_graph',
    href: '/graph',
    icon: 'graph',
    key: ['graph'],
    isDemo: true,
    isReviewer: false,
    isProgramEditor: false
  }
];

const theme = createTheme({
  palette: {
    common: {
      black: '#303949'
    },
    primary: {
      main: '#2544CA',
      light: '#6363FC',
      dark: '#3535A0'
    },
    success: {
      main: '#00e19a',
      light: '#B7F1E2',
      dark: '#13B481'
    },
    error: {
      main: '#ff3d30',
      light: '#FFE5E2',
      dark: '#DE2A1E'
    },
    warning: {
      main: '#ffae56',
      light: '#FFF0DE',
      dark: '#E8963D'
    },
    info: {
      main: '#45dbf4',
      light: '#DDF9FF',
      dark: '#1FB9D2'
    },
    text: {
      primary: '#303949'
    },
    grey: {
      50: '#FDFDFD',
      100: '#f3f6f9',
      200: '#c6cfdc',
      300: '#dfe5ec',
      400: '#C3C0DC',
      500: '#8c99ad',
      600: '#3E3A68',
      700: '#414b5c',
      800: '#1B1A26'
    },
    action: {
      disabledBackground: '#f3f6f9',
      disabled: '#dfe5ec'
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.75
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.5
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.25
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: 1.4286
    }
  },
  shape: {
    borderRadius: 4
  }
});

export const editorDashboardRoutes = [
  {
    element: (
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    ),
    children: [
      {
        element: <DashboardWithSidebarMenu upperNavigation={upperNavigation} />,
        children: [
          {
            path: '/',
            exact: true,
            element: <Navigate to='/home' />
          },
          {
            path: '/activities',
            element: <ActivitiesManager />
          },
          {
            path: '/programs',
            element: <Programs />
          },
          {
            path: '/learning-outcomes',
            element: <LearningOutcomesManager />
          },
          {
            path: '/knowledge-components',
            element: <KnowledgeComponentsManager />
          },
          {
            path: '/departments',
            element: <Departments />
          },
          {
            path: '/standards',
            element: <StandardsManager />
          },
          {
            path: '/home',
            element: <Demo />
          },
          {
            path: '/graph',
            element: <NodesVisualizer />
          },
          {
            path: '/demo',
            element: <Demo />
          },
          {
            path: '/users',
            element: <DemoUsers />
          },
          {
            path: '/lemonade-all-questions',
            element: <LemonadeAllQuestions />
          }
        ]
      },
      {
        path: '/programs/:programGuid',
        element: <EditProgram />
      },
      {
        path: '/update-activity',
        element: <UpdateActivity />
      },
      {
        path: '/learning-outcomes/:learningOutcomeGuid',
        element: <SingleLearningOutcome />
      },
      {
        path: '/knowledge-components/:knowledgeComponentGuid',
        element: <SingleKnowledgeComponent />
      },
      {
        path: '/activities/:activityId',
        element: <SingleActivity />
      },
      {
        path: '/departments/:departmentGuid',
        element: <SingleActivity />
      },
      {
        path: '/preview',
        element: <PreviewActivity />
      }
    ]
  }
];
