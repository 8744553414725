import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/icon';
import BBTooltip from '../../atoms/tooltip/BBTooltip';

const DashboardMenuItem = ({ name, action, href, pageKey, icon, flag }) => {
  const { t } = useTranslation();

  const checkIsCurrentTab = (key) => {
    // key can be string or array
    if (typeof key === 'string') {
      return location.pathname.split('/').includes(key);
    } else if (Array.isArray(key)) {
      return key.some((k) => location.pathname.split('/').includes(k));
    }
  };

  return (
    <BBTooltip text={t(name)} placement='right'>
      <div>
        {action ? (
          <div
            className='sidebar__navigation-list-item'
            sx={{ padding: 0 }}
            onClick={() => onClickForAction(action)}
          >
            <Icon type={icon} format='outline' />
          </div>
        ) : (
          <Link
            to={href}
            className={`sidebar__navigation-list-item ${
              checkIsCurrentTab(pageKey)
                ? 'sidebar__navigation-list-item--active'
                : ''
            }`}
          >
            <Icon type={icon} format='outline' />
          </Link>
        )}
      </div>
    </BBTooltip>
  );
};

export default DashboardMenuItem;
