import { ReactComponent as CustomArrowImage } from '../../assets/icons/arrow_down.svg';
import './CustomArrow.scss';

export const CustomArrow = () => {
  return (
    <div
      className='select-custom-arrow MuiSvgIcon-root MuiSelect-icon'
      style={{ pointerEvents: 'none', marginLeft: '20', marginRight: '20' }}
    >
      <CustomArrowImage />
    </div>
  );
};
