import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import CellTag from '../../../components/table/cell/cellTypes/CellTag'
import CellWithOneLineOfText from '../../../components/table/cell/cellTypes/CellWithOneLineOfText'
import { fetchWithToken } from '../../../utils/helpers/fetcher'
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions'

const useSingleActivity = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const columnHelper = createColumnHelper()

  const [isLoading, setIsLoading] = useState(true)
  const [activity, setActivity] = useState(null)
  const [hasError, setHasError] = useState(false)

  const { isUserReviewer, isUserProgramEditor } =
    useEditorDashboardPermissions()

  const [tabs, setTabs] = useState(
    isUserReviewer || isUserProgramEditor
      ? [
          {
            name: t('activity_tab_title'),
            key: 'json'
          }
        ]
      : [
          {
            name: t('activity_tab_title'),
            key: 'json'
          },
          {
            name: t('metadata_tab_title'),
            key: 'metadata'
          },
          {
            name: t('program_tab_title'),
            key: 'programs'
          }
        ]
  )
  const [currentTab, setCurrentTab] = useState('json')

  const { activityId } = useParams()

  const [activityCode, setActivityCode] = useState(null)
  const [activityCodeApp, setActivityCodeApp] = useState(null)
  const [activityMetadata, setActivityMetadata] = useState(null)
  const [hasChanges, setHasChanges] = useState(false)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' })
  const [showAlert, setShowAlert] = useState(false)
  const [alertOrigin, setAlertOrigin] = useState('backButton')
  const [alertTabDestination, setAlertTabDestination] = useState()
  const [activityPrograms, setActivityPrograms] = useState([])

  const programsTableColumns = [
    columnHelper.accessor('title', {
      header: t('title'),
      id: 'title',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellWithOneLineOfText text={props.row.original.name} />
    }),
    columnHelper.accessor('lang', {
      header: t('language'),
      id: 'lang',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellWithOneLineOfText text={props.row.original.lang} />
    }),
    columnHelper.accessor('country', {
      header: t('country'),
      id: 'country',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.country} />
      )
    }),
    columnHelper.accessor('educationYear', {
      header: t('education_level'),
      id: 'educationYear',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.educationYear} />
      )
    }),
    columnHelper.accessor('tenant', {
      header: t('tenant'),
      id: 'tenant',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellTag tags={props.row.original.tenants} />
    })
  ]

  const onBackWithConfirmation = () => {
    setAlertOrigin('backButton')

    // WATCH FOR CHANGES ON BACK BUTTON
    if (!hasChanges) history.back()
    else setShowAlert(true)
  }

  const onAlertConfirmation = () => {
    // console.log('alertOrigin', alertOrigin);
    // console.log(document.referrer);
    if (alertOrigin === 'tab') setCurrentTab(alertTabDestination)
    else {
      let filtersUrl = localStorage.getItem('bb_filters_url')
      if (!filtersUrl) history.back()
      else {
        navigate(filtersUrl)
      }
    }

    setShowAlert(false)
  }

  const onAlertCancel = () => {
    setShowAlert(false)
  }

  const onChangeTab = (tab) => {
    setAlertOrigin('tab')
    setAlertTabDestination(tab.key)

    // WATCH FOR CHANGES ON CHANGE TAB
    if (!hasChanges) {
      setCurrentTab(tab.key)
    } else setShowAlert(true)
  }

  const getData = async () => {
    // console.log('getting data...');
    setIsLoading(true)
    let response = await fetchWithToken({
      method: 'GET',
      path: `/seeds/${activityId}`
    })
    setActivity({ ...response?.data, data: response?.data?.data })

    setActivityPrograms(
      response?.data?.loNodeSeeds
        ?.map((seed) =>
          seed?.node?.lessons?.map((lesson) => lesson?.unit?.program)
        )
        .flat(2)
        .filter((program) => program)
        .map((program) => ({
          ...program,
          title: program?.name,
          lang: t(program?.lang?.code),
          country: program?.country?.name,
          educationYear: program?.educationYear?.year,
          tenants: program?.tenants.map((tenant) => ({
            label: tenant?.name,
            id: tenant?.guid
          }))
        }))
        .filter(
          (item, index, self) =>
            index === self?.findIndex((obj) => obj.guid === item.guid)
        )
    )

    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const handleSaveMetadata = () => {
    onSaveActivityMetadata()
  }

  const handleSaveActivityJSON = () => {
    onSaveActivityJSON()
  }

  const onSaveButtonClick = {
    metadata: handleSaveMetadata,
    json: handleSaveActivityJSON
  }

  const savePhaseData = async (
    _activitySeedGuid,
    _activityNodeGuid,
    _activityPhase
  ) => {
    let saveLearningOutcomeDataOk = true

    try {
      const phaseResponse = await fetchWithToken({
        path: `nodes/${_activityNodeGuid}/seeds/${_activitySeedGuid}`,
        method: 'PATCH',
        data: {
          phase: _activityPhase
        }
      })

      if (phaseResponse.status !== 'success') saveLearningOutcomeDataOk = false
    } catch (patchPhaseError) {
      saveLearningOutcomeDataOk = false
      console.error(patchPhaseError)
    }

    return saveLearningOutcomeDataOk
  }

  const saveLearningOutcomeData = async (
    questionSeedGuid,
    questionPhase,
    learningOutcomes
  ) => {
    let saveLearningOutcomeDataOk = true

    // Primero borrar
    for (const lo in learningOutcomes) {
      if (learningOutcomes[lo]?.action === 'delete') {
        // Eliminar
        const loDeleteResponse = await fetchWithToken({
          path: `nodes/${learningOutcomes[lo].id}/seeds`,
          method: 'DELETE',
          data: { seedGuid: questionSeedGuid }
        })

        if (loDeleteResponse.status !== 'success') {
          saveLearningOutcomeDataOk = false
        }
      }
    }

    // Despues añadir
    if (saveLearningOutcomeDataOk) {
      for (const lo in learningOutcomes) {
        if (learningOutcomes[lo]?.action === 'add') {
          // Añadir
          const loPostResponse = await fetchWithToken({
            path: `nodes/${learningOutcomes[lo].id}/seeds`,
            method: 'POST',
            data: {
              seedGuid: questionSeedGuid,
              phase: questionPhase
            }
          })

          if (loPostResponse.status !== 'success') {
            saveLearningOutcomeDataOk = false
          }
        }
      }
    }

    return saveLearningOutcomeDataOk
  }

  const onSaveActivityMetadata = async () => {
    const questionSeedGuid = activity?.guid
    const activityMetadataPhase = activityMetadata.phase
    const activityLearningOutcomes = activityMetadata?.learningOutcomes
    const activityMetadataWithoutLO = { ...activityMetadata }
    delete activityMetadataWithoutLO.learningOutcomes

    // Si hay cambio de proceso (phase) Y de learningOutcome sólo se usan los endpoint de learningOutcome
    // Si SOLO cambia el learningOutcome igualmente se usan los endpoint de learningOutcome
    // Si SOLO cambia el proceso (phase) se usa el endpoint de proceso (phase)
    let hasChangedLearningOutcome = false
    for (const lo in activityLearningOutcomes) {
      const _action = activityLearningOutcomes[lo]?.action
      if (_action === 'delete' || _action === 'add') {
        hasChangedLearningOutcome = true
      }
    }

    let hasChangedPhase =
      activityMetadataPhase !== activity?.loNodeSeeds[0]?.phase

    let isLearningOutcomeOrPhaseSavedOk = true

    if (hasChangedPhase && !hasChangedLearningOutcome) {
      isLearningOutcomeOrPhaseSavedOk = await savePhaseData(
        questionSeedGuid,
        activity?.loNodeSeeds[0]?.node?.guid,
        activityMetadataPhase
      )
    } else if (hasChangedLearningOutcome) {
      isLearningOutcomeOrPhaseSavedOk = await saveLearningOutcomeData(
        questionSeedGuid,
        activityMetadataPhase,
        activityLearningOutcomes
      )
    }

    let metadataResponse = {}
    if (isLearningOutcomeOrPhaseSavedOk) {
      // Se guarda el resto de datos de la actividad (sin proceso (phase) y learningOutcome)
      metadataResponse = await fetchWithToken({
        path: `/seeds/${questionSeedGuid}`,
        method: 'PATCH',
        data: activityMetadata
      })
    }

    if (metadataResponse?.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_activity_saved_success')
      })

      setHasChanges(false)
      await getData()
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_saved_error')
      })
    }

    setShowToast(true)
  }

  const onSaveActivityJSON = async () => {
    const questionSeedGuid = activity.guid
    const response = await fetchWithToken({
      path: `/seeds/${questionSeedGuid}`,
      method: 'PATCH',
      data: {
        data:
          typeof activityCode === 'string'
            ? activityCode
            : JSON.stringify(activityCode),
        dataApp: typeof activityCodeApp
          ? activityCodeApp
          : JSON.stringify(activityCodeApp)
      }
    })
    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_activity_saved_success')
      })
      setHasChanges(false)
      await getData()
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_saved_error')
      })
    }
    setShowToast(true)
  }

  const onGenerateMobileJSON = () => {
    // console.log('onGenerateMobileJSON')
    let tempActivity = activityCode
    if (typeof activityCode === 'string') {
      tempActivity = JSON.parse(activityCode)
    }

    switch (tempActivity.algorithm?.template) {
      // SINGLE CHOICE
      // MULTIPLE CHOICE
      // TRUE OR FALSE
      case 'Multiple choice – standard':
      case 'Multiple choice – multiple responses':
        tempActivity.algorithm.params = {
          ...tempActivity.algorithm.params,
          columns: 1,
          showCheckIcon: false
        }
        break

      default:
        break
    }

    switch (tempActivity.algorithm?.name) {
      // PATHWAY
      // REVERSE PATHWAY
      case 'pathway':
        tempActivity.algorithm.params = {
          ...tempActivity.algorithm.params,
          columns: 4,
          rows: 5
        }
        break

      // LINECHART OUTPUT
      // PICTOGRAPH OUTPUT
      case 'linechart':
      case 'pictograph':
        tempActivity.seed.parameters = tempActivity.seed.parameters
          .slice(0, 3)
          .map((param) => ({
            ...param,
            max: 6
          }))
        break

      // PIECHART OUTPUT
      case 'piechart':
        tempActivity.seed.parameters = tempActivity.seed.parameters.slice(0, 3)
        break

      // BAR CHART OUTPUT
      // DOBLE BAR CHART OUTPUT
      case 'barchart':
        if (tempActivity.algorithm.params.labelsX.length === 1) {
          tempActivity.seed.parameters = tempActivity.seed.parameters
            .slice(0, 3)
            .map((param) => ({
              ...param,
              max: 6
            }))
          tempActivity.algorithm.params = {
            ...tempActivity.algorithm.params,
            maxY: 6
          }
        } else {
          tempActivity.seed.parameters = tempActivity.seed.parameters
            .slice(0, 6)
            .map((param) => ({
              ...param,
              max: 4
            }))
          tempActivity.algorithm.params = {
            ...tempActivity.algorithm.params,
            maxY: 4
          }
        }
        break

      // NUMBER LINE OUTPUT
      // NUMBER LINE FRACTION OUTPUT
      case 'numberline':
        tempActivity.algorithm.params = {
          ...tempActivity.algorithm.params,
          divisions: 11,
          numbers: 1
        }
        break

      // COORDINATE AXES
      case 'coordinateaxes':
        tempActivity.seed.parameters = tempActivity.seed.parameters
          .slice(0, 5)
          .map((param) => ({
            ...param,
            max: 5
          }))
        break

      // COUNTING COUNT
      case 'counting':
        if (tempActivity.algorithm.params.operation === 'count') {
          tempActivity.seed.parameters = tempActivity.seed.parameters.map(
            (param) => ({
              ...param,
              min: 2,
              max: 12
            })
          )
        }
        break

      // FRACTIONS OUTPUT
      case 'fraction':
        tempActivity.seed.parameters = tempActivity.seed.parameters.map(
          (param) =>
            param.label === 'Divisions'
              ? {
                  ...param,
                  max: 8
                }
              : param
        )
        break

      default:
        break
    }

    return tempActivity
  }

  return {
    isUserReviewer,
    isUserProgramEditor,
    isLoading,
    activity,
    hasError,
    tabs,
    currentTab,
    onSaveButtonClick,
    hasChanges,
    showToast,
    toastMessage,
    activityMetadata,
    activityCode,
    activityCodeApp,
    showAlert,
    setHasChanges,
    setHasError,
    setShowToast,
    setToastMessage,
    onChangeTab,
    setActivityMetadata,
    setActivityCode,
    setActivityCodeApp,
    onBackWithConfirmation,
    setShowAlert,
    onAlertConfirmation,
    onAlertCancel,
    programsTableColumns,
    activityPrograms,
    onGenerateMobileJSON
  }
}

export default useSingleActivity
