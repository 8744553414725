import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDLemonadeTemplateProgramFilter = ({
  defaultValue,
  showLabel,
  multiple,
  required,
  disabled,
  onChange,
  isFilter = false
}) => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const getData = async (searchValue) => {
    setIsLoading(true);

    let templatesResponse = await fetchWithToken({
      method: 'GET',
      path: `/seeds/templates`
    });

    setTemplates(
      templatesResponse.data?.map((template) => ({
        value: template.seedTemplate,
        label: template.seedTemplate,
        id: template.seedTemplate
      }))
    );

    setTotal(templatesResponse.data.count);
    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce((value) => {
      getData(value);
    }, 500),
    []
  );

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  useEffect(() => {
    setTemplates([]);
    setCurrentPage(0);
    debouncedFetchOptions(inputValue);
  }, [inputValue]);

  return (
    <EDAutocompleteWithLabel
      limitTags={2}
      label='JSON Template'
      showLabel={showLabel}
      multiple={multiple}
      required={required}
      loading={isLoading}
      disabled={disabled}
      loadingText={t('loading_text')}
      noOptionsText={t('no_options_text')}
      value={
        isFilter ? defaultValue : defaultValue?.length ? defaultValue[0] : []
      }
      options={templates}
      ListboxProps={{
        onScroll: (event) => {
          if (
            event.target.scrollTop + event.target.clientHeight ===
            event.target.scrollHeight
          ) {
            if (currentPage * pageSize <= total) {
              setCurrentPage(currentPage + 1);
            }
          }
        }
      }}
      getOptionLabel={(option) => option.label || ''}
      onInputChange={handleInputChange}
      onChange={(event, value) => {
        if (multiple) {
          onChange(value);
        } else {
          onChange(value ? [value] : []);
        }
      }}
      renderInput={(params) => (
        <EDInput {...params} placeholder={t('select_placeholder')} />
      )}
    />
  );
};

export default EDLemonadeTemplateProgramFilter;
