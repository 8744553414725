import { Autocomplete, styled } from '@mui/material';
import { CustomArrow } from '../CustomArrow/CustomArrow';

const EDAutocomplete = styled((props) => (
  <Autocomplete popupIcon={<CustomArrow />} {...props} />
))(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    '& .MuiAutocomplete-inputRoot': {
      '& .MuiAutocomplete-input': {
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        fontSize: '0.875rem',
        lineHeight: 1.4375,
        color: theme.palette.grey[700]
      }
    },
    '& .MuiAutocomplete-clearIndicator': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.grey[500]
      }
    },
    '& .MuiAutocomplete-popupIndicator': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.grey[500]
      }
    },

    '& .MuiAutocomplete-option': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.primary.main
        }
      }
    },
    '& .MuiAutocomplete-noOptions': {
      '& .MuiTypography-root': {
        color: theme.palette.grey[500]
      }
    }
  },
  '& .MuiAutocomplete-paper': {
    backgroundColor: 'blue !important',
    borderRadius: 16,
    border: `1px solid #dfe5ec`,

    '& .MuiAutocomplete-listbox': {
      backgroundColor: 'red !important'
    }
  },
  '& .MuiAutocomplete-popupIndicator': {
    height: '28px',
    width: '28px'
  }
}));

export default EDAutocomplete;
