import { Skeleton } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel'
import EDInput from '../../atoms/EDInput/EDInput'
import { fetchWithToken } from '../../utils/helpers/fetcher'
import { generateEducationYear } from '../../utils/helpers/generateEducationYear'

const EDEducationLevelProgramFilter = ({
  defaultValue,
  showLabel,
  multiple,
  label,
  required,
  disabled,
  onChange,
  isLoading: isInitialLoad,
  isFilter = false
}) => {
  const { t } = useTranslation()

  // ONLY FOR PRIMARY LEVEL
  const EDUCATION_LEVEL = '' // 'nivel-1' queremos que retorne todos los niveles

  const [isLoading, setIsLoading] = useState(false)
  const [offset, setOffset] = useState(-1)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [educationLevelData, setEducationLevelData] = useState([])
  const [inputValue, setInputValue] = useState('')

  const getData = async (searchValue) => {
    setIsLoading(true)

    let responseYears = await fetchWithToken({
      method: 'GET',
      path: `/educations-years?pageSize=100&offset=${
        offset + 1
      }&educationLevelGuid=${EDUCATION_LEVEL}${
        searchValue ? `&name=${searchValue}` : ''
      }`
    })

    setEducationLevelData([
      ...educationLevelData,
      ...responseYears.data.educationYears
        .filter(
          (item) =>
            item?.educationLevel?.guid === 'nivel-2' ||
            (item?.educationLevel?.guid === 'nivel-1' && item?.year !== '7')
        )
        .map((educationYear) => ({
          label: generateEducationYear(educationYear, t),
          year: educationYear.year,
          id: educationYear.guid
        }))
    ])

    setTotal(responseYears.data.count)
    setOffset(responseYears.data.offset)
    setIsLoading(false)
  }

  const debouncedFetchOptions = useCallback(
    debounce((value) => {
      getData(value)
    }, 500),
    []
  )

  const handleInputChange = (event, value) => {
    setInputValue(value)
  }

  useEffect(() => {
    setEducationLevelData([])
    setCurrentPage(0)
    debouncedFetchOptions(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (currentPage > 0) getData()
  }, [currentPage])

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          multiple={multiple}
          limitTags={2}
          showLabel={showLabel}
          required={required}
          label={label || t('education_level_label')}
          loading={isLoading}
          disabled={disabled}
          loadingText={t('loading_text')}
          noOptionsText={t('no_options_text')}
          value={
            isFilter
              ? defaultValue
              : defaultValue?.length
              ? defaultValue[0]
              : []
          }
          options={educationLevelData}
          ListboxProps={{
            onScroll: (event) => {
              if (
                event.target.scrollTop + event.target.clientHeight ===
                event.target.scrollHeight
              ) {
                if (currentPage * pageSize <= total) {
                  setCurrentPage(currentPage + 1)
                }
              }
            }
          }}
          getOptionLabel={(option) => option.label || ''}
          onInputChange={handleInputChange}
          onChange={(event, value) => {
            if (multiple) {
              onChange(value)
            } else {
              onChange(value ? [value] : [])
            }
          }}
          renderInput={(params) => (
            <EDInput
              {...params}
              placeholder={t('education_level_placeholder')}
            />
          )}
        />
      )}
    </>
  )
}

export default EDEducationLevelProgramFilter
