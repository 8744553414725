import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import { generateEducationYear } from '../../../utils/helpers/generateEducationYear';
import { lastUpdate } from '../../../utils/helpers/lastUpdate';
import useAuth from '../../../utils/hooks/useAuth';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';
import useUserSettings from '../../../utils/hooks/useUserSettings';

const useEditProgram = () => {
  const { user } = useAuth();
  const { isUserProgramEditor } = useEditorDashboardPermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('metadata');

  const [tenants, setTenants] = useState([]);
  const [program, setProgram] = useState(null);
  const [lastUpdateString, setLastUpdateString] = useState(null);
  const [showSaving, setShowSaving] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', message: '' });
  const [educationYears, setEducationYears] = useState([]);
  const [phasers, setPhasers] = useState([]);
  const [programErrors, setProgramErrors] = useState(null);

  const [programDataToSave, setProgramDataToSave] = useState({});

  const { programGuid } = useParams();

  const [programPreviewLanguage, setProgramPreviewLanguage] = useState(null);
  const { getPreviewLanguageSettings, setPreviewLanguageSettings } =
    useUserSettings();

  const { t } = useTranslation();

  const getEducationYears = async () => {
    let response = await fetchWithToken({
      path: '/educations-years?pageSize=25&offset=0'
    });

    // Show only Primaria and Secundaria
    const filteredEducationYears = response?.data?.educationYears?.filter(
      (educationYear) =>
        educationYear?.educationLevel?.guid === 'nivel-2' ||
        (educationYear?.educationLevel?.guid === 'nivel-1' &&
          educationYear?.year !== '7')
    );

    setEducationYears(
      filteredEducationYears?.map((educationYear) => ({
        ...educationYear,
        name: generateEducationYear(educationYear, t)
      }))
    );
  };

  const getTenants = async () => {
    let response = await fetchWithToken({
      path: '/tenants?pageSize=25&offset=0'
    });
    setTenants(response.data.tenants);
  };

  const getPhasers = async () => {
    let response = await fetchWithToken({
      path: '/phasers/master-templates'
    });

    setPhasers(response.data);
  };

  const getProgram = async () => {
    let response = await fetchWithToken({
      path: `/programs/${programGuid}?langCode=${getPreviewLanguageSettings()}`
    });

    if (response.data.error) {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: 'Unexpected error occurred with finding the program'
      });
    }

    const tempUnits = response.data?.units || [];

    setProgram({
      ...response.data,
      units: tempUnits
        .sort((a, b) => a.order - b.order)
        .map((unit, unitIndex) => ({
          ...unit,
          order: unitIndex + 1, // update order locally to skip deleted units
          lessons: (unit?.lessons || [])
            .sort((a, b) => a.order - b.order)
            .map((lesson, lessonIndex) => ({
              ...lesson,
              nodes: (lesson?.nodes || [])
                .filter((node) => node.deletedAt === null)
                .map((node) => {
                  const descriptionLangObj =
                    node.descriptionLanguages &&
                    node.descriptionLanguages.length > 0
                      ? node.descriptionLanguages[0]
                      : { description: node.description };
                  return {
                    ...node,
                    descriptionLang: descriptionLangObj
                      ? descriptionLangObj.description
                      : ''
                  };
                }),
              order: lessonIndex + 1 // update order locally to skip deleted lessons
            }))
        }))
    });
  };

  const getProgramErrors = async () => {
    let _programErrors = {
      nodesWithoutSeeds: [],
      nodesWithoutKC: [],
      lessonsWithDependencyProblems: [],
      nodesWithErrors: [],
      lessonsWithoutLOs: []
    };

    let validationResponse = await fetchWithToken({
      path: `/programs/${programGuid}/validations`
    });

    const nodesWithoutSeedsList =
      validationResponse?.data?.nodesWithoutSeeds?.units?.flatMap((unit) =>
        unit.lessons.flatMap((lesson) => lesson.nodes.map((node) => node.guid))
      );

    const nodesWithoutKCList =
      validationResponse?.data?.nodesWithoutKnoledgeComponent?.units?.flatMap(
        (unit) =>
          unit.lessons.flatMap((lesson) =>
            lesson.nodes.map((node) => node.guid)
          )
      );

    const lessonsWithDependencyProblemsList =
      validationResponse?.data?.nodesWithDependencyProblems
        ?.map((item) => item.lesson.guid)
        .filter((value, index, self) => self.indexOf(value) === index);

    const nodesWithDependencyProblemsList =
      validationResponse?.data?.nodesWithDependencyProblems?.map(
        (item) => item.lo.guid
      );

    _programErrors.nodesWithoutSeeds = nodesWithoutSeedsList || [];

    _programErrors.nodesWithoutKC = nodesWithoutKCList || [];

    _programErrors.lessonsWithDependencyProblems =
      lessonsWithDependencyProblemsList || [];

    _programErrors.nodesWithDependencyProblems =
      nodesWithDependencyProblemsList || [];

    _programErrors.dependencyProblemsData =
      validationResponse?.data?.nodesWithDependencyProblems || [];

    // Comprobamos si hay lecciones sin LOs
    let errorLessonWithoutLOs = [];

    program?.units.forEach((item) => {
      let lessonsGuids = item.lessons
        .filter((lesson) => lesson.nodes.length === 0)
        .map((lesson) => lesson.guid);
      errorLessonWithoutLOs = [...errorLessonWithoutLOs, ...lessonsGuids];
    });

    _programErrors.lessonsWithoutLOs = errorLessonWithoutLOs;

    _programErrors.nodesWithErrors = _programErrors.nodesWithoutSeeds
      .concat(_programErrors.nodesWithoutKC)
      .concat(_programErrors.nodesWithDependencyProblems)
      .concat(_programErrors.lessonsWithoutLOs);

    setProgramErrors(_programErrors);
  };

  const onCreateUnit = async ({ name, description, languages }) => {
    let response = await fetchWithToken({
      path: '/units',
      method: 'POST',
      data: {
        program: programGuid,
        name: name,
        description: description,
        languages: languages,
        phaserTemplateGuid: phasers[0]?.guid
      }
    });

    if (response.data.error) {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_content_create_unit_error')
      });
    } else {
      setShowSaving(true);
      getProgram();
    }
  };

  const onCreateLesson = async ({ name, unitGuid, languages }) => {
    let response = await fetchWithToken({
      path: '/lessons',
      method: 'POST',
      data: {
        program: programGuid,
        name: name,
        description: name,
        languages: languages,
        unit: unitGuid,
        nodes: []
      }
    });

    if (response.data.error) {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_content_create_lesson_error')
      });
    } else {
      setShowSaving(true);
      getProgram();
    }
  };

  const onEditUnit = async ({ name, description, languages, unitGuid }) => {
    let response = await fetchWithToken({
      path: `/units/${unitGuid}`,
      method: 'PATCH',
      data: {
        name,
        description,
        languages
      }
    });

    if (response.status === 'success') {
      setShowToast(true);
      setToastMessage({
        type: 'success',
        message: t('program_content_edit_unit_success')
      });
      getProgram();
      setShowSaving(true);
    } else {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_content_edit_unit_error')
      });
    }
  };

  const onEditLesson = async ({ name, description, languages, lessonGuid }) => {
    let response = await fetchWithToken({
      path: `/lessons/${lessonGuid}`,
      method: 'PATCH',
      data: {
        name,
        description,
        languages
      }
    });

    if (response.status === 'success') {
      setShowToast(true);
      setToastMessage({
        type: 'success',
        message: t('program_content_edit_lesson_success')
      });
      setShowSaving(true);
      getProgram();
    } else {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_content_edit_lesson_error')
      });
    }
  };

  const onReorderUnit = async ({ unitGuids }) => {
    await fetchWithToken({
      path: `/units/reorder`,
      method: 'PUT',
      data: {
        program: programGuid,
        guids: unitGuids
      }
    });
    setShowSaving(true);
    getProgram();
  };

  const onReorderLesson = async ({ lessonGuids, unitGuid }) => {
    await fetchWithToken({
      path: `/lessons/reorder`,
      method: 'PUT',
      data: {
        program: programGuid,
        unit: unitGuid,
        guids: lessonGuids
      }
    });
    setShowSaving(true);
    getProgram();
  };

  const onDeleteUnit = async ({ unitGuid }) => {
    let response = await fetchWithToken({
      path: `/units`,
      method: 'DELETE',
      data: {
        program: programGuid,
        guids: [unitGuid]
      }
    });

    if (response.status === 'success') {
      setShowToast(true);
      setToastMessage({
        type: 'success',
        message: t('program_content_delete_unit_success')
      });
      setShowSaving(true);
      getProgram();
    } else {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_content_delete_unit_error')
      });
    }
  };

  const onDeleteLesson = async ({ unitGuid, lessonGuid }) => {
    let response = await fetchWithToken({
      path: `/lessons`,
      method: 'DELETE',
      data: {
        guids: [lessonGuid]
      }
    });

    if (response.status === 'success') {
      setShowToast(true);
      setToastMessage({
        type: 'success',
        message: t('program_content_delete_lesson_success')
      });
      setShowSaving(true);
      getProgram();
    } else {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_content_delete_lesson_error')
      });
    }
  };

  const onAddLOsToLesson = async ({ lessonGuid, nodes }) => {
    await fetchWithToken({
      path: `/lessons/nodes`,
      method: 'POST',
      data: {
        program: programGuid,
        lesson: lessonGuid,
        guids: nodes
      }
    });
    setShowSaving(true);
    getProgram();
  };

  const onRemoveLOsFromLesson = async ({ lessonGuid, nodes }) => {
    await fetchWithToken({
      path: `/lessons/nodes`,
      method: 'DELETE',
      data: {
        program: programGuid,
        lesson: lessonGuid,
        guids: nodes
      }
    });
    setShowSaving(true);
    getProgram();
  };

  const onSaveProgram = async () => {
    let response = await fetchWithToken({
      path: `/programs/${programGuid}`,
      method: 'PATCH',
      data: programDataToSave
    });

    if (response.status === 'success') {
      setShowToast(true);
      setToastMessage({
        type: 'success',
        message: t('program_content_save_program_success')
      });
      getProgram();
    } else {
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_content_save_program_error')
      });
    }
  };

  const onPublishProgram = async () => {
    let response = await fetchWithToken({
      path: `/programs/${programGuid}/syncs-tenant`,
      method: 'POST'
    });

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        message: t('program_content_publish_program_success')
      });
      setShowToast(true);
      getProgram();
    } else {
      if (response.data.message === 'Program is publishing')
        setToastMessage({
          type: 'info',
          message: t('program_content_publish_program_soon')
        });
      else {
        setToastMessage({
          type: 'error',
          message: t('program_content_publish_program_error')
        });
      }
      setShowToast(true);
    }
  };

  // Language preview
  const onProgramPreviewLanguageChange = (event, newLanguage) => {
    setProgramPreviewLanguage(newLanguage);
  };

  // Permissions management
  const isProgramOwner = (program) => {
    return program?.createdBy?.guid === user?.guid;
  };

  const canEditProgram = () => {
    return !isUserProgramEditor || isProgramOwner(program);
  };

  useEffect(() => {
    // Get user configuration
    if (!programPreviewLanguage)
      setProgramPreviewLanguage(getPreviewLanguageSettings());

    getInitialProgramData();
    setCurrentTab('content');
  }, []);

  const getInitialProgramData = async () => {
    // Get data
    await getEducationYears();
    await getTenants();
    await getPhasers();
    await getProgram();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!program) return;
    getLastUpdateDate();
    getProgramErrors();
  }, [program]);

  useEffect(() => {
    if (!programPreviewLanguage) return;

    if (getPreviewLanguageSettings() !== programPreviewLanguage) {
      setPreviewLanguageSettings(programPreviewLanguage);
      getProgram();
    }
  }, [programPreviewLanguage]);

  const getLastUpdateDate = () => {
    const updatedString = lastUpdate(
      program?.updatedAt,
      (program?.updatedBy || program?.createdBy)?.name || t('no_data'),
      t
    );

    const publishedString = program?.synchronizedAt
      ? lastUpdate(
          program?.synchronizedAt,
          program?.synchronizedBy?.name || t('no_data'),
          t
        )
      : null;

    const formattedUpdatedString =
      t('programs_table_updated') +
      ' ' +
      updatedString.charAt(0).toLowerCase() +
      updatedString.slice(1);

    const formattedPublishedString = publishedString
      ? t('programs_table_published') +
        ' ' +
        publishedString.charAt(0).toLowerCase() +
        publishedString.slice(1)
      : t('program_editor_not_published');

    if (program?.status === 'DRAFT')
      setLastUpdateString(formattedUpdatedString);
    else
      setLastUpdateString(
        formattedUpdatedString + ' - ' + formattedPublishedString
      );
  };

  const onChangeTab = (newTab) => {
    setCurrentTab(newTab.key);
  };

  const onUpdateProgram = (data) => {
    setProgramDataToSave({
      ...programDataToSave,
      ...data
    });
  };

  return {
    program,
    tenants,
    phasers,
    showToast,
    isLoading,
    showSaving,
    currentTab,
    programGuid,
    toastMessage,
    programErrors,
    educationYears,
    lastUpdateString,
    isUserProgramEditor,
    programPreviewLanguage,
    t,
    onEditUnit,
    onChangeTab,
    onCreateUnit,
    setShowToast,
    onEditLesson,
    onDeleteUnit,
    onReorderUnit,
    setShowSaving,
    onSaveProgram,
    onDeleteLesson,
    canEditProgram,
    onCreateLesson,
    onUpdateProgram,
    onReorderLesson,
    onPublishProgram,
    onAddLOsToLesson,
    onRemoveLOsFromLesson,
    onProgramPreviewLanguageChange
  };
};

export default useEditProgram;
