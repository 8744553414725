import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { checkIfValidEmail } from '../../../../utils/helpers/checkIfValidEmail';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';

const useEditUser = () => {
  const { userGuid } = useParams();
  const { t } = useTranslation();

  const [user, setUser] = useState(null);
  const [currentUserSchool, setCurrentUserSchool] = useState(null);
  const [isFirsTimeGetGroups, setIsFirsTimeGetGroups] = useState(true);
  const [schools, setSchools] = useState([]);
  const [groups, setGroups] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' });
  const [isLoginAsUserLoading, setIsLoginAsUserLoading] = useState(false);
  const [isEditLicenseDialogOpen, setIsEditLicenseDialogOpen] = useState(false);
  const [isEditingLicense, setIsEditingLicense] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [originalListOfGroupsForUser, setOriginalListOfGroupsForUser] =
    useState([]);

  const tenantToStudentMap = {
    'localhost:3002': 'https://school.blueberry-dev.oneclicklabs.es/',
    'backoffice.school.blueberry-qa.oneclicklabs.es':
      'https://school.blueberry-qa.oneclicklabs.es/',
    'tenants-backoffice.tangerine-dev.oneclicklabs.es':
      'https://school.blueberry-dev.oneclicklabs.es/',
    'backoffice.school.blueberrymath.ai': 'https://school.blueberrymath.ai/',
    'backoffice.escola.blueberrymath.ai': 'https://escola.blueberrymath.ai/',
    'backoffice.escuela.blueberrymath.ai': 'https://escuela.blueberrymath.ai/',
    'backoffice.school.blueberrymath.com': 'https://school.blueberrymath.com/',
    'backoffice.escola.blueberrymath.com': 'https://escola.blueberrymath.com/',
    'backoffice.escuela.blueberrymath.com':
      'https://escuela.blueberrymath.com/',
    'backoffice.layers.blueberry.app.br': 'https://layers.blueberry.app.br/'
  };

  const tenantToTeacherMap = {
    'localhost:3002': 'https://dashboard.school.blueberry-dev.oneclicklabs.es/',
    'backoffice.school.blueberry-qa.oneclicklabs.es':
      'https://dashboard.school.blueberry-qa.oneclicklabs.es/',
    'tenants-backoffice.tangerine-dev.oneclicklabs.es':
      'https://dashboard.school.blueberry-dev.oneclicklabs.es/',
    'backoffice.school.blueberrymath.ai':
      'https://dashboard.school.blueberrymath.ai/',
    'backoffice.escola.blueberrymath.ai':
      'https://dashboard.escola.blueberrymath.ai/',
    'backoffice.escuela.blueberrymath.ai':
      'https://dashboard.escuela.blueberrymath.ai/',
    'backoffice.school.blueberrymath.com':
      'https://dashboard.school.blueberrymath.com/',
    'backoffice.escola.blueberrymath.com':
      'https://dashboard.escola.blueberrymath.com/',
    'backoffice.escuela.blueberrymath.com':
      'https://dashboard.escuela.blueberrymath.com/',
    'backoffice.layers.blueberry.app.br':
      'https://dashboard.layers.blueberry.app.br/'
  };

  const getUser = async () => {
    let response = await fetchWithToken({
      path: `/users/${userGuid}?include[]=groups`
    });

    const auxUserSchool =
      response.data.schools && response.data.schools.length > 0
        ? {
            label: response.data.schools[0]?.name,
            value: response.data.schools[0]?.guid
          }
        : null;

    const userGroups =
      (response.data.groups &&
        response.data.groups.map((group) => ({
          label: group.name,
          value: group.guid
        }))) ||
      [];

    const user = {
      ...response.data,
      userSchool: auxUserSchool,
      userGroups: userGroups
    };

    return {
      user,
      userSchool: auxUserSchool,
      userGroups: userGroups
    };
  };

  const getData = async () => {
    setIsLoading(true);

    const { user, userSchool, userGroups } = await getUser();

    setUser(user);
    setCurrentUserSchool(userSchool);
    setOriginalListOfGroupsForUser(userGroups);

    // using lite schools to get a faster response
    // could be searched in the future by adding ?name=schoolName param
    let schoolsResponse = await fetchWithToken({
      path: `/schools?lite=true&pageSize=100&offset=0`
    });

    setSchools(
      schoolsResponse.data.schools.map((school) => ({
        label: school.name,
        value: school.guid
      }))
    );

    await getLicenses();

    setIsLoading(false);
  };

  const getLicenses = async () => {
    let licensesResponse = await fetchWithToken({
      path: `/users/${userGuid}/license-personals`
    });

    setLicenses(licensesResponse.data);
  };

  const onChangeTab = (tab) => {
    setCurrentTab(tab.key);
  };

  const onCreateNewContract = () => {
    console.log('create new contract');
  };

  const onCreateNewGroup = () => {
    console.log('create new contract');
  };

  const onUpdateUser = (data) => {
    setUser({ ...user, ...data });

    const onlyGroupsOrSchoolUpdate =
      Object.keys(data).filter(
        (key) => key !== 'userGroups' && key !== 'userSchool'
      ).length === 0;

    let newFieldsToUpdate = {
      // name is required to update the user and should be updated if the user is not being updated only in groups or school
      ...(!onlyGroupsOrSchoolUpdate ? { name: user?.name } : {}),
      ...fieldsToUpdate,
      ...data
    };
    setFieldsToUpdate(newFieldsToUpdate);
  };

  const onSaveUser = async () => {
    // Teachers must have an email as a username. Students can have any username.
    if (user.role_guid === 'R02') {
      if (!checkIfValidEmail(user.username)) {
        setToastMessage({
          type: 'error',
          text: t('teacher_username_must_be_email')
        });
        setShowToast(true);
        return;
      }
    }

    // Proceed with the saving process after the checks
    setIsSaving(true);

    try {
      let response = null;
      if (
        Object.keys(fieldsToUpdate).filter(
          (key) => key !== 'userGroups' && key !== 'userSchool'
        ).length > 0
      ) {
        delete fieldsToUpdate.role;
        delete fieldsToUpdate.userSchool;
        delete fieldsToUpdate.userGroups;
        response = await fetchWithToken({
          path: `/users/${userGuid}`,
          method: 'PUT',
          data: fieldsToUpdate
        });

        if (response.status !== 'success') {
          setToastMessage({
            type: 'error',
            text: 'An error has occurred.'
          });
          setIsSaving(false);
          setShowToast(true);
          return;
        }
      }

      if (user.userSchool && user.userSchool.value !== user.schools[0]?.guid) {
        // remove user from all previous schools
        await Promise.all(
          user.schools.map(async (school) => {
            const deleteUserResponse = await fetchWithToken({
              path: `/schools/${school.guid}/users`,
              method: 'DELETE',
              data: {
                guid: [user.guid]
              }
            });

            if (deleteUserResponse.status !== 'success') {
              setToastMessage({
                type: 'error',
                text: 'An error has occurred.'
              });
              setIsSaving(false);
              setShowToast(true);
              return;
            }

            return deleteUserResponse;
          })
        );

        response = await fetchWithToken({
          path: `/users/${userGuid}/schools`,
          method: 'POST',
          data: {
            guid: [user.userSchool.value]
          }
        });

        if (response.status !== 'success') {
          setToastMessage({
            type: 'error',
            text: 'An error has occurred.'
          });
          setIsSaving(false);
          setShowToast(true);
          return;
        }
      }

      // check if there are groups to delete and delete them. We do this by comparing what are the IDs that exist in original but not in the new list

      const groupsToDelete = originalListOfGroupsForUser.filter(
        (group) =>
          !user.userGroups.some((newGroup) => newGroup.value === group.value)
      );

      const groupsToAdd = user.userGroups.filter(
        (group) =>
          !originalListOfGroupsForUser.some(
            (originalGroup) => originalGroup.value === group.value
          )
      );

      if (groupsToDelete.length > 0) {
        await Promise.all(
          groupsToDelete.map(async (group) => {
            response = await fetchWithToken({
              path: `/front/school-admin/${user.userSchool.value}/groups/${group.value}/users`,
              method: 'DELETE',
              data: {
                guid: [user.guid],
                removeUserAccounts: false
              }
            });

            if (response.status !== 'success') {
              setToastMessage({
                type: 'error',
                text: 'An error has occurred.'
              });
              setIsSaving(false);
              setShowToast(true);
              return;
            }
          })
        );
      }

      if (groupsToAdd.length > 0) {
        await Promise.all(
          groupsToAdd.map(async (group) => {
            response = await fetchWithToken({
              path: `/front/school-admin/${user.userSchool.value}/groups/${group.value}/users`,
              method: 'POST',
              data: {
                guid: [userGuid]
              }
            });

            if (response.status !== 'success') {
              setToastMessage({
                type: 'error',
                text: 'An error has occurred.'
              });
              setIsSaving(false);
              setShowToast(true);
              return;
            }
          })
        );
      }

      const { user: refreshedUser, userSchool, userGroups } = await getUser();

      setUser(refreshedUser);
      setCurrentUserSchool(userSchool);
      setOriginalListOfGroupsForUser(userGroups);
      setFieldsToUpdate({});

      setToastMessage({
        type: 'success',
        text: t('user_updated_successfully')
      });

      setIsSaving(false);
      setShowToast(true);
    } catch (error) {
      console.error('Error saving user:', error);
      // Handle the error, show an error message, etc.
      setToastMessage({
        type: 'error',
        text: 'An error occurred while saving the user.'
      });
      setIsSaving(false);
      setShowToast(true);
    }
  };

  const onUpdatePassword = async () => {
    setIsSaving(true);
    console.log('update password', newPassword, newConfirmPassword);

    if (newPassword !== newConfirmPassword) {
      setToastMessage({
        type: 'error',
        text: t('password_updated_error_passwords_do_not_match')
      });
      setShowToast(true);
      setIsSaving(false);
      return;
    }

    let response = await fetchWithToken({
      path: `/users/${userGuid}`,
      method: 'PUT',
      data: {
        name: user.name,
        password: newPassword
      }
    });

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('password_updated_successfully')
      });
    } else {
      setToastMessage({
        type: 'error',
        text: t('password_updated_error')
      });
    }

    setShowToast(true);
    setIsSaving(false);
  };

  const onLoginAsUser = async () => {
    setIsLoginAsUserLoading(true);

    let response = await fetchWithToken({
      method: 'POST',
      path: `/users/${userGuid}/login`,
      data: {}
    });

    if (response.status === 'success') {
      let userDomain;
      const loginEndpoint = 'login-qa';

      const isStudent = user.role_guid === 'R01';
      const isTeacher = user.role_guid === 'R02';

      if (isTeacher) {
        userDomain = tenantToTeacherMap[window.location.host];
      } else if (isStudent) {
        userDomain = tenantToStudentMap[window.location.host];
      }

      if (userDomain === undefined || userDomain === null) {
        setToastMessage({
          type: 'error',
          text: t('An error occurred while trying to login as the user.')
        });
        setShowToast(true);
        return;
      }

      window.open(
        `${userDomain}${loginEndpoint}?token=${response.data.token}`,
        '_blank'
      );

      setIsLoginAsUserLoading(false);
    }
  };

  const onEditLicense = (license) => {
    setIsEditLicenseDialogOpen(true);
  };

  const onCloseEditLicenseDialog = () => {
    setIsEditLicenseDialogOpen(false);
  };

  const onConfirmEditLicense = async (license) => {
    setIsEditingLicense(true);
    let response = await fetchWithToken({
      path: `/users/${userGuid}/redeem`,
      method: 'POST',
      data: {
        code: license,
        replace: true
      }
    });

    await getLicenses();

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('license_updated_successfully')
      });
      setShowToast(true);
      setIsEditLicenseDialogOpen(false);
    }

    setIsEditingLicense(false);
  };

  useEffect(() => {
    if (user && currentUserSchool) {
      const hasToGetGroups =
        user?.userSchool?.value !== currentUserSchool?.value ||
        isFirsTimeGetGroups;

      if (user && user.userSchool && hasToGetGroups && schools.length > 0) {
        getGroups(user.userSchool.value);
        setIsFirsTimeGetGroups(false);
      }
    }
  }, [user, schools]);

  useEffect(() => {
    if (fieldsToUpdate.userSchool) {
      getGroups(fieldsToUpdate.userSchool.value);
    }
  }, [fieldsToUpdate]);

  useEffect(() => {
    getData();
  }, []);

  const getGroups = async (schoolGuid) => {
    let groupsResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups`
    });

    const auxSchoolGroups = groupsResponse.data.schoolGroups.map((group) => ({
      label: group.name,
      value: group.guid
    }));

    // Si tenía grupos asignados quitarlos si no son del colegio actualmente seleccionado
    let auxUserGroups = [];
    if (user?.userGroups?.length && user.userGroups.length > 0) {
      auxUserGroups = user.userGroups.filter((userItem) =>
        auxSchoolGroups.some(
          (schoolItem) => schoolItem.value === userItem.value
        )
      );
    }

    setCurrentUserSchool(user.userSchool);
    setUser({ ...user, userGroups: auxUserGroups });
    setGroups(auxSchoolGroups);
  };

  const TABS = {
    details: { name: 'Details', key: 'details', action: onSaveUser },
    password: { name: 'Password', key: 'password', action: onUpdatePassword },
    licenses: { name: 'Licenses', key: 'licenses', action: () => {} }
  };

  return {
    TABS,
    user,
    groups,
    schools,
    licenses,
    isSaving,
    isLoading,
    showToast,
    currentTab,
    toastMessage,
    isEditingLicense,
    isLoginAsUserLoading,
    isEditLicenseDialogOpen,
    onSaveUser,
    onChangeTab,
    setShowToast,
    onUpdateUser,
    onLoginAsUser,
    onEditLicense,
    setNewPassword,
    onUpdatePassword,
    onCreateNewGroup,
    onConfirmEditLicense,
    onCreateNewContract,
    setNewConfirmPassword,
    onCloseEditLicenseDialog
  };
};

export default useEditUser;
