import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';

const ConfirmDeleteDialogV2 = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={600}>
              Delete class
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to delete this class? This action is
              irreversible.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
              <Button
                variant='text'
                onClick={onClose}
                sx={{
                  padding: '0.75rem 1.5rem',
                  textTransform: 'none'
                }}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                variant='contained'
                color='primary'
                onClick={onConfirm}
                sx={{
                  padding: '0.75rem 1.5rem',
                  textTransform: 'none'
                }}
              >
                Delete class
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDeleteDialogV2;
