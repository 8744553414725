import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDLearningObjectiveFilter = ({
  defaultValue,
  showLabel,
  multiple,
  required,
  disabled,
  onChange,
  isLoading: isInitialLoad,
  isFilter = false
}) => {
  const { t } = useTranslation();
  const pageSize = 1000;

  const [isLoading, setIsLoading] = useState(false);
  // const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getData = async (searchValue) => {
    setIsLoading(true);

    const responseNodes = await fetchWithToken({
      method: 'GET',
      path: `/nodes?pageSize=${pageSize}&type[]=outcome&offset=${
        currentPage * pageSize
      }${
        searchValue ? `&name=${searchValue}` : ''
      }&includeLinkFrom=0&includeLinkTo=0&includeSeeds=0&includeDepartments=0&includeUpdateByUserDetails=0&includeStandards=0`
    });

    const loList = {};
    const updatedData = [
      ...data,
      ...responseNodes.data.nodes.map((node) => {
        return {
          label: node.name,
          id: node.guid
        };
      })
    ].filter((item) => {
      const isAlreadyOnList = loList.hasOwnProperty(item.id);
      loList[item.id] = true;

      return !isAlreadyOnList;
    });

    setData(updatedData);
    setTotal(responseNodes.data.count);
    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce((value) => {
      getData(value);
    }, 500),
    []
  );

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  useEffect(() => {
    setData([]);
    setCurrentPage(0);
    debouncedFetchOptions(inputValue);
  }, [inputValue]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          limitTags={2}
          showLabel={showLabel}
          disabled={disabled}
          required={required}
          label={t('lo_label')}
          multiple={multiple}
          loading={isLoading}
          loadingText={t('loading_text')}
          noOptionsText={t('no_options_text')}
          defaultValue={
            isFilter
              ? defaultValue
              : defaultValue?.length
              ? defaultValue[0]
              : []
          }
          options={data}
          ListboxProps={{
            onScroll: (event) => {
              if (
                event.target.scrollTop + event.target.clientHeight ===
                event.target.scrollHeight
              ) {
                if (currentPage * pageSize <= total) {
                  setCurrentPage(currentPage + 1);
                }
              }
            }
          }}
          getOptionLabel={(option) => option.label || ''}
          onInputChange={handleInputChange}
          onChange={(event, value) => {
            if (multiple) {
              onChange(value);
            } else {
              onChange(value ? [value] : []);
            }
          }}
          renderInput={(params) => (
            <EDInput {...params} placeholder={t('select_placeholder')} />
          )}
        />
      )}
    </>
  );
};

export default EDLearningObjectiveFilter;
