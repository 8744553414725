import { SettingsOutlined } from '@mui/icons-material'
import { Button, CircularProgress, Popover } from '@mui/material'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EDInput from '../../atoms/EDInput/EDInput'
import SwitchWithLabel from '../../atoms/SwitchWithLabel/SwitchWithLabel'
import useUserSettings from '../../utils/hooks/useUserSettings'
import TablePagination from '../TablePagination/TablePagination'
import './Table.scss'
import TableBody from './body/TableBody'
import TableHeadGroup from './headerGroup/TableHeadGroup'

const Table = ({
  name,
  columns,
  data,
  onRowClick,
  isFirstColumnSticky,
  isLastColumnSticky,
  isLoading,
  className,
  showPagination,
  showTopPagination,
  paginationSize,
  totalPages,
  currentPage,
  onChangePage,
  options,
  isCustomizable,
  tableColumnsVisibility,
  useApiSorting,
  onChangeSort,
  customHighlightRow,
  onOptionsClick,
  showMessageIfNoData = false
}) => {
  // Use the state and functions returned from useReactTable to build your UI
  const [sorting, setSorting] = useState([])
  const [columnVisibility, setColumnVisibility] = useState({})
  const { t } = useTranslation()
  const { setTableSettings, getTableSettings, hasTableSettings } =
    useUserSettings()

  const cellTagSort = (rowA, rowB, columnId) => {
    const numA = rowA.getValue(columnId)[0].label
    const numB = rowB.getValue(columnId)[0].label
    return numA?.localeCompare(numB)
  }

  const cellWithTitleAndSubtitleSort = (rowA, rowB, columnId) => {
    const numA = rowA.getValue(columnId)[0].title
    const numB = rowB.getValue(columnId)[0].title
    return numA?.localeCompare(numB)
  }

  const cellWithLabelAndProgressBarSort = (rowA, rowB, columnId) => {
    const numA = rowA.getValue(columnId)
    const numB = rowB.getValue(columnId)
    return numA - numB
  }

  const cellWithStatusSort = (rowA, rowB, columnId) => {
    let order = [
      'no-data',
      'needMotivation',
      'forgetting',
      'inProgress',
      'mastered'
    ]
    const numA = rowA.getValue(columnId)
    const numB = rowB.getValue(columnId)

    return order.indexOf(numA) - order.indexOf(numB)
  }

  const cellWithOneLineOfTextLargeSort = (rowA, rowB, columnId) => {
    const valueOfRowA = (rowA?.original || {})[columnId]
    const valueOfRowB = (rowB?.original || {})[columnId]
    return valueOfRowA?.localeCompare(valueOfRowB)
  }

  const cellWithOneLineOfTextSort = cellWithOneLineOfTextLargeSort

  // Customize popover

  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const onCustomizeButton = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onCustomizeClose = () => {
    setAnchorEl(null)
  }

  // go to page

  const [goToPage, setGoToPage] = useState('')

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10)
    if (!isNaN(pageNumber) && pageNumber >= 0 && pageNumber <= totalPages) {
      onChangePage(null, pageNumber)
    }
    setGoToPage('')
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: useApiSorting ? undefined : getSortedRowModel(),
    manualSorting: useApiSorting,
    sortingFns: {
      cellTag: cellTagSort,
      cellWithTitleAndSubtitle: cellWithTitleAndSubtitleSort,
      cellWithLabelAndProgressBar: cellWithLabelAndProgressBarSort,
      cellWithStatus: cellWithStatusSort,
      cellWithOneLineOfTextLarge: cellWithOneLineOfTextLargeSort,
      cellWithOneLineOfText: cellWithOneLineOfTextSort
    },
    debugTable: true
  })

  useEffect(() => {
    // set default visibility
    if (isCustomizable && name && tableColumnsVisibility) {
      if (hasTableSettings(name)) {
        table.setColumnVisibility(getTableSettings(name))
      } else {
        table.setColumnVisibility(tableColumnsVisibility)
      }
    }
  }, [])

  useEffect(() => {
    // save table visibility to local config
    if (isCustomizable && name) {
      setTableSettings(name, table.getState().columnVisibility)
    }
  }, [table.getState().columnVisibility])

  useEffect(() => {
    if (useApiSorting && sorting && sorting.length > 0) {
      onChangeSort({
        orderBy: sorting[0].id,
        orderType: sorting[0].desc ? 'DESC' : 'ASC'
      })
    }
  }, [sorting, useApiSorting])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleGoToPage()
      // Aquí puedes poner la lógica que quieras ejecutar cuando se presione Enter
    }
  }

  return (
    <div className={`table__wrapper ${className}`}>
      {showTopPagination && (
        <div className='table__top-pagination'>
          <TablePagination
            count={totalPages}
            page={currentPage}
            onChange={onChangePage}
            shape='rounded'
            siblingCount={2}
          />
          <div className='table__top-pagination-input'>
            <EDInput
              value={goToPage}
              onChange={(event) => setGoToPage(event.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button
              variant='contained'
              disableElevation
              color='inherit'
              onClick={handleGoToPage}
            >
              {t('go')}
            </Button>
          </div>
        </div>
      )}
      <div
        className={`table__container ${
          isFirstColumnSticky && 'table__sticky-column'
        } ${isLastColumnSticky && 'table__sticky-column-last'}`}
      >
        <table>
          <TableHeadGroup
            headerGroups={table.getHeaderGroups()}
            options={options}
          />

          {isLoading && (
            <tbody>
              <tr className='table__loading'>
                <td
                  rowSpan={columns.length}
                  className='table__loading__spinner'
                >
                  <CircularProgress />
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && (
            <TableBody
              rows={table.getRowModel().rows}
              onRowClick={onRowClick}
              options={options}
              onOptionsClick={onOptionsClick}
              customHighlightRow={customHighlightRow}
            />
          )}
          {!isLoading &&
            table.getRowModel().rows.length === 0 &&
            showMessageIfNoData && (
              <tbody>
                <tr>
                  <td rowSpan={columns.length}>{t('no_data_available')}</td>
                </tr>
              </tbody>
            )}
        </table>
      </div>
      <div className='table__footer'>
        {showPagination && (
          <div className='table__pagination'>
            <TablePagination
              count={totalPages}
              page={currentPage}
              onChange={onChangePage}
              shape='rounded'
              siblingCount={paginationSize === 'large' ? 6 : 1}
            />
          </div>
        )}

        {isCustomizable && (
          <div className='table__customization'>
            <Button
              variant='contained'
              disableElevation
              color='inherit'
              className='table__customize-button'
              startIcon={<SettingsOutlined sx={{ color: '#8c99ad' }} />}
              onClick={onCustomizeButton}
            >
              {t('Customize')}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={onCustomizeClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              className='table__customization-popover'
            >
              <div className='table__customization-title'>
                {t('show_fields')}
              </div>
              <div className='table__customization-options'>
                {table.getAllLeafColumns().map((column) => {
                  return (
                    <div
                      key={column.id}
                      className='table__customization-options-item'
                    >
                      <SwitchWithLabel
                        checked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        label={column.columnDef.header}
                      />
                    </div>
                  )
                })}
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  )
}

Table.propTypes = {
  useApiSorting: PropTypes.bool,
  onChangeSort: PropTypes.func,
  customHighlightRow: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.any
  }),
  onOptionsClick: PropTypes.func,
  showMessageIfNoData: PropTypes.bool
}

export default Table
