import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchWithFilterButton from '../../../atoms/SearchWithFilterButton/SearchWithFilterButton';
import EDDashboardTabs from '../../../components/EDDashboardTabs/EDDashboardTabs';
import Table from '../../../components/table/Table';
import TableResultsText from '../../../components/tableResultsText/TableResultsText';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import ConfirmDeleteDialog from '../../tenantDashboard/schools/Edit/tabs/dialogs/ConfirmDeleteDialog';
import LearningObjectivesFilter from './components/LearningOutcomesFilter';
import NewLearningOutcomeDialog from './components/NewLearningOutcomeDialog';
import useLearningOutcomesManager from './useLearningOutcomesManager';

const LearningOutcomesManager = () => {
  const {
    isUserProgramEditor,
    data,
    columns,
    total,
    filters,
    search,
    numberOfFilters,
    isLoading,
    currentPage,
    totalPages,
    onChangeFilter,
    onChangeSearch,
    onChangePage,
    onCreateLearningObjective,
    onDeleteLearningObjective
  } = useLearningOutcomesManager();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isNewLearningOutcomeDialogOpen, setIsNewLearningOutcomeDialogOpen] =
    useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [loToDelete, setLoToDelete] = useState(null);

  const handleClickOpen = () => {
    setIsNewLearningOutcomeDialogOpen(true);
  };

  const handleClose = () => {
    setIsNewLearningOutcomeDialogOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            {
              name: t('learning_outcomes_tab_title'),
              to: '/learning-outcomes',
              key: 'learning-outcomes'
            },
            {
              name: t('knowledge_components_tab_title'),
              to: '/knowledge-components',
              key: 'knowledge-components'
            },
            {
              name: t('departments_tab_title'),
              to: '/departments',
              key: 'departments'
            }
          ]}
          currentTab={'learning-outcomes'}
        />
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <SearchWithFilterButton
          onClick={toggleDrawer}
          value={search}
          onChange={onChangeSearch}
          numberOfFilters={numberOfFilters}
          placeholder={t('search_placeholder')}
        />
        {!isUserProgramEditor && (
          <Button
            disableElevation
            color='primary'
            variant='contained'
            onClick={handleClickOpen}
            sx={{
              padding: '0.75rem 1.5rem',
              textTransform: 'none'
            }}
          >
            {t('Create')}
          </Button>
        )}
      </div>
      <div>
        <div
          style={{
            marginBottom: '19px',
            color: '#8c99ad',
            fontSize: '12px',
            fontWeight: '600'
          }}
        >
          <TableResultsText
            isLoading={isLoading}
            sectionNameText={t('learning_outcomes_have_been_found')}
            total={total}
          />
        </div>
      </div>

      <Table
        name='learning-objectives'
        data={data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={(e) => {
          navigate(`/learning-outcomes/${e.guid}`);
        }}
        showPagination
        showTopPagination
        paginationSize='large'
        totalPages={totalPages}
        currentPage={parseInt(currentPage) || 1}
        onChangePage={onChangePage}
        isCustomizable
        tableColumnsVisibility={{
          department: false,
          internalEducationYear: false,
          educationYears: false,
          learningStandards: false
        }}
        isFirstColumnSticky
        isLastColumnSticky
        useApiSorting={totalPages > 1}
        onChangeSort={onChangeFilter}
        options={
          !isUserProgramEditor && [
            {
              icon: <BorderColorOutlinedIcon />,
              label: t('edit'),
              onClick: (e) => {
                navigate(`/learning-outcomes/${e.guid}`);
              }
            },
            {
              icon: <DeleteOutlineIcon />,
              label: t('delete'),
              onClick: (lo) => {
                setLoToDelete(lo);
                setIsConfirmDeleteDialogOpen(true);
              }
            }
          ]
        }
      />

      <NewLearningOutcomeDialog
        isOpen={isNewLearningOutcomeDialogOpen}
        onClose={handleClose}
        onSubmit={(newLO) => {
          handleClose();
          onCreateLearningObjective(newLO);
        }}
      />

      <LearningObjectivesFilter
        isDrawerOpen={isDrawerOpen}
        currentFilters={filters}
        onSearch={(value) => {
          onChangeFilter(value);
          setIsDrawerOpen(false);
        }}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      />

      <ConfirmDeleteDialog
        isOpen={isConfirmDeleteDialogOpen}
        onClose={() => {
          setLoToDelete(null);
          setIsConfirmDeleteDialogOpen(false);
        }}
        onConfirm={() => {
          setIsConfirmDeleteDialogOpen(false);
          onDeleteLearningObjective(loToDelete);
          setLoToDelete(null);
        }}
        itemToBeDeletedName={loToDelete?.name}
        itemToBeDeletedDescription={''}
      />
    </PageWithHeader>
  );
};

export default LearningOutcomesManager;
