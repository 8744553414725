import { Button, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDCard from '../../../../atoms/EDCard/EDCard';
import Toast from '../../../../atoms/Toast/Toast';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import './CommentSection.scss';

export default function CommentSection({ seedId }) {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [toastMessage, setToastMessage] = useState({});
  const [showToast, setShowToast] = useState(false);

  const handleCancel = () => {
    setComment('');
  };

  const handleSend = async () => {
    let commentResponse = await fetchWithToken({
      path: `/seeds/${seedId}/comments`,
      method: 'POST',
      data: { message: comment }
    });
    setComment('');
    getComments();
    if (commentResponse.status != 'success') {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_saved_error')
      });
      setShowToast(true);
    }
  };

  const handleDelete = async (commentId) => {
    let commentResponse = await fetchWithToken({
      path: `/seeds/${seedId}/comments`,
      method: 'DELETE',
      data: { commentGuids: [commentId] }
    });

    getComments();
    if (commentResponse.status != 'success') {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_saved_error')
      });
      setShowToast(true);
    }
  };

  const getComments = async () => {
    let response = await fetchWithToken({
      path: `/seeds/${seedId}/comments?offset=0&pageSize=25`,
      method: 'GET'
    });
    if (response.status === 'success') {
      setComments(response.data.seedComents);
    }
  };

  const formatDate = (dateString) => {
    let date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let minute = date.getMinutes();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (minute < 10) {
      minute = '0' + minute;
    }

    let formattedDate = `${day}/${month}/${year} ${t(
      'time_text'
    )} ${hour}:${minute}`;

    return formattedDate;
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <EDCard elevation={0}>
            <Grid
              container
              spacing={3}
              justifyContent='flex-start'
              alignItems='center'
            >
              <Grid item xs={12}>
                <div className='comment-section_title'>
                  {t('comments-title')}
                </div>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={t('add_comment')}
                  fullWidth
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant='contained'
                  disabled={!comment.trim()}
                  onClick={handleSend}
                >
                  {t('send_text')}
                </Button>
                {comment.trim() && (
                  <>
                    <div className='comment-section_cancel-button'>
                      <Button onClick={handleCancel}>{t('cancel')}</Button>
                    </div>
                  </>
                )}
              </Grid>
              {/* <Grid container justifyContent='flex-start' alignItems='center'> */}
              <Grid item xs={12}>
                {comments.map((data) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={data.guid}
                      className='comment-container'
                    >
                      <div className='comment-name'>
                        {data.createdBy.name} {data.createdBy.lastname}
                      </div>
                      <div className='comment-date'>
                        {formatDate(data.createdAt)}
                      </div>
                      <div className='comment-section_delete-button'>
                        <Button onClick={() => handleDelete(data.guid)}>
                          {t('delete')}
                        </Button>
                      </div>
                      <div className='comment-message'>{data.message}</div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </EDCard>
        </Grid>
      </Grid>
      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={toastMessage.type}
          text={toastMessage.text}
        />
      )}
    </Grid>
  );
}
