import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../_practice-components';
import EDAutoCompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import SearchInput from '../../atoms/SearchInput/SearchInput';
import Toast from '../../atoms/Toast/Toast';
import Card from '../../atoms/card/Card';
import EDDepartmentFilter from '../../components/filters/EDDepartmentFilter';
import PageWithHeader from '../../layouts/pageWithHeader/PageWithHeader';
import useAuth from '../../utils/hooks/useAuth';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../utils/localStorageKeys';
import KCsAndLOsTree from './components/kCsAndLOsTree/KCsAndLOsTree';
import useDemo from './useDemo';

const Demo = () => {
  const { getItem } = useLocalStorage();
  const {
    isLoading,
    educationLevels,
    isLoadingKCsAndLOs,

    languageOptions,
    selectedLanguage,
    onChangeLanguage,

    selectedDepartment,
    onChangeDepartment,

    educationYears,
    selectedEducationYear,
    onChangeSelectedYear,

    kCsAndLOs,
    onSelectLO,

    seed,
    showSeeds,
    disciplines,
    onNextSeed,
    noSeedsFound,
    isLoadingSeeds,
    onPreviousSeed,
    currentSeedIndex,
    seeds,

    multiLanguageKeys,
    previewLanguage,
    handlePreviewLanguageChange,

    showToast,
    setShowToast,

    onLoadMoreNodes,
    isLoadingMoreNodes,
    hasMoreNodesToLoad,

    search,
    onChangeSearch,
    selectedSeedName
  } = useDemo();

  const { t } = useTranslation();
  const { user } = useAuth();
  const [uiActivitiesLanguage, setUiActivitiesLanguage] = useState('en');
  useEffect(() => {
    setUiActivitiesLanguage(
      getItem(localStorageKeys.language) || i18nInstance.language || 'en'
    );
  }, []);

  return (
    <PageWithHeader>
      <Container maxWidth={'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h1'>{t('demo_title')}</Typography>
          </Grid>

          {/* FIXED */}
          <Grid item xs={12} md={2}>
            <EDAutoCompleteWithLabel
              label={t('demo_subject')}
              options={disciplines}
              value={disciplines[0]}
              showLabel={true}
              disabled={true}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <EDAutoCompleteWithLabel
              label={t('demo_education_level')}
              options={educationLevels}
              value={educationLevels[0]}
              disabled={true}
              showLabel={true}
              isLoading={isLoading}
            />
          </Grid>

          {/* VARIABLE */}
          <Grid item xs={12} md={2}>
            <EDAutoCompleteWithLabel
              label={t('demo_language')}
              options={languageOptions}
              value={selectedLanguage}
              onChange={(e, value) => onChangeLanguage(value)}
              disableClearable
              showLabel={true}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <EDAutoCompleteWithLabel
              label={t('demo_grade')}
              options={educationYears}
              value={selectedEducationYear}
              onChange={(e, value) => onChangeSelectedYear(value)}
              disableClearable
              showLabel={true}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EDDepartmentFilter
              language={selectedLanguage.value}
              onChange={onChangeDepartment}
              defaultValue={selectedDepartment}
              showLabel
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item md={4} xs={12}>
            <Stack spacing={1}>
              <Typography variant='caption' color='gray'>
                {t('demo_kc_and_lo')}
              </Typography>
              <Card
                variant='outlined'
                style={{ height: 800, overflowY: 'auto' }}
              >
                <div style={{ marginBottom: '1em' }}>
                  <SearchInput
                    value={search}
                    onChange={onChangeSearch}
                    placeholder={t('search_placeholder')}
                  />
                </div>

                {!selectedDepartment && (
                  <Typography variant='caption' color='GrayText'>
                    {t('demo_select_department')}
                  </Typography>
                )}
                {selectedDepartment && (
                  <KCsAndLOsTree
                    kCsAndLOs={kCsAndLOs}
                    onClickOnLO={onSelectLO}
                    isLoading={isLoadingKCsAndLOs}
                    onLoadMore={onLoadMoreNodes}
                    isLoadingMore={isLoadingMoreNodes}
                    hasMoreToLoad={hasMoreNodesToLoad}
                    isLoadingSeeds={isLoadingSeeds}
                  />
                )}
              </Card>
            </Stack>
          </Grid>
          <Grid item md={8} xs={12}>
            <Stack spacing={1}>
              <Typography variant='caption' color='gray'>
                {t('demo_activities')}
              </Typography>
              <Card
                variant='outlined'
                style={{ height: 800, overflowY: 'auto' }}
              >
                <Grid container spacing={3} justifyContent='center'>
                  {!Boolean(seeds.length) && !isLoadingSeeds && (
                    <Grid item xs={12}>
                      {noSeedsFound ? (
                        <Typography variant='caption' color='GrayText'>
                          {t('demo_no_activities')}
                        </Typography>
                      ) : (
                        <Typography variant='caption' color='GrayText'>
                          {t('demo_select_lo')}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {isLoadingSeeds && (
                    <Grid item xs={12}>
                      <Typography variant='caption' color='GrayText'>
                        {t('demo_loading_activities')}
                      </Typography>
                    </Grid>
                  )}
                  {Boolean(seeds.length) && (
                    <Grid item xs={12} style={{ height: '100%' }}>
                      <Stack justifyContent='space-between' direction='row'>
                        <Chip
                          label={t(seeds[currentSeedIndex]?.phase)}
                          color='primary'
                        />

                        {multiLanguageKeys && (
                          <ToggleButtonGroup
                            color='primary'
                            value={previewLanguage}
                            exclusive
                            onChange={handlePreviewLanguageChange}
                            aria-label='Language'
                            size='small'
                          >
                            {multiLanguageKeys.map((langItem) => (
                              <ToggleButton key={langItem} value={langItem}>
                                {langItem.toUpperCase()}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        )}

                        <Stack
                          direction='row'
                          alignItems='center'
                          spacing={1}
                          sx={{
                            minWidth: '136px',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <IconButton
                            color='primary'
                            onClick={onPreviousSeed}
                            disabled={currentSeedIndex === 0}
                          >
                            <ArrowLeft />
                          </IconButton>
                          {currentSeedIndex + 1} / {seeds.length}
                          <IconButton
                            color='primary'
                            disabled={seeds.length - 1 <= currentSeedIndex}
                            onClick={onNextSeed}
                          >
                            <ArrowRight />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {Boolean(seed) && (
                      <div style={{ height: '700px', width: '100%' }}>
                        <ActivityBlueberryComponent
                          activityType={ACTIVITY_TYPES.PREVIEW}
                          activity={seed}
                          mathRenderEngine='katex'
                          uiLanguage={uiActivitiesLanguage}
                          exerciseLanguage={previewLanguage}
                          pathAssetsSchool={`/assets/graphics/gamification`}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      {showToast && (
        <Toast
          type='error'
          text={t('demo_toast_error')}
          handleShowToast={setShowToast}
          autoHide={false}
        />
      )}
    </PageWithHeader>
  );
};

export default Demo;
