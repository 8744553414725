import { useTranslation } from 'react-i18next'
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/status_completed.svg'
import { ReactComponent as JsonNeedsCorrectionIcon } from '../../../../assets/icons/status_json_needs_correction.svg'
import { ReactComponent as TranslationIcon } from '../../../../assets/icons/status_json_pending.svg'
import { ReactComponent as FixTranslation } from '../../../../assets/icons/status_needs_correction.svg'
import { ReactComponent as JsonRevisionIcon } from '../../../../assets/icons/status_needs_revision.svg'
import { ReactComponent as PendingTranslationOnlyAppIcon } from '../../../../assets/icons/status_pending_translation_only_app.svg'
import { ReactComponent as ToDoIcon } from '../../../../assets/icons/status_todo.svg'

import './CellSeedStatusChip.scss'

const CellSeedStatusChip = ({ status }) => {
  const { t } = useTranslation()

  const statusIcon = {
    to_do: <ToDoIcon />,
    check_JSON: <JsonRevisionIcon />,
    correct_JSON: <JsonNeedsCorrectionIcon />,
    pending_auto_translation: <TranslationIcon />,
    check_translation: <JsonRevisionIcon />,
    fix_translation: <FixTranslation />,
    pending_translation_only_app: <PendingTranslationOnlyAppIcon />,
    done: <CompletedIcon />
  }

  return (
    <div className={`cell__container cell-seed-status`}>
      <span className='cell-seed-status__icon'>{statusIcon[status]}</span>
      <span className='cell-seed-status__text' title={t(status)}>
        {t(status)}
      </span>
    </div>
  )
}

export default CellSeedStatusChip
