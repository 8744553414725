import { useState } from 'react';
import { ReactComponent as DocumentDuplicateIcon } from '../../../../../../atoms/icon/svg/solid/document-duplicate.svg';
import BBTooltip from '../../../../../../atoms/tooltip/BBTooltip';
import './CellClassWithTooltip.scss';
const CellClassWithTooltip = ({ code, name }) => {
  const [codeText, setCodeText] = useState(code);

  const handleClickOnCode = () => {
    navigator.clipboard.writeText(code);
    setCodeText('Copied to clipboard!');
    setTimeout(() => {
      setCodeText(code);
    }, 1200);
  };

  return (
    <div className='cell-class-with-tooltip'>
      <BBTooltip text={codeText} placement='top'>
        <div
          className='cell-class-with-tooltip__code'
          onClick={handleClickOnCode}
        >
          <DocumentDuplicateIcon />
        </div>
      </BBTooltip>
      <BBTooltip text={name} placement='top'>
        <div>{name}</div>
      </BBTooltip>
    </div>
  );
};

export default CellClassWithTooltip;
