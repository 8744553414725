import { OpenInNew } from '@mui/icons-material';
import {
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import BackButton from '../../../../atoms/BackButton/BackButton';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDCard from '../../../../atoms/EDCard/EDCard';
import Toast from '../../../../atoms/Toast/Toast';
import TDTabs from '../../../../components/TDTabs/TDTabs';
import FullPageLayout from '../../../../layouts/dashboardFullPage/FullPageLayout';
import EditLicenseDialog from './components/EditLicenseDialog';
import UserDetails from './tabs/UserDetails';
import UserLicenses from './tabs/UserLicenses';
import UserPassword from './tabs/UserPassword';
import useEditUser from './useEditUser';

const EditUser = () => {
  const {
    TABS,
    user,
    groups,
    schools,
    licenses,
    isSaving,
    isLoading,
    showToast,
    currentTab,
    toastMessage,
    isEditingLicense,
    isLoginAsUserLoading,
    isEditLicenseDialogOpen,
    onSaveUser,
    onChangeTab,
    setShowToast,
    onUpdateUser,
    onLoginAsUser,
    onEditLicense,
    setNewPassword,
    onUpdatePassword,
    onCreateNewGroup,
    onConfirmEditLicense,
    onCreateNewContract,
    setNewConfirmPassword,
    onCloseEditLicenseDialog
  } = useEditUser();

  return (
    <FullPageLayout
      left={<BackButton />}
      title={
        <Typography variant='h4' fontWeight={600}>
          Edit User
        </Typography>
      }
      right={
        <EDButton
          onClick={onLoginAsUser}
          variant='outlined'
          color='inherit'
          disabled={isLoginAsUserLoading || isLoading}
          disableElevation
          endIcon={!isLoginAsUserLoading && !isLoading && <OpenInNew />}
        >
          {isLoading || isLoginAsUserLoading ? (
            <CircularProgress color='inherit' size={20} />
          ) : (
            'Login as user'
          )}
        </EDButton>
      }
      footerRight={
        <EDButton
          onClick={TABS[currentTab].action}
          variant='contained'
          disabled={isSaving || isLoading}
          disableElevation
        >
          {isSaving ? 'Saving...' : 'Save'}
        </EDButton>
      }
    >
      <Container maxWidth='xl'>
        <Grid container spacing={3} mb={20}>
          <Grid item xs={12}>
            <TDTabs
              tabs={Object.values(TABS)}
              currentTab={currentTab}
              onClick={onChangeTab}
            />
          </Grid>

          {isLoading && (
            <Grid item xs={12}>
              <EDCard>
                <CardContent>
                  <div>Loading...</div>
                </CardContent>
              </EDCard>
            </Grid>
          )}
          {!isLoading && currentTab === 'details' && (
            <UserDetails
              {...user}
              isDemoUser={user?.is_demo}
              schools={schools}
              groups={groups}
              jsonSettings={user?.json_settings}
              onUpdateUser={onUpdateUser}
            />
          )}
          {!isLoading && currentTab === 'password' && (
            <UserPassword
              setNewPassword={setNewPassword}
              setNewConfirmPassword={setNewConfirmPassword}
            />
          )}
          {!isLoading && currentTab === 'licenses' && (
            <UserLicenses
              licenses={licenses}
              onRedeemLicense={onCreateNewGroup}
              onEditLicense={onEditLicense}
            />
          )}
        </Grid>

        {showToast && (
          <Toast
            handleShowToast={setShowToast}
            type={toastMessage.type}
            text={toastMessage.text}
          />
        )}

        {isEditLicenseDialogOpen && (
          <EditLicenseDialog
            selectedLicense={{
              label: licenses[0]?.code,
              value: licenses[0]?.code
            }}
            onSaveLicense={onConfirmEditLicense}
            isEditLicenseDialogOpen={isEditLicenseDialogOpen}
            isEditingLicense={isEditingLicense}
            onCloseEditLicenseDialog={onCloseEditLicenseDialog}
          />
        )}
      </Container>
    </FullPageLayout>
  );
};

export default EditUser;
