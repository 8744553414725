import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDrawer from '../../../../../atoms/filterDrawer/FilterDrawer';
import FilterItem from '../../../../../atoms/filterItem/FilterItem';
import EDEducationLevelProgramFilter from '../../../../../components/filters/EDEducationLevelProgramFilter';
import EDResponsibleProgramFilter from '../../../../../components/filters/EDResponsibleProgramFilter';

const ProgramsFilter = ({
  isDrawerOpen,
  onCloseDrawer,
  onSearch,
  currentFilters
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState(currentFilters);

  useEffect(() => {
    setFilters(currentFilters);
  }, [currentFilters]);

  const removeDuplicates = (array) => {
    return array.filter((item, index, self) =>
      typeof item === 'object'
        ? index === self.map((object) => object.id).indexOf(item.id)
        : index === self.map((value) => value).indexOf(item)
    );
  };

  const onChangeFilter = (filter) => {
    let filterValue = undefined;

    if (filter?.value?.length > 0) {
      filterValue = removeDuplicates(filter.value);
    }

    setFilters({
      ...filters,
      [filter.id]: filterValue
    });
  };

  return (
    <FilterDrawer
      title={t('Filter')}
      isDrawerOpen={isDrawerOpen}
      onCloseDrawer={onCloseDrawer}
      onReset={() => {
        onSearch({});
        setFilters({});
        onCloseDrawer();
      }}
      onSearch={() => onSearch(filters)}
      isSearchDisabled={Object.keys(filters).length === 0}
    >
      <FilterItem
        title={t('programs_status')}
        type='checkbox'
        multiple
        options={[
          { label: t('programs_status_draft'), value: 'DRAFT' },
          { label: t('programs_status_pending'), value: 'PENDING' },
          { label: t('programs_status_published'), value: 'PUBLISHED' }
        ]}
        defaultSelection={filters.status || []}
        onChange={(value) => {
          onChangeFilter({ id: 'status', value });
        }}
      />

      <FilterItem
        title={t('programs_property')}
        type='custom'
        id='responsible'
        defaultSelection={filters.responsible || []}
        customFilter={
          <EDResponsibleProgramFilter
            multiple
            defaultValue={filters.responsible || []}
            onChange={(value) => {
              onChangeFilter({ id: 'responsible', value: value });
            }}
            isFilter={true}
          />
        }
      />

      <FilterItem
        title={t('programs_education_level')}
        type='custom'
        defaultSelection={filters.educationYearGuid || []}
        customFilter={
          <EDEducationLevelProgramFilter
            multiple
            defaultValue={filters.educationYearGuid || []}
            onChange={(value) => {
              onChangeFilter({ id: 'educationYearGuid', value });
            }}
            isFilter={true}
          />
        }
      />

      <FilterItem
        title={t('programs_country')}
        type='checkbox'
        multiple
        options={[
          { label: t('spain'), value: 'ES' },
          { label: t('brazil'), value: 'BR' },
          { label: t('united_states'), value: 'US' },
          { label: t('argentina'), value: 'AR' }
        ]}
        defaultSelection={filters.countryCode || []}
        onChange={(value) => {
          onChangeFilter({ id: 'countryCode', value });
        }}
      />
    </FilterDrawer>
  );
};

export default ProgramsFilter;
