import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import EDButton from '../../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';

const CreateSchoolDialog = ({ isOpen, onClose, onSubmit }) => {
  const [newSchoolDetails, setNewSchoolDetails] = useState({
    name: '',
    address: ''
  });

  const [canProceed, setCanProceed] = useState(false);

  useEffect(() => {
    if (newSchoolDetails.name && newSchoolDetails.address) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [newSchoolDetails]);

  const onCancelDialog = () => {
    setNewSchoolDetails({
      name: '',
      address: '',
      school_path: null
    });
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onCancelDialog}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h2' fontSize={18} fontWeight={600}>
                Create School
              </Typography>
              {/* Add close icon */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <EDInputWithLabel
              label='School name'
              onChange={({ target: { value } }) => {
                setNewSchoolDetails({ ...newSchoolDetails, name: value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <EDInputWithLabel
              label='Address'
              onChange={({ target: { value } }) => {
                setNewSchoolDetails({ ...newSchoolDetails, address: value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(newSchoolDetails.school_path)}
                    onChange={({ target: { checked } }) => {
                      console.log('checked', checked);
                      if (checked) {
                        setNewSchoolDetails({
                          ...newSchoolDetails,
                          school_path: 'human_cartoons'
                        });
                      } else {
                        setNewSchoolDetails({
                          ...newSchoolDetails,
                          school_path: null
                        });
                      }
                    }}
                  />
                }
                label='Blessberry'
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onCancelDialog}>
          Cancel
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canProceed}
          onClick={() => {
            onSubmit(newSchoolDetails);
          }}
        >
          Create
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSchoolDialog;
