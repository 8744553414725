import BBTooltip from '../../../../../../atoms/tooltip/BBTooltip';

const CellWithToolTip = ({ text }) => {
  return (
    <div className='cell-with-tooltip'>
      <BBTooltip text={text} placement='top'>
        <div>{text}</div>
      </BBTooltip>
    </div>
  );
};

export default CellWithToolTip;
