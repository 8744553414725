import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useState } from 'react';
import LicenseFilter from '../../../../../components/tenantFilters/LicenseFilter';

const EditLicenseDialog = ({
  onSaveLicense,
  selectedLicense,
  isEditingLicense,
  isEditLicenseDialogOpen,
  onCloseEditLicenseDialog
}) => {
  const [newSelectedLicense, setNewSelectedLicense] = useState(selectedLicense);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={isEditLicenseDialogOpen}
      onClose={onCloseEditLicenseDialog}
    >
      <DialogTitle>Edit License</DialogTitle>
      <DialogContent>
        <LicenseFilter
          defaultValue={newSelectedLicense}
          onChange={(value) => {
            setNewSelectedLicense(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseEditLicenseDialog}>Cancel</Button>
        <Button
          onClick={() => onSaveLicense(newSelectedLicense.value)}
          variant='contained'
          disableElevation
          loading={isEditingLicense}
          disabled={isEditingLicense}
        >
          {isEditingLicense ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLicenseDialog;
