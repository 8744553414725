import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createNewUser, redeemLicense } from '../../../../utils/crud/user';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';

const useUsers = () => {
  const columnHelper = createColumnHelper();
  const pageSize = 10;

  const tabs = [
    { name: 'Users', to: '/users', key: 'users' },
    // { name: 'Groups', to: '/groups', key: 'groups' },
    { name: 'Schools', to: '/schools', key: 'schools' }
  ];

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' });

  const [nameSearch, setNameSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateNewUserDialogOpen, setIsCreateNewUserDialogOpen] =
    useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / pageSize);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('lastname', {
      header: 'Last Name',
      id: 'lastname',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('role_name', {
      header: 'role',
      id: 'role_name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('username', {
      header: 'username',
      id: 'username',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  const roles = [
    {
      guid: 'R01',
      role: 'Student'
    },
    {
      guid: 'R02',
      role: 'Teacher'
    },
    {
      guid: 'R03',
      role: 'School Admin'
    },
    {
      guid: 'R04',
      role: 'Editorial Admin'
    },
    {
      guid: 'R05',
      role: 'Help desk'
    }
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [nameSearch, currentPage]);

  const getData = async () => {
    setIsLoading(true);

    const auxNameSearch = nameSearch ? `&name=${nameSearch}` : '';

    let response = await fetchWithToken({
      path: `/users?pageSize=${pageSize}&offset=${offset}${auxNameSearch}`
    });
    setUsers(response.data.users);
    setTotal(response.data.total);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * pageSize);
  };

  let searchDeboundTimer = null;
  const onSearch = (value) => {
    clearTimeout(searchDeboundTimer);
    searchDeboundTimer = setTimeout(() => {
      setNameSearch(value);
    }, 300);
  };

  const onClickOnUser = (e) => {
    navigate(`/users/${e.guid}`);
  };

  const onClickOnCreate = (e) => {
    setIsCreateNewUserDialogOpen(true);
  };

  const onCloseCreateNewUserDialog = () => {
    setIsCreateNewUserDialogOpen(false);
  };

  const onCreateNewUser = async (data) => {
    setIsLoading(true);

    let hasError = false;
    let newUserGuid = null;

    try {
      // Crear usuario
      const newUserResponse = await createNewUser(data);

      // console.log('newUserResponse', newUserResponse);

      if (newUserResponse.errorData) {
        throw newUserResponse;
      }

      newUserGuid = newUserResponse.data.guid;

      // redeem license if user is role teacher
      if (data.role === 'R02') {
        await redeemLicense({
          userGuid: newUserGuid,
          licenseCode: data.license
        });
      }
    } catch (createError) {
      console.log('onCreateNewUser error', createError);

      hasError = true;

      const errorMessage =
        createError?.errorData?.data?.error?.code === 409
          ? t('users_error_username_exists')
          : t('users_error_create');

      setToastMessage({
        type: 'error',
        text: errorMessage
      });

      setShowToast(true);
    }

    setIsLoading(false);

    if (!hasError) {
      navigate(`/users/${newUserGuid}`);
    }
  };

  const onDeleteUser = async (user) => {
    console.log('user to delete', user);
    const deleteUserResponse = await fetchWithToken({
      method: 'DELETE',
      path: `/users/${user.guid}`
    });

    if (deleteUserResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('user_delete_success')
      });
      getData();
    } else {
      setToastMessage({
        type: 'error',
        text: t('user_delete_error')
      });
    }
    setShowToast(true);
  };

  return {
    tabs,
    roles,
    total,
    offset,
    columns,
    users,
    pageSize,
    isLoading,
    currentPage,
    totalPages,
    onChangePage,
    showToast,
    toastMessage,
    setShowToast,
    onClickOnUser,
    onClickOnCreate,
    onCreateNewUser,
    isCreateNewUserDialogOpen,
    onCloseCreateNewUserDialog,
    onSearch,
    onDeleteUser
  };
};
export default useUsers;
