import { fetchWithToken } from '../helpers/fetcher';

export const createNewUser = async (userData) => {
  // Crear usuario
  const newUserResponse = await fetchWithToken({
    path: '/users',
    method: 'POST',
    data: userData
  });

  // console.log('createNewUser', newUserResponse);

  if (newUserResponse.status !== 'success') {
    throw { type: 0, errorData: newUserResponse };
  }

  const newUserGuid = newUserResponse.data.guid;

  // Inicio limpieza usuario colegio por defecto
  // Nota: "Esto debería ir en back y obtener el usuario limpio"

  // Obtener datos de usuario para ver si hay que borrar colegios asignados por defecto
  const getUserResponse = await fetchWithToken({
    path: `/users/${newUserGuid}?include[]=groups`
  });

  // console.log('getUserResponse', getUserResponse);

  if (getUserResponse.status !== 'success') {
    throw { type: 1, errorData: newUserResponse };
  }

  // Si siendo nuevo tiene algún colegio asigando por defecto se quita
  if (
    getUserResponse?.data?.schools?.length &&
    getUserResponse?.data?.schools?.length > 0
  ) {
    const deleteDefaultSchoolResponse = await fetchWithToken({
      path: `/schools/${getUserResponse.data.schools[0].guid}/users`,
      method: 'DELETE',
      data: {
        guid: [newUserGuid]
      }
    });

    // console.log('deleteDefaultSchoolResponse', deleteDefaultSchoolResponse);

    if (deleteDefaultSchoolResponse.status !== 'success') {
      throw { type: 1, errorData: deleteDefaultSchoolResponse };
    }
  }
  // Fin limpieza usuario colegio por defecto

  return newUserResponse;
};

export const redeemLicense = async ({ userGuid, licenseCode }) => {
  const redeemLicenseResponse = await fetchWithToken({
    path: `/users/${userGuid}/redeem`,
    method: 'POST',
    data: {
      code: licenseCode,
      replace: true
    }
  });

  return redeemLicenseResponse;
};
