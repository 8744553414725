import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchWithFilterButton from '../../../../atoms/SearchWithFilterButton/SearchWithFilterButton';
import Toast from '../../../../atoms/Toast/Toast';
import EDDashboardTabs from '../../../../components/EDDashboardTabs/EDDashboardTabs';
import Table from '../../../../components/table/Table';
import TableResultsText from '../../../../components/tableResultsText/TableResultsText';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import ConfirmDeleteDialog from '../../../tenantDashboard/schools/Edit/tabs/dialogs/ConfirmDeleteDialog';
import CreateNewProgramDialog from '../dialogs/CreateNewProgramDialog';
import ProgramsFilter from './components/ProgramsFilter';
import './ListPrograms.scss';
import useListPrograms from './useListPrograms';

const Programs = () => {
  const {
    total,
    totalPages,
    currentPage,
    tenants,
    showToast,
    phasers,
    setShowToast,
    toastMessage,
    columns,
    programs,
    educationYears,
    onCreateNewProgram,
    onChangePage,
    isLoading,
    onClickOnProgram,
    onDeleteProgram,
    isCreateNewProgramDialogOpen,
    setIsCreateNewProgramDialogOpen,
    onDuplicateProgram,
    canEditProgram,
    onChangeFilter,
    onChangeSearch,
    search,
    filters,
    numberOfFilters
  } = useListPrograms();
  const { t } = useTranslation();

  const [itemToDelete, setItemToDelete] = useState(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const dashboardTabs = [
    {
      key: 'activities',
      name: t('activities_tab_title'),
      to: '/activities'
    },
    {
      key: 'programs',
      name: t('programs_tab_title'),
      to: '/programs'
    }
  ];

  const tableRowOptions = [
    {
      icon: <BorderColorOutlinedIcon />,
      label: t('edit'),
      onClick: (program) => onClickOnProgram(program),
      hideOption: (program) => !canEditProgram(program)
    },
    {
      icon: <ContentCopyIcon />,
      label: t('duplicate'),
      onClick: (program) => {
        onDuplicateProgram(program);
      }
    },
    {
      icon: <DeleteOutlineIcon />,
      label: t('delete'),
      onClick: (program) => {
        setItemToDelete(program);
        setIsConfirmDeleteDialogOpen(true);
      },
      hideOption: (program) => {
        return !canEditProgram(program);
      }
    }
  ];

  const getResultsText = () => {
    if (isLoading) return <span>{loadingText}</span>;

    if (total > 0)
      return (
        <>
          <span className='programs-found-items-number'>
            {Intl.NumberFormat().format(total)}
          </span>{' '}
          {t('x_programs_found', { count: total })}
        </>
      );

    return <span>{withoutResultsText}</span>;
  };

  return (
    <PageWithHeader
      left={<EDDashboardTabs tabs={dashboardTabs} currentTab={'programs'} />}
    >
      <Stack spacing={2}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem'
          }}
        >
          <div className='programs-search-input-container'>
            <SearchWithFilterButton
              onClick={toggleDrawer}
              value={search}
              onChange={onChangeSearch}
              numberOfFilters={numberOfFilters}
              placeholder={t('search_programs')}
            />
          </div>

          <Button
            disableElevation
            color='primary'
            variant='contained'
            onClick={() => {
              setIsCreateNewProgramDialogOpen(true);
            }}
            sx={{
              padding: '0.75rem 1.5rem',
              textTransform: 'none'
            }}
          >
            {t('Create')}
          </Button>
        </div>

        <div className='programs-found-items-container'>
          <TableResultsText
            isLoading={isLoading}
            sectionNameText={t('x_programs_found', { count: total })}
            total={total}
            className={'programs-found-items-number'}
          />
        </div>

        <Table
          filters={[]}
          onFilter={onChangeFilter}
          data={programs}
          onRowClick={onClickOnProgram}
          columns={columns}
          numberOfFilters={0}
          showPagination={true}
          showTopPagination
          paginationSize='large'
          totalPages={totalPages}
          currentPage={parseInt(currentPage) || 1}
          useApiSorting={totalPages > 1}
          onChangePage={onChangePage}
          onChangeSort={onChangeFilter}
          isLoading={isLoading}
          options={tableRowOptions}
        />
      </Stack>

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={toastMessage.type}
          text={toastMessage.message}
        />
      )}
      {isCreateNewProgramDialogOpen && (
        <CreateNewProgramDialog
          isOpen={isCreateNewProgramDialogOpen}
          onClose={() => {
            setIsCreateNewProgramDialogOpen(false);
          }}
          phasers={phasers}
          tenants={tenants}
          educationYears={educationYears}
          onCreateNewProgram={onCreateNewProgram}
        />
      )}
      <ConfirmDeleteDialog
        isOpen={isConfirmDeleteDialogOpen}
        onClose={() => {
          setIsConfirmDeleteDialogOpen(false);
          setItemToDelete(null);
        }}
        onConfirm={() => {
          setIsConfirmDeleteDialogOpen(false);
          onDeleteProgram(itemToDelete);
        }}
        itemToBeDeletedName={itemToDelete?.name || 'program'}
        itemToBeDeletedDescription={'confirm_delete_not_reversible'}
      />
      <ProgramsFilter
        isDrawerOpen={isDrawerOpen}
        currentFilters={filters}
        onSearch={(value) => {
          onChangeFilter(value);
          setIsDrawerOpen(false);
        }}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      />
    </PageWithHeader>
  );
};

export default Programs;
