import { createColumnHelper } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CellTag from '../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfText from '../../../components/table/cell/cellTypes/CellWithOneLineOfText';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import { lastUpdate } from '../../../utils/helpers/lastUpdate';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';

const useKnowledgeComponentsManager = () => {
  const PAGE_SIZE = 25;
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const { isUserProgramEditor } = useEditorDashboardPermissions();

  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({});
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / PAGE_SIZE);

  const [toastMessage, setToastMessage] = useState({});
  const [showToast, setShowToast] = useState(false);

  const columns = [
    // name displays the guid in reality (check the get function)
    columnHelper.accessor('name', {
      header: t('knowledge_component_column_name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'cellWithOneLineOfText',
      enableHiding: false,
      cell: (props) => <CellWithOneLineOfText text={props.row.original.name} />
    }),

    // description displays the name in reality (check the get function)
    columnHelper.accessor('description', {
      header: t('knowledge_component_column_description'),
      id: 'description',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.description} />
      )
    }),

    columnHelper.accessor('department', {
      header: t('knowledge_component_column_department'),
      id: 'department',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (info) => info.renderValue()
    }),

    columnHelper.accessor('knowledgeComponents', {
      header: t('knowledge_component_column_knowledge_components'),
      id: 'knowledgeComponents',
      enableSorting: false,
      sortingFn: 'cellTag',
      cell: (props) => <CellTag tags={props.row.original.knowledgeComponents} />
    }),

    columnHelper.accessor('lastUpdate', {
      header: t('knowledge_component_column_updated'),
      id: 'updatedAt',
      enableSorting: true,
      sortingFn: 'datetime',
      cell: (info) => info.renderValue()
    })
  ];

  const onChangeFilter = (newFilters) => {
    let tempFilters = { ...newFilters };
    // remove page from filters to avoid pagination issues when changing filters
    delete tempFilters.page;
    // filters are added if they do not exist and replaced if they do
    setFilters({ ...tempFilters, search: search });
    // save filters on url
    saveFiltersInURL({ ...tempFilters, search: search });
    // update number of filters
    Object.keys(
      Object.keys(tempFilters).filter(
        (item) => !['search', 'orderBy', 'orderType'].includes(item)
      )
    ).length - 1;

    getData();
  };

  const saveFiltersInURL = (newFilters) => {
    // check if all filters are empty
    const allFiltersEmpty = Object.values(newFilters).every(
      (filter) => filter === '' || filter === null || filter === undefined
    );

    // if all filters are empty, remove filters from url
    if (allFiltersEmpty) {
      const searchParams = new URLSearchParams(window.location.search);

      const URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // delete except search
        if (!['search', 'orderBy', 'orderType'].includes(key)) {
          searchParams.delete(key);
        }
      });

      const newUrl = `${window.location.pathname}${
        searchParams.toString() !== ''
          ? `?${searchParams.toString()}`
          : searchParams.toString()
      }`;

      history.pushState(null, '', newUrl);
      return;
    }

    const searchParams = new URLSearchParams();
    const tempFilters = { ...newFilters };
    delete tempFilters.offset;

    Object.keys(tempFilters).forEach((key) => {
      if (
        tempFilters[key] !== '' &&
        tempFilters[key] !== null &&
        tempFilters[key] !== undefined
      ) {
        searchParams.append(
          key,
          typeof tempFilters[key] === 'object'
            ? JSON.stringify(tempFilters[key])
            : tempFilters[key]
        );
      }
    });
    // searchParams.set('filters', JSON.stringify(newFilters));
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    history.pushState(null, '', newUrl);
  };

  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // get all search params
    let URLfilters = {};
    try {
      URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // check if value is a stringified object
        if (URLfilters[key].startsWith('{')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }

        // check if value is a stringified array
        if (URLfilters[key].startsWith('[')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }
      });
    } catch (e) {
      console.log('error parsing filters', e);
    }

    setFilters(URLfilters);
    setSearch(URLfilters.search);
    setOffset(((URLfilters.page || 1) - 1) * PAGE_SIZE);
    setCurrentPage(URLfilters.page || 1);

    // update number of filters
    setNumberOfFilters(
      Object.keys(URLfilters).filter(
        (item) =>
          !['search', 'page', 'orderBy', 'orderType'].includes(item) &&
          URLfilters[item] !== '' &&
          URLfilters[item] !== null &&
          URLfilters[item] !== undefined
      ).length
    );

    return { ...URLfilters, offset: ((URLfilters.page || 1) - 1) * PAGE_SIZE };
  };

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);

    saveFiltersInURL({ ...filters, page: page });
  };

  const getDescription = (descriptionLanguages, lang) => {
    const description = descriptionLanguages.find(
      (item) => item.lang === lang
    )?.description;
    return description;
  };

  const getData = async () => {
    // to avoid multiple calls
    if (isLoading) return;
    setIsLoading(true);

    const filtersFromURL = getFiltersFromURL();
    let filter_revision_without_dependencies = false;
    let filter_revision_without_lo = false;
    if (filtersFromURL.kc_revision && filtersFromURL.kc_revision.length > 0) {
      if (filtersFromURL.kc_revision.includes('KCWithoutDependencies')) {
        filter_revision_without_dependencies = true;
      }
      if (filtersFromURL.kc_revision.includes('KCWithoutLearningOutcome')) {
        filter_revision_without_lo = true;
      }
    }

    const queryParameters = [
      `offset=${
        filtersFromURL.offset !== undefined && filtersFromURL.offset !== null
          ? filtersFromURL.offset
          : offset
      }`,
      `pageSize=${PAGE_SIZE}`,
      'type[]=concept', // tipo para LOs
      filtersFromURL.search && `search=${filtersFromURL.search}`,
      filtersFromURL.departments &&
        filtersFromURL.departments
          .map((s) => `departmentGuid[]=${s.id}`)
          .join('&'),
      filtersFromURL.educationYearGuid &&
        filtersFromURL.educationYearGuid
          .map((s) => `internalEducationYearGuid[]=${s.id}`)
          .join('&'),
      filter_revision_without_dependencies && `KCWithoutDependencies=1`,
      filter_revision_without_lo && `KCWithoutLearningOutcome=1`,
      `orderBy=${filtersFromURL.orderBy || 'updatedAt'}`,
      `orderType=${filtersFromURL.orderType || 'DESC'}`,
      `includeDepartments=1`,
      `includeSeeds=0`,
      `includeLinkTo=0`,
      `includeStandards=0`,
      `includeUpdateByUserDetails=0`
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/nodes/knowledge-components/?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });

    console.log('KC response', response);

    setTotal(response.data.count);

    const data = response.data.nodes.map((kc) => ({
      ...kc,
      guid: kc.guid,

      description:
        getDescription(kc.descriptionLanguages, 'ES') ||
        getDescription(kc.descriptionLanguages, 'EN') ||
        getDescription(kc.descriptionLanguages, 'PT') ||
        '-',

      name: kc.name || '-',

      department: kc.department?.name || '-',

      knowledgeComponents: kc.linkFrom
        // .filter((node) => node.type === 'concept')
        .map((node) => ({
          label: node.name,
          description: node.description
        })),

      lastUpdate: lastUpdate(
        kc?.updatedAt || kc?.createdAt || new Date().toISOString(),
        (kc.updatedBy || kc.createdBy)?.name || 'user',
        t
      )

      /*
      learningStandards: kc.learningObjectives.map((item) => ({
        label: item.guid,
        description: item.name
      })),
      */

      // educationYears: kc.loNodeSeeds
      //   .map((item) => item?.loSeed?.educationYear?.year || '?')
      //   .filter((value, index, array) => array.indexOf(value) === index)
      //   .sort((a, b) => {
      //     if (a === '?') return -1;
      //     if (b === '?') return 1;
      //     return a - b;
      //   })
      //   .join(', ')
    }));

    setData(data);

    setIsLoading(false);
  };

  const onCreateKnowledgeComponent = async (newLo) => {
    const createKCResponse = await fetchWithToken({
      method: 'POST',
      path: '/nodes',
      data: newLo
    });

    if (createKCResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_kc_saved_success')
      });
      // Reload the table with the new data
      getData({});
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_kc_saved_error')
      });
    }

    setShowToast(true);
  };

  const onDeleteKnowledgeComponent = async (kc) => {
    console.log(kc);
    const deleteKCResponse = await fetchWithToken({
      method: 'DELETE',
      path: `/nodes/${kc.guid}`
    });

    if (deleteKCResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_kc_deleted_success')
      });
      getData({});
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_kc_deleted_error')
      });
    }

    setShowToast(true);
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      let filtersFromURL = getFiltersFromURL();
      delete filtersFromURL.page;
      saveFiltersInURL({ ...filtersFromURL, search: searchValue });
      getData();
      return;
    }, 500),
    []
  );

  useEffect(() => {
    getData({ ...filters, search: search });
  }, [currentPage]);

  return {
    isUserProgramEditor,
    data,
    total,
    search,
    columns,
    filters,
    isLoading,
    setFilters,
    onChangeSearch,
    onChangeFilter,
    numberOfFilters,
    currentPage,
    totalPages,
    onChangePage,
    toastMessage,
    showToast,
    setShowToast,
    onCreateKnowledgeComponent,
    onDeleteKnowledgeComponent
  };
};

export default useKnowledgeComponentsManager;
