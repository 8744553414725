import { useState } from 'react';
import { checkIfValidEmail } from '../../../../../../utils/helpers/checkIfValidEmail';
import { fetchWithToken } from '../../../../../../utils/helpers/fetcher';

const useImportUsers = ({ schoolGuid }) => {
  const [fileRawData, setFileRawData] = useState(null);
  const [checksData, setChecksData] = useState(null);
  const [dataForApi, setDataForAPI] = useState(null);
  const [usernameErrorsCount, setUsernameErrorsCount] = useState(0);
  const [groupErrorsCount, setGroupErrorsCount] = useState(0);
  const [groupUnassignedCount, setGroupUnassignedCount] = useState(0);
  const [isCheckingUsers, setIsCheckingUsers] = useState(false);
  const [isCreatingUsers, setIsCreatingUsers] = useState(false);
  const [hasReadFile, setHasReadFile] = useState(false);
  const [creationStatus, setCreationStatus] = useState(false);
  const [missingLicenseErrorsCount, setMissingLicenseErrorsCount] = useState(0);
  const [invalidLicenseErrorsCount, setInvalidLicenseErrorsCount] = useState(0);

  const handleUploadData = (results) => {
    setFileRawData(results?.data);
    createDataForAPI(results?.data);
    setHasReadFile(true);
  };

  const acceptLicenses = (license) => {
    return ['ES', 'EN', 'PT'].includes(license);
  };

  const createDataForAPI = (rawData) => {
    const dataForApi = rawData.slice(1);
    let dataArray = [];
    dataForApi.forEach((item) => {
      const userObject = {
        name: item[0]?.trim(),
        lastname: item[1]?.trim(),
        username: item[2]?.trim(),
        email: item[2]?.trim(),
        role: item[3]?.trim()?.toLowerCase(),
        password: item[4]?.trim(),
        group: item[5]?.trim(),
        is_demo: item[6]
          ? item[6]?.trim()?.toLowerCase() === 'no'
            ? 0
            : 1
          : 0,
        devmode: item[7]
          ? item[7]?.trim()?.toLowerCase() === 'no'
            ? 0
            : 1
          : 0,
        ...(item[3]?.trim()?.toLowerCase() === 'teacher' && {
          license: item[8]?.trim(),
          replace: true
        })
      };
      dataArray.push(userObject);
    });

    setDataForAPI(dataArray);
  };

  const checkUsername = (item) => {
    if (item.role.toLowerCase() === 'teacher') {
      if (!checkIfValidEmail(item.username)) {
        return {
          message: 'The teacher username must be an email.',
          hasError: true
        };
      }
    }
    return {
      message: item.username_check?.message,
      hasError: item.username_check?.code !== 200
    };
  };

  const createCheckDataForTable = (data) => {
    let dataForTable = [];
    data.forEach((item) => {
      const checkObject = {
        username: item.username,
        username_check_message: checkUsername(item).message,
        username_check_has_error: checkUsername(item).hasError,
        group: item.group,
        group_check_message: item.group
          ? item.group_check?.message
          : 'No group',
        group_check_has_error:
          item.group_check?.code !== 200 &&
          item.group_check?.code !== undefined,
        license: item.license,
        invalid_license_check_message:
          item.license &&
          item.role.toLowerCase() === 'teacher' &&
          !acceptLicenses(item.license)
            ? 'License not accepted'
            : '',
        invalid_license_check_has_error:
          item.license &&
          item.role.toLowerCase() === 'teacher' &&
          !acceptLicenses(item.license),

        missing_license_check_message:
          item.role.toLowerCase() === 'teacher' && !item.license
            ? 'Missing license for teacher'
            : '',
        missing_license_check_has_error:
          item.role.toLowerCase() === 'teacher' && !item.license
      };
      dataForTable.push(checkObject);
    });

    const errorCounts = dataForTable.reduce(
      (acc, obj) => {
        acc.usernameErrors += obj.username_check_has_error ? 1 : 0;
        acc.groupErrors += obj.group_check_has_error ? 1 : 0;
        acc.groupUnassigned += obj.group ? 0 : 1;
        acc.missingLicenseErrors += obj.missing_license_check_has_error ? 1 : 0;
        acc.invalidLicenseErrors += obj.invalid_license_check_has_error ? 1 : 0;
        return acc;
      },
      {
        usernameErrors: 0,
        groupErrors: 0,
        groupUnassigned: 0,
        missingLicenseErrors: 0,
        invalidLicenseErrors: 0
      }
    );

    setUsernameErrorsCount(errorCounts.usernameErrors);
    setGroupErrorsCount(errorCounts.groupErrors);
    setGroupUnassignedCount(errorCounts.groupUnassigned);
    setMissingLicenseErrorsCount(errorCounts.missingLicenseErrors);
    setInvalidLicenseErrorsCount(errorCounts.invalidLicenseErrors);

    setChecksData(dataForTable);
  };

  const onCheckBatchUsersData = async () => {
    setIsCheckingUsers(true);
    const response = await fetchWithToken({
      method: 'POST',
      path: `/front/school-admin/${schoolGuid}/check-users`,
      data: {
        users: dataForApi
      }
    });
    console.log('onCheckBatchUsersData response', response);
    if (response.status === 'success') {
      createCheckDataForTable(response?.data);
      console.log('⏫ Import Users - SUCCESS checking users');
    } else {
      console.log('⏫ Import Users - ERROR checking users', response.data);
    }
    setIsCheckingUsers(false);
  };

  const onCreateBatchUsers = async () => {
    console.log('onCreateBatchUsers schoolGuid', schoolGuid);
    setIsCreatingUsers(true);
    const response = await fetchWithToken({
      method: 'POST',
      path: `/front/school-admin/${schoolGuid}/users`,
      data: {
        users: dataForApi
      }
    });

    if (response.status === 'success') {
      setCreationStatus('success');
    } else {
      setCreationStatus('error');
    }
    setIsCreatingUsers(false);
  };

  return {
    handleUploadData,
    onCheckBatchUsersData,
    onCreateBatchUsers,
    fileRawData,
    checksData,
    usernameErrorsCount,
    groupErrorsCount,
    groupUnassignedCount,
    isCreatingUsers,
    isCheckingUsers,
    hasReadFile,
    creationStatus,
    missingLicenseErrorsCount,
    invalidLicenseErrorsCount
  };
};

export default useImportUsers;
