import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWithToken } from '../../utils/helpers/fetcher';
import useUserSettings from '../../utils/hooks/useUserSettings';

const useDemo = () => {
  const { t } = useTranslation();
  const { getPreviewLanguageSettings, setPreviewLanguageSettings } =
    useUserSettings();

  const languageOptions = [
    { value: 'es', label: t('Spanish') },
    { value: 'en', label: t('English') },
    { value: 'pt', label: t('Portuguese') }
  ];

  const PHASES = {
    es: {
      evocar: 'Evocar',
      aplicar: 'Aplicar',
      identificar: 'Identificar'
    },
    en: {
      evocar: 'Evoke',
      aplicar: 'Apply',
      identificar: 'Identify'
    },
    pt: {
      evocar: 'Evocar',
      aplicar: 'Aplicar',
      identificar: 'Identificar'
    }
  };

  const DISCIPLINES = {
    en: {
      Matemáticas: t('demo_maths'),
      Mathematics: t('demo_maths'),
      Matemáticas: t('demo_maths')
    },
    es: {
      Matemáticas: t('demo_maths'),
      Mathematics: t('demo_maths'),
      Matemáticas: t('demo_maths')
    },
    pt: {
      Matemáticas: t('demo_maths'),
      Mathematics: t('demo_maths'),
      Matemáticas: t('demo_maths')
    }
  };

  const EDUCATION_LEVELS = {
    en: {
      'nivel-1': t('demo_primary'),
      'nivel-2': 'Secondary'
    },
    es: {
      'nivel-1': t('demo_primary'),
      'nivel-2': 'Secundaria'
    },
    pt: {
      'nivel-1': t('demo_primary'),
      'nivel-2': 'Secundaria'
    }
  };

  const ALL_DEPARTMENTS = {
    en: [
      { value: 'geometry,geometría,geometria', label: t('demo_geometry') },
      {
        value:
          'magnitudes and measurement,magnitudes and measure,magnitudes and measurements,magnitudes y medida,grandezas e medidas',
        label: t('demo_magnitudes')
      },
      {
        value:
          'numbers and operations,números e operações,números y operaciones',
        label: t('demo_numbers')
      },
      {
        value:
          'statistics and probability,estadística y probabilidad,estatística e probabilidade',
        label: t('demo_statistics')
      }
    ],
    es: [
      { value: 'geometry,geometría,geometria', label: t('demo_geometry') },
      {
        value:
          'magnitudes and measurement,magnitudes and measure,magnitudes and measurements,magnitudes y medida,grandezas e medidas',
        label: t('demo_magnitudes')
      },
      {
        value:
          'numbers and operations,números e operações,números y operaciones',
        label: t('demo_numbers')
      },
      {
        value:
          'statistics and probability,estadística y probabilidad,estatística e probabilidade',
        label: t('demo_statistics')
      }
    ],
    pt: [
      {
        value:
          'statistics and probability,estadística y probabilidad,estatística e probabilidade',
        label: t('demo_statistics')
      },
      { value: 'geometry,geometría,geometria', label: t('demo_geometry') },
      {
        value:
          'magnitudes and measurement,magnitudes and measure,magnitudes and measurements,magnitudes y medida,grandezas e medidas',
        label: t('demo_magnitudes')
      },
      {
        value:
          'numbers and operations,números e operações,números y operaciones',
        label: t('demo_numbers')
      }
    ]
  };

  const PAGE_SIZE = 15;
  const DEFAULT_DEPARTMENT = {
    value: '7e869bdd-f1ee-45ce-8dd0-7a7fc86552ac',
    label: t('demo_numbers')
  };

  const [isLoading, setIsLoading] = useState([]);
  const [isLoadingSeeds, setIsLoadingSeeds] = useState(false);
  const [isLoadingKCsAndLOs, setIsLoadingKCsAndLOs] = useState(false);
  const [isLoadingMoreNodes, setIsLoadingMoreNodes] = useState(false);
  const [hasMoreNodesToLoad, setHasMoreNodesToLoad] = useState(false);
  const [disciplines, setDisciplines] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [allEducationYears, setAllEducationYears] = useState([]);
  const [educationYears, setEducationYears] = useState([]);
  const [kCsAndLOs, setKCsAndLOs] = useState([]);
  const [KCsData, setKCsData] = useState([]);
  const [phases, setPhases] = useState([]);
  const [seeds, setSeeds] = useState([]);
  const [seed, setSeed] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(null);
  const [nodePage, setNodePage] = useState(0);
  const [noSeedsFound, setNoSeedsFound] = useState(false);

  const [selectedEducationYear, setSelectedEducationYear] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[1]);
  const [departments, setDepartments] = useState(
    ALL_DEPARTMENTS[languageOptions[0].value]
  );
  const [selectedDepartment, setSelectedDepartment] =
    useState(DEFAULT_DEPARTMENT);
  const [selectedPhase, setSelectedPhase] = useState({ value: 'all' });
  const [selectedSeeds, setSelectedSeeds] = useState([]);
  const [selectedSeed, setSelectedSeed] = useState(null);

  const [selectedSeedName, setSelectedSeedName] = useState(null);

  const [currentSeedIndex, setCurrentSeedIndex] = useState(0);

  const [isLocked, setIsLocked] = useState({
    language: false,
    department: false,
    educationYear: false
  });

  const [multiLanguageKeys, setMultiLanguageKeys] = useState([]);
  const [previewLanguage, setPreviewLanguage] = useState(
    languageOptions[0].value
  );

  const [search, setSearch] = useState('');

  const getDisciplines = () => {
    return fetchWithToken({
      path: '/disciplines?offset=0&pageSize=10',
      method: 'GET'
    }).then((response) => {
      return response.data.educationDisciplines.map((discipline) => ({
        value: discipline.guid,
        label: discipline.discipline
      }));
    });
  };

  const getEducationLevels = () => {
    return fetchWithToken({
      path: '/educations-levels?offset=0&pageSize=10',
      method: 'GET'
    }).then((response) => {
      return response.data.educationLevels
        .filter((level) => level.guid === 'nivel-1')
        .map((educationLevel) => ({
          value: educationLevel.guid,
          label: educationLevel.level
        }));
    });
  };

  const getEducationYears = () => {
    return fetchWithToken({
      path: '/educations-years?offset=0&pageSize=10',
      method: 'GET'
    }).then((response) => {
      return response.data.educationYears
        .filter((year) => year.educationLevel.guid === 'nivel-1')
        .map((educationYear) => ({
          value: educationYear.guid,
          label: educationYear.year
        }));
    });
  };

  const getAllKCsAndLOs = async ({ isNewSearch = false }) => {
    if (isNewSearch) {
      setKCsAndLOs([]);
      setNodePage(0);
      setIsLoadingKCsAndLOs(true);
    } else {
      setIsLoadingMoreNodes(true);
    }

    const currentPage = isNewSearch ? 0 : nodePage;
    const _baseQuery =
      '&orderBy=description&includeStandards=0&includeDepartments=1&includeSeeds=0&includeLinkTo=1&includeUpdateByUserDetails=0&orderType=ASC&type[]=concept';
    const _department = selectedDepartment?.value
      ? `&departmentGuid[]=${selectedDepartment.value}`
      : '';
    const _educationYear = selectedEducationYear?.value
      ? `&internalEducationYearGuid[]=${selectedEducationYear.value}`
      : '';
    const _search = search ? `&search=${search}` : '';
    const _langCode = selectedLanguage?.value
      ? `&langCode=${selectedLanguage.value.toUpperCase()}`
      : '';

    let query =
      `/nodes?offset=${currentPage * PAGE_SIZE}&pageSize=${PAGE_SIZE}` +
      _baseQuery +
      _department +
      _educationYear +
      _search +
      _langCode;

    let response = await fetchWithToken({
      path: query,
      method: 'GET'
    });

    if (response.error) {
      setIsLoadingKCsAndLOs(false);
      setError(response);
      setShowToast(true);
      setKCsAndLOs([]);
      setIsLoadingKCsAndLOs(false);

      return;
    }

    const data = response.data.nodes.map((node) => ({
      ...node,
      description:
        node.descriptionLanguages.find(
          (item) => item.lang === selectedLanguage.value.toUpperCase()
        )?.description || node.description,
      learningOutcomes: node.linkTo
        .filter((node) => node.type === 'outcome')
        .map((item) => ({
          ...item,
          description:
            item.descriptionLanguages.find(
              (item) => item.lang === selectedLanguage.value.toUpperCase()
            )?.description || item.description
        }))
        .filter((node) => node.description)
        .sort((a, b) => a.description.localeCompare(b.description))
    }));

    if (isNewSearch) {
      console.log('getAllKCsAndLOs isNewSearch', data);
      setKCsAndLOs(data);
    } else {
      console.log('getAllKCsAndLOs noIsNewSearch', [...kCsAndLOs, ...data]);
      setKCsAndLOs([...kCsAndLOs, ...data]);
    }
    setIsLoadingKCsAndLOs(false);
    setIsLoadingMoreNodes(false);
    setHasMoreNodesToLoad(response.data.count > (currentPage + 1) * PAGE_SIZE);
    setNodePage(currentPage + 1);
  };

  const handlePreviewLanguageChange = (event, newLanguage) => {
    setPreviewLanguage(newLanguage);
    setPreviewLanguageSettings(newLanguage);
  };

  const getMultiLanguageKeys = () => {
    const seedCode =
      typeof selectedSeed.data === 'string'
        ? JSON.parse(selectedSeed.data)
        : selectedSeed.data;

    if (seedCode?.locales) {
      setMultiLanguageKeys(Object.keys(seedCode.locales));
    } else {
      setMultiLanguageKeys([]);
    }
  };

  const getSeed = async () => {
    setSeed(null);

    let seedResponse = await fetchWithToken({
      path: `/seeds/preview-json-lemonade`,
      method: 'POST',
      data: {
        guid: selectedSeed?.guid,
        data:
          typeof selectedSeed?.data === 'string'
            ? selectedSeed?.data
            : JSON.stringify(selectedSeed?.data),
        lang: previewLanguage
      }
    });
    setSeed(seedResponse.data);
  };

  const showSeeds = ({ seedIndex }) => {
    let tempSeeds = [];
    tempSeeds = seeds.map((item) => ({
      ...item,
      phase: PHASES[selectedLanguage.value][item.phase]
    }));

    setSelectedSeeds(tempSeeds);
    setCurrentSeedIndex(seedIndex || 0);

    console.log(tempSeeds, seedIndex, currentSeedIndex);

    setSelectedSeed({
      data: tempSeeds[seedIndex || currentSeedIndex].loSeed.data,
      guid: tempSeeds[seedIndex || currentSeedIndex].loSeed.guid
    });
  };

  const onChangeSelectedYear = (value) => {
    setSelectedEducationYear(value);

    setSeeds([]);
    setSelectedSeeds([]);
    setSelectedSeed(null);
    setCurrentSeedIndex(0);
    setSeed(null);

    // reset filters
    // setSelectedDepartment(null);
  };

  const onChangeLanguage = (value) => {
    setSelectedLanguage(value);

    setSelectedDepartment(null);
    setKCsAndLOs([]); // fix blinking of the list
    setSeeds([]);
    setSelectedSeeds([]);
    setSelectedSeed(null);
    setCurrentSeedIndex(0);
    setSeed(null);
  };

  const onChangeDepartment = (value) => {
    setSelectedDepartment(value);

    setSeeds([]);
    setSelectedSeeds([]);
    setSelectedSeed(null);
    setCurrentSeedIndex(0);
    setSeed(null);
  };

  const onNextSeed = () => {
    if (currentSeedIndex < selectedSeeds.length - 1) {
      setCurrentSeedIndex(currentSeedIndex + 1);
      showSeeds({ seedIndex: currentSeedIndex + 1 });
    }
  };

  const onPreviousSeed = () => {
    if (currentSeedIndex > 0) {
      setCurrentSeedIndex(currentSeedIndex - 1);
      showSeeds({ seedIndex: currentSeedIndex - 1 });
    }
  };

  const getSeeds = async (loNode) => {
    setSeed(null);
    setSeeds([]);
    setSelectedSeeds([]);
    setSelectedSeed(null);
    setCurrentSeedIndex(0);

    setIsLoadingSeeds(true);

    const url = `/nodes/${loNode.guid}/seeds`;
    const seedsResponse = await fetchWithToken({
      path: url,
      method: 'GET'
    });

    setNoSeedsFound(Boolean(seedsResponse.data.loNodeSeeds.length === 0));
    setSeeds(seedsResponse.data.loNodeSeeds);

    setIsLoadingSeeds(false);
  };

  const onSelectLO = (value) => {
    if (value) {
      getSeeds(value);
    }
  };

  useEffect(() => {
    if (selectedLanguage && allEducationYears.length) {
      setPreviewLanguage(selectedLanguage.value);
      setDepartments(ALL_DEPARTMENTS[selectedLanguage.value]);
      setEducationYears(allEducationYears.slice(0, 6));
      setSelectedEducationYear({
        value: allEducationYears[0].value,
        label: allEducationYears[0].label
      });

      setEducationLevels(
        educationLevels.map((level) => ({
          ...level,
          label: EDUCATION_LEVELS[selectedLanguage.value][level.value]
        }))
      );
      setDisciplines(
        disciplines.map((discipline) => ({
          ...discipline,
          label: DISCIPLINES[selectedLanguage.value][discipline.label]
        }))
      );
    }
  }, [selectedLanguage, allEducationYears]);

  useEffect(() => {
    if (selectedDepartment) {
      getAllKCsAndLOs({
        isNewSearch: true
      });
    }
  }, [selectedDepartment, selectedEducationYear]);

  useEffect(() => {
    if (selectedSeed) {
      getMultiLanguageKeys();
      setPreviewLanguage(previewLanguage);
      setSelectedSeedName(seeds[currentSeedIndex]?.loSeed?.name);
      getSeed();
    }
  }, [selectedSeed, previewLanguage]);

  useEffect(() => {
    if (seeds.length > 0) {
      setCurrentSeedIndex(0);
      showSeeds({ seedIndex: 0 });
    }
  }, [seeds]);

  const getData = () => {
    setIsLoading(true);

    Promise.all([
      getDisciplines(),
      getEducationLevels(),
      getEducationYears()
    ]).then(([disciplines, educationLevels, educationYears]) => {
      setDisciplines(disciplines);
      setEducationLevels(educationLevels);
      setAllEducationYears(educationYears);

      setSelectedEducationYear({
        value: educationYears[0].value,
        label: educationYears[0].label
      });

      setIsLoading(false);
    });
  };

  const onLoadMoreNodes = () => {
    setIsLoadingMoreNodes(true);
    getAllKCsAndLOs({
      isNewSearch: false
    });
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      getData();
      return;
    }, 500),
    []
  );

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  useEffect(() => {
    const _language = getPreviewLanguageSettings();
    const _selectedLanguage = languageOptions.find(
      (language) => language.value === _language
    );
    setPreviewLanguage(_language);
    setSelectedLanguage(_selectedLanguage);

    getData();
  }, []);

  return {
    isLocked,
    isLoading,
    educationLevels,
    isLoadingKCsAndLOs,

    languageOptions,
    selectedLanguage,
    onChangeLanguage,

    departments,
    selectedDepartment,
    onChangeDepartment,

    educationYears,
    selectedEducationYear,
    onChangeSelectedYear,

    currentIndex: 0,

    kCsAndLOs,
    onSelectLO,

    seed,
    showSeeds,
    disciplines,
    onNextSeed,
    noSeedsFound,
    isLoadingSeeds,
    onPreviousSeed,
    currentSeedIndex,
    seeds: selectedSeeds,

    multiLanguageKeys,
    previewLanguage,
    handlePreviewLanguageChange,

    error,
    showToast,
    setShowToast,

    onLoadMoreNodes,
    isLoadingMoreNodes,
    hasMoreNodesToLoad,

    search,
    onChangeSearch,
    selectedSeedName
  };
};

export default useDemo;
