import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterDrawer from '../../../../atoms/filterDrawer/FilterDrawer'
import FilterItem from '../../../../atoms/filterItem/FilterItem'
import EDDepartmentMainFilter from '../../../../components/filters/EDDepartmentMainFilter'
import EDEducationLevelProgramFilter from '../../../../components/filters/EDEducationLevelProgramFilter'
import EDLearningObjectiveFilter from '../../../../components/filters/EDLearningObjectiveFilter'
import EDLemonadeTemplateProgramFilter from '../../../../components/filters/EDLemonadeTemplateProgramFilter'
import EDResponsibleProgramFilter from '../../../../components/filters/EDResponsibleProgramFilter'

const ActivitiesFilter = ({
  isDrawerOpen,
  onCloseDrawer,
  onSearch,
  educationYears,
  currentFilters
}) => {
  const { t } = useTranslation()

  const [filters, setFilters] = useState(currentFilters)

  useEffect(() => {
    setFilters(currentFilters)
  }, [currentFilters])

  const removeDuplicates = (array) => {
    return array.filter((item, index, self) =>
      typeof item === 'object'
        ? index === self.map((object) => object.id).indexOf(item.id)
        : index === self.map((value) => value).indexOf(item)
    )
  }

  const onChangeFilter = (filter) => {
    let filterValue = undefined

    if (filter?.value?.length > 0) {
      filterValue = removeDuplicates(filter.value)
    }

    setFilters({
      ...filters,
      [filter.id]: filterValue
    })
  }

  return (
    <FilterDrawer
      title={t('Filter')}
      isDrawerOpen={isDrawerOpen}
      onCloseDrawer={() => {
        onSearch({})
        setFilters({})
        onCloseDrawer()
      }}
      onReset={() => {
        onSearch({})
        setFilters({})
        onCloseDrawer()
      }}
      onSearch={() => {
        onSearch(filters)
      }}
      isSearchDisabled={Object.keys(filters).length === 0}
    >
      <FilterItem
        title={t('activity_education_level')}
        type='custom'
        defaultSelection={filters.educationYearGuid || []}
        customFilter={
          <EDEducationLevelProgramFilter
            multiple
            defaultValue={filters.educationYearGuid || []}
            onChange={(value) => {
              onChangeFilter({ id: 'educationYearGuid', value })
            }}
            isFilter={true}
          />
        }
      />

      <FilterItem
        title={t('learning_outcome_column_department')}
        type='custom'
        defaultSelection={filters.departments || []}
        customFilter={
          <EDDepartmentMainFilter
            multiple
            defaultValue={filters.departments || []}
            onChange={(value) => {
              onChangeFilter({ id: 'departments', value })
            }}
            isFilter={true}
          />
        }
      />

      <FilterItem
        title='LO'
        type='custom'
        defaultSelection={filters.learningObjective || []}
        customFilter={
          <EDLearningObjectiveFilter
            multiple
            defaultValue={filters.learningObjective || []}
            onChange={(value) => {
              onChangeFilter({ id: 'learningObjective', value })
            }}
            isFilter={true}
          />
        }
      />

      <FilterItem
        title={t('activity_phase')}
        type='checkbox'
        multiple
        options={[
          { label: t('Evoke'), value: 'evocar' },
          { label: t('Identify'), value: 'identificar' },
          { label: t('Apply'), value: 'aplicar' }
        ]}
        defaultSelection={filters.phase || []}
        onChange={(value) => onChangeFilter({ id: 'phase', value })}
      />

      <FilterItem
        title={t('activity_seedTemplate')}
        type='custom'
        defaultSelection={filters.seedTemplate || []}
        customFilter={
          <EDLemonadeTemplateProgramFilter
            multiple
            defaultValue={filters.seedTemplate || []}
            onChange={(value) => {
              onChangeFilter({ id: 'seedTemplate', value })
            }}
            isFilter={true}
          />
        }
      />

      <FilterItem
        title={t('activity_App')}
        type='checkbox'
        options={[
          { label: t('has_mobile_version'), value: 1 },
          { label: t('no_mobile_version'), value: 0 }
        ]}
        defaultSelection={filters.hasDataApp || []}
        onChange={(value) => {
          onChangeFilter({ id: 'hasDataApp', value })
        }}
        isFilter={true}
      />

      <FilterItem
        title={t('activity_status')}
        type='checkbox'
        multiple
        options={[
          { label: t('to_do'), value: 'to_do' },
          { label: t('check_JSON'), value: 'check_JSON' },
          { label: t('correct_JSON'), value: 'correct_JSON' },
          {
            label: t('pending_auto_translation'),
            value: 'pending_auto_translation'
          },
          { label: t('check_translation'), value: 'check_translation' },
          { label: t('fix_translation'), value: 'fix_translation' },
          {
            label: t('pending_translation_only_app'),
            value: 'pending_translation_only_app'
          },
          { label: t('done'), value: 'done' }
        ]}
        defaultSelection={filters.status || []}
        onChange={(value) => onChangeFilter({ id: 'status', value })}
      />

      <FilterItem
        title={t('activity_responsible')}
        type='custom'
        id='responsible'
        defaultSelection={filters.responsible || []}
        customFilter={
          <EDResponsibleProgramFilter
            multiple
            defaultValue={filters.responsible || []}
            onChange={(value) => {
              onChangeFilter({ id: 'responsible', value: value })
            }}
            isFilter={true}
          />
        }
      />

      {/* <FilterItem
        title={t('activity_language')}
        type='dropdown'
        options={[
          { label: t('Spanish'), value: 'es' },
          { label: t('English'), value: 'en' },
          { label: t('Portuguese'), value: 'pt' }
        ]}
        defaultSelection={filters.language || []}
        onChange={(value) => {
          onChangeFilter({ id: 'language', value });
        }}
      /> */}
    </FilterDrawer>
  )
}

export default ActivitiesFilter
